/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Invia",
      register: "Registrati",
      login: "Accedi",
      continue: "Continua",
      confirm: "Conferma",
      close: "Chiudi",
      back: "Indietro",
      browse: "Sfoglia file",
      save: "Salva",
      cancel: "Annulla",
      delete: "Elimina",
      create: "Crea",
      invite: "Invita",
      analyze: "Analizza",
      select: "Seleziona",
      selectAll: "Seleziona tutto",
      clearFilters: "Cancella filtri",
      exportReport: "Esporta Report",
      exportAsPDF: "Esporta come PDF",
      exportCSV: "Esporta CSV",
      analyzeVoice: "Analizza Voce",
      backToAll: "Torna a tutti i report",
      downloadVideoReport: "Scarica report video",
      generateVideoReport: "Genera report video",
      startDeepfake: "Avvia rilevamento Deepfake",
      changePassword: "Cambia password",
      logout: "Esci",
      editMember: "Modifica membro del team",
      editTeam: "Modifica team",
      newUser: "Nuovo utente",
      createTeam: "Crea nuovo team",
      copy: "Copia",
      showThumbnails: "Mostra Anteprime",
      blurThumbnails: "Sfoca Anteprime",
      showMore: "Mostra di più",
      showLess: "Mostra di meno",
    },
    loading: {
      submitting: "Invio in corso",
      registering: "Registrazione in corso",
      loggingIn: "Accesso in corso",
      sending: "Invio in corso",
      inProgress: "In corso",
      changing: "Modifica in corso",
      processing: "Elaborazione in corso",
      exporting: "Esportazione in corso",
      generating: "Generazione in corso",
      loading: "Caricamento"
    },
    placeholder: {
      email: "{'esempio@mail.com'}",
      password: "*********",
      enterUrl: "Inserisci URL",
      enterEmail: "Inserisci indirizzo email",
      enterTeamName: "Inserisci nome del team",
      select: "Seleziona un valore",
      searchByNameAndEmail: "Cerca per nome o indirizzo email",
    },
    label: {
      email: "Email",
      password: "Password",
      confirmPassword: "Conferma password",
      name: "Nome",
      firstName: "Nome",
      lastName: "Cognome",
      phoneNumber: "Numero di telefono",
      industry: "Settore",
      companyWebsite: "Sito web aziendale",
      companyEmail: "Email aziendale",
      oldPassword: "Vecchia password",
      newPassword: "Nuova password",
      teamName: "Nome del team",
      teamVisibility: "Visibilità del team",
      role: "Ruolo",
      emailAddress: "Indirizzo email",
      dragAndDrop: "Trascina qui i file multimediali",
      browseFiles: "Sfoglia file",
      or: "oppure",
      hide: "Nascondi",
      show: "Mostra",
      all: "Tutti",
      selected: "{count} Selezionati",
      minutes: "min",
      allResults: "Tutti i risultati",
      audio: "audio",
      video: "video",
      image: "immagine",
      pdf: "pdf",
      url: "URL",
      fileName: "Nome file",
      reportId: "ID report",
      searchType: "Tipo di ricerca",
      searchBy: "Cerca per",
      status: "Stato",
      user: "Utente",
      serviceType: "Tipo di servizio",
      date: "Data",
      report: "Report",
      reportStatus: "Stato del report",
      extractedDetails: "Dettagli estratti",
      settings: "Impostazioni",
      language: "Lingua",
      noTeamMembers: "Nessun membro del team trovato.",
      currentUser: "Utente attuale",
      inviteSent: "Invito inviato",
      members: "{count} Membri",
      timeRange: "Intervallo di Tempo",
      duration: "Durata",
      speaker: "Oratore"
    }
  },
  component: {
    list: {
      title: "Lista risultati",
      noData: "Nessun dato trovato",
      noDataForEmail: "Nessun risultato trovato per questa email:",
      searchPlaceholder: "Cerca email...",
      resultsFound: "Risultati trovati: {count}",
      deleteModal: {
        title: "Elimina elementi selezionati",
        message: "Questi report verranno eliminati. Sei sicuro di voler procedere?",
      },
      table: {
        header: {
          media: "Media",
          info: "Info",
          result: "Risultato",
          riskScore: "Punteggio di rischio documento"
        },
      },
    },
    status: {
      inProgress: "In corso",
      completed: "Completato",
      failed: "Fallito",
      verified: "Verificato",
      notVerified: "Non verificato",
      valid: "Valido",
      suspicious: "Sospetto",
      serverError: "Errore server",
      extractionFailed: "Estrazione fallita",
      tooltip: {
        analyzing: "Analisi del contenuto in corso...",
        urlExtractionFailed: "Estrazione contenuto URL fallita",
        urlExtractionFailedDesc: "Non è stato possibile estrarre il contenuto dall'URL fornito.",
        fakeDetected: "Rilevato contenuto falso o tentativo di spoofing",
        fakeDetectedDesc: "Questo contenuto è stato segnalato come falso o contiene un tentativo di spoofing.",
        checkCompleted: "Verifica completata con successo",
        checkCompletedDesc: "Nessuna anomalia o tentativo di spoofing rilevato.",
        serverErrorDesc: "Qualcosa è andato storto. Riprova più tardi."
      }
    },
    table: {
      detection: {
        faceManCheck: "Controllo manipolazione volto",
        aiGenCheck: "Controllo contenuto generato da IA",
        forensicAnalysisCheck: "Controllo analisi forense",
        voiceAnalysisCheck: "Controllo analisi voce",
        confidence: "Affidabilità",
        modelGenerator: "Generatore modello",
        manipulationType: "Tipo di manipolazione",
        noFace: "Volto troppo piccolo/Nessun volto",
        noVoice: "Nessuna voce",
        details: "Dettagli",
        alert: "Avviso",
        creationDate: "Data creazione",
        modifyDate: "Data modifica",
        notAvailable: "N/D",
        description: {
          confidence: "Un'alta affidabilità indica chiari segni di manipolazione IA, con una certezza di almeno il 50%",
          confidenceAI: "Un'alta affidabilità indica chiari segni di generazione IA, con una certezza di almeno il 50%",
          modelGenerator: "Generatore modello: quando mostrato, il rilevatore può anche identificare quale modello è stato usato come generatore",
          manipulationType: "Tipo di manipolazione: quando mostrato, il rilevatore può anche identificare se si tratta di uno scambio di volto, una rianimazione facciale, una sincronizzazione labiale o un'animazione facciale"
        }
      }
    },
    pagination: {
      itemPerPage: "Elementi per pagina:",
      range: "{from}-{to} di {total}",
    },
    modal: {
      upgradePlan: {
        title: "Aggiorna piano",
        message: "Puoi contattare il tuo account manager per aggiornare il tuo piano cliccando sul pulsante qui sotto.",
        demoMessage: "Cliccando su \"Voglio aggiornare il mio piano\" sarai contattato dal tuo account manager che ti spiegherà le varie opzioni.\nVuoi procedere?",
        licenceMessage: "La tua licenza è scaduta. Contattaci per rinnovare la tua licenza.",
        monthlyLimitReached: "Limite mensile raggiunto",
        trialLimitReached: "Hai raggiunto il limite mensile di prova di {limit} invii.",
        action: "Voglio aggiornare il mio piano",
      },
    }
  },
  feedback: {
    error: {
      label: "Errore!",
      default: "Si è verificato un errore",
      server: "Si è verificato un errore del server",
      generic: "Qualcosa è andato storto. Per favore riprova più tardi.",
      unsupportedFileType: "Il tuo abbonamento non consente il caricamento di questo tipo di file",
      terms: "Per favore accetta i Termini e Condizioni!",
      upload: "Caricamento del file non riuscito",
    },
    success: {
      label: "Successo!",
      default: "Operazione completata con successo",
      passwordChanged: "Password modificata con successo",
      registration: "La tua richiesta è stata inviata per l'approvazione",
      contact: "Grazie per la tua richiesta, ti contatteremo presto",
      copy: "Copiato negli appunti",
    },
    warning: {
      label: "Attenzione!",
      trialDisclaimer: "Stai utilizzando la versione di prova della piattaforma. Passa al piano premium per utilizzare la piattaforma senza restrizioni.",
      submissionDisclaimer: "Non inviare informazioni personali. Sensity non è responsabile del contenuto del tuo invio."
    },
    info: {
      agreeTo: "Accetto quanto segue:",
      terms: "Termini e Condizioni",
      privacy: "Informativa sulla Privacy",
    },
    validation: {
      required: "Questo campo è obbligatorio",
      url: "Inserisci un URL valido",
      email: "Inserisci un'email valida",
      password: "Inserisci una password valida",
      passwordMismatch: "Le password non coincidono",
      numeric: "Questo campo deve essere numerico",
      workEmail: "Fornisci un'email aziendale",
      website: "Inserisci un sito web valido",
      phoneNumber: "Inserisci un numero di telefono valido",
      allFieldsRequired: "Compila tutti i campi!",
      allFieldsCorrect: "Compila tutti i campi correttamente!",
      termsRequired: "Accetta i Termini e Condizioni!"
    },
    limitation: {
      maxFileSize: "Dimensione massima file",
      maxFile: "Numero massimo di file caricabili contemporaneamente",
      invalidFile: "Tipo di file non valido",
      singleFileWarning: "Puoi trascinare solo un file",
      maxFileWarning: "Puoi trascinare solo {count} file alla volta",
      maxFileNameLength: "Il nome del file può essere lungo al massimo {count} caratteri",
      badExtension: "Estensione file non valida",
      maxAudioDuration: "Durata audio massima {value} minuti",
      maxVideoDuration: "Durata video massima {value} minuti",
      maxVideoResolution: "Risoluzione video massima {height}x{width} o {width}x{height}",
      fileAlreadyUploaded: "Il file {filename} è già stato caricato",
      unsupportedFileType: "Tipo di file non supportato per il tuo abbonamento"
    }
  },
  page: {
    home: {
      title: "Pannello di controllo",
      subtitle: "Visualizza i dettagli di utilizzo e invio",
      stats: {
        monthlyLeftCredits: "Crediti mensili rimanenti",
        monthlyUsage: "Utilizzo mensile",
        accountExpire: "L'account scadrà il",
        getStarted: "Ottieni il massimo da Sensity",
        videoTitle: "Iniziare con Sensity: funzionalità e risultati"
      }
    },
    auth: {
      login: {
        title: "Accedi",
        subtitle: "Non hai un account?",
        signUpLink: "Registrati gratuitamente",
        forgotPassword: "Hai dimenticato la password?"
      },
      register: {
        title: "Crea un account",
        subtitle: "Hai già un account?",
        emailDisclaimer: "La tua registrazione sarà approvata da un operatore umano. I domini gratuiti e temporanei non saranno considerati legittimi per impostazione predefinita",
        detailsPlaceholder: "Dettagli \nPer favore, raccontaci di più sulla tua organizzazione e quali obiettivi vuoi raggiungere utilizzando Sensity. Senza informazioni esaustive il tuo account sarà rifiutato"
      },
      passwordRecovery: {
        title: "Recupero password",
        subtitle: "Torna a",
        message: "Inserisci l'email che stai utilizzando per il tuo account.",
        successMessage: "Abbiamo inviato le istruzioni per il ripristino della password al tuo indirizzo email. Se non ricevi l'email entro dieci minuti, verifica che l'indirizzo inserito sia corretto."
      },
      confirmPassword: {
        title: "Reimposta password"
      },
      changePassword: {
        title: "Cambia password"
      }
    },
    analytics: {
      title: "Analisi",
      subtitle: "Esplora informazioni dettagliate su tutti i contenuti caricati per gli invii tramite piattaforma e API."
    },
    team: {
      title: "Gestione team",
      visibility: {
        visible: "Gli invii sono visibili a tutto il team",
        notVisible: "Gli invii non sono visibili a tutto il team",
        tooltip: {
          visible: "Gli invii del tuo team sono visibili a tutti i membri del team.",
          notVisible: "I tuoi invii sono privati per ciascun membro del team."
        },
        hint: "Se vuoi rendere gli invii del tuo team visibili a tutti i membri del team, spunta questa casella. Non spuntarla se vuoi che gli invii di ogni membro del team rimangano privati."
      },
      addNewMemberModal: {
        title: "Aggiungi nuovo membro del team",
        desc: "Inserisci l'indirizzo email del nuovo membro del team.",
        existingMember: "Questo utente è già membro del team."
      },
      upgradeModal: {
        title: "La gestione del team è una funzionalità premium",
        desc: "Cliccando su \"Voglio aggiornare il mio piano\" sarai contattato dal tuo account manager che ti spiegherà le varie opzioni.",
        confirm: "Vuoi procedere?",
        button: "Voglio aggiornare il mio piano"
      },
      deleteTeamMemberModal: {
        title: "Elimina membro del team",
        desc: "Questi utenti saranno eliminati dal team. Sei sicuro di voler procedere?"
      },
      createTeamModal: {
        title: "Crea nuovo team",
        desc: "Inserisci un nome per il tuo team"
      },
      newUserTooltip: {
        analyst: "Contatta l'amministratore del team per aggiungere altri utenti.",
        admin: "Aggiungi nuovi utenti al tuo team."
      },
    },
    deepfake: {
      title: "Rilevamento Deepfake",
      subtitle: "Analizza contenuti multimediali sospetti per rilevare deepfake audio e video e contenuti generati dall'IA.",
      button: "Avvia rilevamento Deepfake",
      info: {
        automation: "Vuoi automatizzare gli invii?",
        checkDocs: "Consulta la nostra documentazione API",
        checkDocsOnPrem: "Consulta il nostro manuale utente on-premise per visualizzare la documentazione API.",
        contactSupport: "e contattaci a"
      },
      report: {
        overall: {
          user: "Utente",
          fileName: "Nome file",
          fileSize: "Dimensione file (KB)",
          fileHash: "Hash file (SHA256)",
          submissionDate: "Data invio",
          submissionTime: "Ora invio",
          fileResolution: "Risoluzione file",
          url: "URL",
          faces: "volti",
          face: "volto",
          processingMessage: "Elaborazione report video. Potrebbe richiedere alcuni minuti.",
          selectFaceMessage: "Seleziona almeno un volto per generare il report video.",
          enableVoiceAnalysisMessage: "Per attivare il servizio di analisi vocale, contatta support@sensity.ai",
          exportingReport: "Esportazione report",
          processingVideo: "Elaborazione video"
        },
        explanation: {
          fakeFacesDetected: "Volti falsi rilevati",
          faceExplanation: "Selezionando i volti, i relativi riquadri di delimitazione verranno visualizzati sopra il contenuto multimediale.",
          faceExplanationVideo: "e nel video esportato.",
          selected: "selezionato",
          viewOnVideo: "Visualizza su video",
          exportVideoLabel: "Esporta video con volti selezionati",
          faceResolution: "Risoluzione volto",
          low: "bassa",
          lowResolutionInfo: "Una bassa risoluzione del volto può compromettere la precisione del rilevatore",
          viewAt: "Visualizza a",
          pixelBasedAssessment: "Valutazione basata sui pixel",
          pixelBasedInfo: "Questa sezione fornisce rappresentazioni visive che confermano i risultati sopra",
          expandImage: "Espandi immagine",
          expandVideoFrame: "Espandi fotogramma video",
          heatmapInfo: "La mappa di calore mostra le aree in cui il modello ha rilevato una maggiore probabilità di sintesi a livello di pixel",
          sectionDesc: "Questa sezione fornisce rappresentazioni visive che confermano i risultati sopra",
          pixelAndSegmentTitle: "Valutazione basata su pixel e segmenti",
          faceAndPixelTitle: "Allineamento volto e valutazione basata sui pixel",
          segmentBasedNotAvailable: "La valutazione basata sui segmenti non è disponibile per questo contenuto",
          segmentBasedAssesment: "Valutazione basata sui segmenti",
          segmentBasedInfo: "Il rilevatore identifica gli oggetti nell'immagine più probabilmente generati dall'IA",
          faceAlignmentAssesment: "Valutazione allineamento volto",
          faceAlignmentInfo: "Le immagini generate da questa famiglia di GAN possono essere riconosciute dalla posizione fissa degli occhi e della bocca",
          fakeSpeakersDetected: "Rilevati Oratori Falsi",
        },
      },
      form: {
        title: "Carica contenuto multimediale",
        tab: {
          file: "File",
          url: "URL",
          urlNotAvailable: "Il caricamento tramite URL non è disponibile on-premise"
        },
      }
    },
    document: {
      title: "Verifica Documento",
      subtitle: "Estrai informazioni chiave e convalida l'autenticità dei documenti.",
      button: "Inizia Verifica Documento",
    },
    identity: {
      title: "Verifica dell'Identità",
      subtitle: "Esegue la verifica dell'identità e il rilevamento della vivacità.",
      button: "Inizia Verifica dell'Identità",
    },
    developer: {
      title: "Sviluppatore",
      subtitle: "Gestisci le tue chiavi API e l'utilizzo",
      apiToken: "Token API"
    }
  },
  name: {
    service: {
      deepfake: "Rilevamento Deepfake",
      aiGen: "Contenuto generato da IA",
      faceMan: "Manipolazione volto",
      document: "Verifica documento",
      forensic: "Analisi forense",
      id: "Verifica identità",
      liveness: "Video dal vivo",
      pixel: "Analisi pixel",
      voice: "Analisi voce",
      faceMatch: "Confronto volti",
      title: "Sensity Enterprise",
      idDoc: "Lettore documenti d'identità"
    },
    language: {
      english: "Inglese (English)",
      turkish: "Turco (Türkçe)",
      uzbek: "Uzbeco (O'zbekcha)",
      spanish: "Spagnolo (Español)",
      korean: "Coreano (한국어)",
      russian: "Russo (Русский)",
      hindi: "Hindi (हिंदी)",
      german: "Tedesco (Deutsch)",
      dutch: "Olandese (Nederlands)",
      chinese: "Cinese (中文)",
      arabic: "Arabo (العربية)",
      indonesian: "Indonesiano (Bahasa)",
      vietnamese: "Vietnamita (Tiếng Việt)",
      japanese: "Giapponese (日本語)",
      italian: "Italiano",
      french: "Francese (Français)"
    }
  }
}
