<script>
import { mapActions } from "vuex";
import SnsInput from "@/components/SnsInput.vue";
import SnsAlert from "@/components/SnsAlert.vue";
import SnsButton from "@/components/SnsButton.vue";

export default {
  name: "UserChangePasswordModal",
  components: {
    SnsInput,
    SnsAlert,
    SnsButton,
  },
  emits: ["close"],
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      isLoading: false,
      alert : {
        isVisible: false,
        type: "alert-success text-white",
        header: this.$t("feedback.success.label"),
        content: "",
      },
    };
  },
  computed: {
    isAllFieldsFilled() {
      return (
        this.currentPassword.length &&
        this.newPassword.length &&
        this.newPasswordConfirm.length
      );
    },
  },
  methods: {
    ...mapActions(["changePassword"]),
    updatePassword() {
      if (this.isAllFieldsFilled) {
        if (this.newPassword === this.newPasswordConfirm) {
          this.alert.isVisible = false;
          this.isLoading = true;

          this.changePassword({
            passwordOld: this.currentPassword,
            passwordNew: this.newPassword,
            passwordConfirm: this.newPasswordConfirm,
          })
            .then(() => {
              this.alert = {
                isVisible: true,
                type: "alert-success text-white",
                header: this.$t("feedback.success.label"),
                content: this.$t("feedback.success.passwordChanged"),
              };
              this.currentPassword = "";
              this.newPassword = "";
              this.newPasswordConfirm = "";
              this.isLoading = false;
            })
            .catch((error) => {
              if(error.response.data.non_field_errors) {
                this.alert = {
                  isVisible: true,
                  type: "alert-error text-white",
                  header: this.$t("feedback.error.label"),
                  content: error.response.data.non_field_errors[0],
                };
              } else {
                this.alert = {
                  isVisible: true,
                  type: "alert-error text-white",
                  header: this.$t("feedback.error.label"),
                  content: error.response.data.detail,
                };
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.alert = {
            isVisible: true,
            type: "alert-warning",
            header: this.$t("feedback.warning.label"),
            content: this.$t("feedback.validation.passwordMismatch"),
          };
        }
      } else {
        this.alert = {
          isVisible: true,
          type: "alert-warning",
          header: this.$t("feedback.warning.label"),
          content: this.$t("feedback.validation.allFieldsRequired"),
        };
      }
    },
  }
};
</script>

<template>
  <div class="flex flex-col space-y-4">
    <SnsAlert
      v-if="alert.isVisible"
      :type="alert.type"
      :header="alert.header"
      :content="alert.content"
    />

    <div class="basis-full">
      <div class="flex">
        <div class="basis-full">
          <SnsInput
            v-model="currentPassword"
            type="password"
            :label="$t('common.label.oldPassword')"
            required
          />
        </div>
      </div>

      <div class="flex">
        <div class="basis-1/2 pr-2">
          <SnsInput
            v-model="newPassword"
            type="password"
            :label="$t('common.label.newPassword')"
            required
          />
        </div>

        <div class="basis-1/2 pl-2">
          <SnsInput
            v-model="newPasswordConfirm"
            type="password"
            :label="$t('common.label.confirmPassword')"
            required
          />
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <SnsButton
        :disabled="!isAllFieldsFilled || isLoading"
        :loading="isLoading"
        :loading-label="$t('common.loading.changing')"
        custom-class="disabled:border-accent"
        @click="updatePassword"
      >
        {{ $t("page.auth.changePassword.title") }}
      </SnsButton>
    </div>
  </div>
</template>
