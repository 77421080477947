import globalAxios from "axios";
import store from "../store";
import router from "../router";

const axios = globalAxios.create({
  baseURL: import.meta.env.VITE_APP_ROOT_API,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error?.response?.status || 500;

    const loggedUser = store.getters.getUser;

    if (!error.response) {
      if (!loggedUser) {
        window.location.href = "/login";
        localStorage.clear();
      }

      throw error;
    }

    const nonRedirectRoutes = [
      "login",
      "register",
      "password-recovery",
      "confirm-new-password",
    ];
    const isNonRedirectRoute = nonRedirectRoutes.some(i => router.currentRoute.value.fullPath.includes(i));
    const isTokenExpired = status === 401 && error?.response?.data?.code === "token_not_valid";

    if ((status === 401 && !isNonRedirectRoute)) {
      if (isTokenExpired) {
        store.dispatch("refreshToken");
      } else {
        store.dispatch("logout");
      }
    }

    const currentRoute = router?.currentRoute?.value?.fullPath;
    if (
      status === 404 &&
      !isNonRedirectRoute &&
      !currentRoute.includes("404")
    ) {
      router.push("/404");
    }

    throw error;
  }
);

export default axios;
