import { createI18n } from "vue-i18n";
import en from "@/locales/en";
import tr from "@/locales/tr";
import es from "@/locales/es";
import ko from "@/locales/ko";
import ru from "@/locales/ru";
import hi from "@/locales/hi";
import uz from "@/locales/uz";
import zh from "@/locales/zh";
import ar from "@/locales/ar";
import id from "@/locales/id";
import vn from "@/locales/vn";
import ja from "@/locales/ja";
import it from "@/locales/it";
import fr from "@/locales/fr";
import de from "@/locales/de";
import nl from "@/locales/nl";

const messages = {
  en,
  tr,
  es,
  ko,
  ru,
  hi,
  uz,
  zh,
  ar,
  id,
  vn,
  ja,
  it,
  fr,
  de,
  nl
};

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("locale") || "en",
  fallbackLocale: "en",
  messages,
  globalInjection: true,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});

export default i18n;
