export const apiUrlMap = {
  login: {
    GET: {
      LIST: "v1/login/",
      DETAIL: "v1/login/",
    },
    POST: "v1/login/",
    PATCH: "v1/login/",
    DELETE: "v1/login/",
  },
  signup: {
    GET: {
      LIST: "v1/signup/",
      DETAIL: "v1/signup/",
    },
    POST: "v1/signup/",
    PATCH: "v1/signup/",
    DELETE: "v1/signup/",
  },
  passwordReset: {
    GET: {
      LIST: "v1/password/reset/",
      DETAIL: "v1/password/reset/",
    },
    POST: "v1/password/reset/",
    PATCH: "v1/password/reset/",
    DELETE: "v1/password/reset/",
  },
  activate: {
    GET: {
      LIST: "v1/activate/",
      DETAIL: "v1/activate/",
    },
    POST: "v1/activate/",
    PATCH: "v1/activate/",
    DELETE: "v1/activate/",
  },
  passwordResetConfirm: {
    GET: {
      LIST: "v1/password/reset/confirm/",
      DETAIL: "v1/password/reset/confirm/",
    },
    POST: "v1/password/reset/confirm/",
    PATCH: "v1/password/reset/confirm/",
    DELETE: "v1/password/reset/confirm/",
  },
  passwordChange: {
    GET: {
      LIST: "v1/password/change/",
      DETAIL: "v1/password/change/",
    },
    POST: "v1/password/change/",
    PATCH: "v1/password/change/",
    DELETE: "v1/password/change/",
  },
  user: {
    GET: {
      DETAIL: "v2/users/me/",
    },
  },
  users: {
    GET: {
      LIST: "v1/users/",
      DETAIL: "v1/users/",
    },
    POST: "v1/users/",
    PATCH: "v1/users/",
    DELETE: "v1/users/",
  },
  userUsage: {
    GET: {
      LIST: "v1/user/usage/",
      DETAIL: "v1/user/usage/",
    },
    POST: "v1/user/usage/",
    PATCH: "v1/user/usage/",
    DELETE: "v1/user/usage/",
  },
  sendSlackMessage: {
    GET: {
      LIST: "v1/send_slack_message/",
      DETAIL: "v1/send_slack_message/",
    },
    POST: "v1/send_slack_message/",
    PATCH: "v1/send_slack_message/",
    DELETE: "v1/send_slack_message/",
  },
  serviceMedia: {
    GET: {
      LIST: "v1/service-media/",
      DETAIL: "v1/service-media/",
    },
    POST: "v1/service-media/",
    PATCH: "v1/service-media/",
    DELETE: "v1/service-media/",
  },
  serviceJob: {
    GET: {
      LIST: "v1/service-job/",
      DETAIL: "v1/service-job/",
    },
    POST: "v1/service-job/",
    PATCH: "v1/service-job/",
    DELETE: "v1/service-job/",
  },
  reportList: {
    GET: {
      LIST: "v1/report_list/",
      DETAIL: "v1/report_list/",
    },
    POST: "v1/report_list/",
    PATCH: "v1/report_list/",
    DELETE: "v1/report_list/",
  },
  targetIndustries: {
    GET: {
      LIST: "v1/target-industries/",
      DETAIL: "v1/target-industries/",
    },
    POST: "v1/target-industries/",
    PATCH: "v1/target-industries/",
    DELETE: "v1/target-industries/",
  },
  contactUs: {
    GET: {
      LIST: "v1/contact-us/",
      DETAIL: "v1/contact-us/",
    },
    POST: "v1/contact-us/",
    PATCH: "v1/contact-us/",
    DELETE: "v1/contact-us/",
  },
  teamMembers: {
    GET: {
      LIST: "v1/team/members/",
      DETAIL: "v1/team/members/",
    },
    POST: "v1/team/members/",
    PATCH: "v1/team/members/",
    DELETE: "v1/team/members/",
  },
  teamMember: {
    GET: {
      LIST: "v1/team/member/",
      DETAIL: "v1/team/member/",
    },
    POST: "v1/team/member/",
    PATCH: "v1/team/member/",
    PUT: "v1/team/member/",
    DELETE: "v1/team/member/",
  },
  teamRoles: {
    GET: {
      LIST: "v1/team/roles/",
      DETAIL: "v1/team/roles/",
    },
    POST: "v1/team/roles/",
    PATCH: "v1/team/roles/",
    DELETE: "v1/team/roles/",
  },
  team: {
    GET: {
      LIST: "v1/team/",
      DETAIL: "v1/team/",
    },
    POST: "v1/team/",
    PATCH: "v1/team/",
    DELETE: "v1/team/",
  },
  explanation: {
    GET: {
      LIST: "v1/explanation/",
      DETAIL: "v1/explanation/",
    },
    POST: "v1/explanation/",
    PATCH: "v1/explanation/",
    DELETE: "v1/explanation/",
  },
  report: {
    DELETE: "v1/report/delete/",
  },
  submissions: {
    GET: {
      DETAIL: "v2/count/",
    },
  },
  createJWT: {
    POST: "v2/jwt/create/",
  },
  refreshJWT: {
    POST: "v2/jwt/refresh/",
  },
  verifyJWT: {
    POST: "v2/jwt/verify/",
  },
  analytics: {
    GET: {
      LIST: "v1/reports/download/",
    },
  },
};

export const onPremUrlMap = {
  ...apiUrlMap,
  explanation: {
    GET: {
      LIST: "v2/explanation/",
      DETAIL: "v2/explanation/",
    },
    POST: "v2/explanation/",
    PATCH: "v2/explanation/",
    DELETE: "v2/explanation/",
  },
};
