<script>
import SnsModal from "@/components/SnsModal.vue";
import LanguageSwitcher from "@/components/common/LanguageSwitcher.vue";
import UserChangePasswordModal from "@/components/profile/UserChangePasswordModal.vue";

export default {
  name: "UserSettings",
  components: {
    SnsModal,
    LanguageSwitcher,
    UserChangePasswordModal
  },
  props: {
    isModalOn: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close"],
};
</script>

<template>
  <div>
    <SnsModal
      :is-modal-on="isModalOn"
      modal-id="user-settings-modal"
      @close="$emit('close')"
    >
      <template #modal-header>
        <div class="flex items-center font-bold">
          <i class="bi-gear text-xl mr-2" />
          {{ $t("common.label.settings") }}
        </div>
      </template>

      <template #modal-body>
        <div class="flex items-center mb-4 mt-8">
          <i class="bi-translate text-xl mr-2" />
          {{ $t("common.label.language") }}
        </div>

        <div class="flex">
          <div class="basis-full">
            <LanguageSwitcher />
          </div>
        </div>

        <div class="flex items-center mb-4 mt-8">
          <i class="bi-key text-xl mr-2" />
          {{ $t("common.action.changePassword") }}
        </div>
        <UserChangePasswordModal />
      </template>
    </SnsModal>
  </div>
</template>
