/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Verzenden",
      register: "Registreren",
      login: "Inloggen",
      continue: "Doorgaan",
      confirm: "Bevestigen",
      close: "Sluiten",
      back: "Terug",
      browse: "Bestanden zoeken",
      save: "Opslaan",
      cancel: "Annuleren",
      delete: "Verwijderen",
      create: "Aanmaken",
      invite: "Uitnodigen",
      analyze: "Analyseren",
      select: "Selecteren",
      selectAll: "Alles selecteren",
      clearFilters: "Filters wissen",
      exportReport: "Rapport Exporteren",
      exportAsPDF: "Exporteren als PDF",
      exportCSV: "CSV Exporteren",
      analyzeVoice: "Stem Analyseren",
      backToAll: "Terug naar alle rapporten",
      downloadVideoReport: "Videorapport downloaden",
      generateVideoReport: "Videorapport genereren",
      startDeepfake: "Deepfake-detectie starten",
      changePassword: "Wachtwoord wijzigen",
      logout: "Uitloggen",
      editMember: "Teamlid bewerken",
      editTeam: "Team bewerken",
      newUser: "Nieuwe gebruiker",
      createTeam: "Nieuw team aanmaken",
      copy: "Kopiëren",
      showThumbnails: "Miniaturen Weergeven",
      blurThumbnails: "Miniaturen Vervagen",
      showMore: "Meer weergeven",
      showLess: "Minder weergeven",
    },
    loading: {
      submitting: "Bezig met verzenden",
      registering: "Bezig met registreren",
      loggingIn: "Bezig met inloggen",
      sending: "Bezig met verzenden",
      inProgress: "Bezig",
      changing: "Bezig met wijzigen",
      processing: "Bezig met verwerken",
      exporting: "Bezig met exporteren",
      generating: "Bezig met genereren",
      loading: "Bezig met laden"
    },
    placeholder: {
      email: "{'voorbeeld@mail.com'}",
      password: "*********",
      enterUrl: "URL invoeren",
      enterEmail: "E-mailadres invoeren",
      enterTeamName: "Teamnaam invoeren",
      select: "Selecteer een waarde",
      searchByNameAndEmail: "Zoeken op naam of e-mailadres",
    },
    label: {
      email: "E-mail",
      password: "Wachtwoord",
      confirmPassword: "Wachtwoord bevestigen",
      name: "Naam",
      firstName: "Voornaam",
      lastName: "Achternaam",
      phoneNumber: "Telefoonnummer",
      industry: "Branche",
      companyWebsite: "Bedrijfswebsite",
      companyEmail: "Zakelijke e-mail",
      oldPassword: "Oud wachtwoord",
      newPassword: "Nieuw wachtwoord",
      teamName: "Teamnaam",
      teamVisibility: "Teamzichtbaarheid",
      role: "Rol",
      emailAddress: "E-mailadres",
      dragAndDrop: "Sleep mediabestanden hierheen",
      browseFiles: "Bestanden zoeken",
      or: "of",
      hide: "Verbergen",
      show: "Tonen",
      all: "Alles",
      selected: "{count} Geselecteerd",
      minutes: "min",
      allResults: "Alle resultaten",
      audio: "audio",
      video: "video",
      image: "afbeelding",
      pdf: "pdf",
      url: "URL",
      fileName: "Bestandsnaam",
      reportId: "Rapport-ID",
      searchType: "Zoektype",
      searchBy: "Zoeken op",
      status: "Status",
      user: "Gebruiker",
      serviceType: "Servicetype",
      date: "Datum",
      report: "Rapport",
      reportStatus: "Rapportstatus",
      extractedDetails: "Geëxtraheerde details",
      settings: "Instellingen",
      language: "Taal",
      noTeamMembers: "Geen teamleden gevonden.",
      currentUser: "Huidige gebruiker",
      inviteSent: "Uitnodiging verzonden",
      members: "{count} Leden",
      timeRange: "Tijdsbereik",
      duration: "Duur",
      speaker: "Spreker"
    }
  },
  component: {
    list: {
      title: "Resultatenlijst",
      noData: "Geen gegevens gevonden",
      noDataForEmail: "Geen resultaten gevonden voor dit e-mailadres:",
      searchPlaceholder: "E-mail zoeken...",
      resultsFound: "Resultaten gevonden: {count}",
      deleteModal: {
        title: "Geselecteerde items verwijderen",
        message: "Deze rapporten worden verwijderd. Weet u zeker dat u wilt doorgaan?",
      },
      table: {
        header: {
          media: "Media",
          info: "Info",
          result: "Resultaat",
          riskScore: "Document-risicoscore"
        },
      },
    },
    status: {
      inProgress: "In behandeling",
      completed: "Voltooid",
      failed: "Mislukt",
      verified: "Geverifieerd",
      notVerified: "Niet geverifieerd",
      valid: "Geldig",
      suspicious: "Verdacht",
      serverError: "Serverfout",
      extractionFailed: "Extractie mislukt",
      tooltip: {
        analyzing: "Inhoud wordt geanalyseerd...",
        urlExtractionFailed: "URL-inhoudsextractie mislukt",
        urlExtractionFailedDesc: "De inhoud kon niet worden geëxtraheerd uit de opgegeven URL.",
        fakeDetected: "Nepinhoud of spoofingpoging gedetecteerd",
        fakeDetectedDesc: "Deze inhoud is gemarkeerd als nep of bevat een spoofingpoging.",
        checkCompleted: "Controle succesvol voltooid",
        checkCompletedDesc: "Geen afwijkingen of spoofingpogingen gevonden.",
        serverErrorDesc: "Er is een fout opgetreden. Probeer het later opnieuw."
      }
    },
    table: {
      detection: {
        faceManCheck: "Gezichtsmanipulatiecontrole",
        aiGenCheck: "AI-gegenereerde inhoudscontrole",
        forensicAnalysisCheck: "Forensische analysecontrole",
        voiceAnalysisCheck: "Stemanalysecontrole",
        confidence: "Betrouwbaarheid",
        modelGenerator: "Modelgenerator",
        manipulationType: "Manipulatietype",
        noFace: "Gezicht te klein/Geen gezicht",
        noVoice: "Geen stem",
        details: "Details",
        alert: "Waarschuwing",
        creationDate: "Aanmaakdatum",
        modifyDate: "Wijzigingsdatum",
        notAvailable: "N/B",
        description: {
          confidence: "Hoge betrouwbaarheid duidt op duidelijke tekenen van AI-manipulatie, met minimaal 50% zekerheid",
          confidenceAI: "Hoge betrouwbaarheid duidt op duidelijke tekenen van AI-generatie, met minimaal 50% zekerheid",
          modelGenerator: "Modelgenerator: indien weergegeven, kan de detector ook identificeren welk model als generator is gebruikt",
          manipulationType: "Manipulatietype: indien weergegeven, kan de detector ook identificeren of dit een gezichtswissel, gezichtsnabootsing, lipsynchronisatie of gezichtsanimatie is"
        }
      }
    },
    pagination: {
      itemPerPage: "Items per pagina:",
      range: "{from}-{to} van {total}",
    },
    modal: {
      upgradePlan: {
        title: "Plan upgraden",
        message: "U kunt contact opnemen met uw accountmanager om uw plan te upgraden door op de onderstaande knop te klikken.",
        demoMessage: "Door te klikken op \"Ik wil mijn plan upgraden\" wordt u gecontacteerd door uw accountmanager die de verschillende opties zal uitleggen.\nWilt u doorgaan?",
        licenceMessage: "Uw licentie is verlopen. Neem contact met ons op om uw licentie te verlengen.",
        monthlyLimitReached: "Maandelijks limiet bereikt",
        trialLimitReached: "U heeft uw maandelijkse proefaanvragenlimiet van {limit} bereikt.",
        action: "Ik wil mijn plan upgraden",
      },
    }
  },
  feedback: {
    error: {
      label: "Fout!",
      default: "Er is een fout opgetreden",
      server: "Er is een serverfout opgetreden",
      generic: "Er is iets misgegaan. Probeer het later opnieuw.",
      unsupportedFileType: "Uw abonnement staat het uploaden van dit bestandstype niet toe",
      terms: "Accepteer de Algemene Voorwaarden!",
      upload: "Uploaden van bestand mislukt",
    },
    success: {
      label: "Succes!",
      default: "Operatie geslaagd",
      passwordChanged: "Wachtwoord succesvol gewijzigd",
      registration: "Uw verzoek is verzonden ter goedkeuring",
      contact: "Bedankt voor uw verzoek, we nemen binnenkort contact met u op",
      copy: "Gekopieerd naar klembord",
    },
    warning: {
      label: "Waarschuwing!",
      trialDisclaimer: "U gebruikt de proefversie van het platform. Upgrade naar het premium-plan om het platform zonder beperkingen te gebruiken.",
      submissionDisclaimer: "Verstuur geen persoonlijke informatie. Sensity is niet verantwoordelijk voor de inhoud van uw inzending."
    },
    info: {
      agreeTo: "Ik ga akkoord met het volgende:",
      terms: "Algemene voorwaarden",
      privacy: "Privacybeleid",
    },
    validation: {
      required: "Dit veld is verplicht",
      url: "Voer een geldige URL in",
      email: "Voer een geldig e-mailadres in",
      password: "Voer een geldig wachtwoord in",
      passwordMismatch: "Wachtwoorden komen niet overeen",
      numeric: "Dit veld moet numeriek zijn",
      workEmail: "Voer een zakelijk e-mailadres in",
      website: "Voer een geldige website in",
      phoneNumber: "Voer een geldig telefoonnummer in",
      allFieldsRequired: "Vul alle velden in!",
      allFieldsCorrect: "Vul alle velden correct in!",
      termsRequired: "Accepteer de algemene voorwaarden!"
    },
    limitation: {
      maxFileSize: "Maximale bestandsgrootte",
      maxFile: "Maximaal aantal gelijktijdig te uploaden bestanden",
      invalidFile: "Ongeldig bestandstype",
      singleFileWarning: "U kunt slechts één bestand neerzetten",
      maxFileWarning: "U kunt slechts {count} bestanden tegelijk neerzetten",
      maxFileNameLength: "De bestandsnaam mag maximaal {count} tekens bevatten",
      badExtension: "Ongeldige bestandsextensie",
      maxAudioDuration: "Maximale audioduur {value} minuten",
      maxVideoDuration: "Maximale videoduur {value} minuten",
      maxVideoResolution: "Maximale videoresolutie {height}x{width} of {width}x{height}",
      fileAlreadyUploaded: "Bestand {filename} is al geüpload",
      unsupportedFileType: "Niet-ondersteund bestandstype voor uw abonnement"
    }
  },
  page: {
    home: {
      title: "Dashboard",
      subtitle: "Bekijk gebruiks- en inzendingsdetails",
      stats: {
        monthlyLeftCredits: "Resterende maandelijkse credits",
        monthlyUsage: "Maandelijks gebruik",
        accountExpire: "Account verloopt op",
        getStarted: "Haal het beste uit Sensity",
        videoTitle: "Aan de slag met Sensity: functies en resultaten"
      }
    },
    auth: {
      login: {
        title: "Inloggen",
        subtitle: "Nog geen account?",
        signUpLink: "Gratis registreren",
        forgotPassword: "Wachtwoord vergeten?"
      },
      register: {
        title: "Account aanmaken",
        subtitle: "Heeft u al een account?",
        emailDisclaimer: "Uw registratie wordt goedgekeurd door een menselijke operator. Gratis domeinen en tijdelijke domeinen worden standaard niet als legitiem beschouwd",
        detailsPlaceholder: "Details \nVertel ons meer over uw organisatie en welke doelen u wilt bereiken met Sensity. Zonder uitgebreide informatie wordt uw account afgewezen"
      },
      passwordRecovery: {
        title: "Wachtwoordherstel",
        subtitle: "Terug naar",
        message: "Voer het e-mailadres in dat u gebruikt voor uw account.",
        successMessage: "We hebben instructies voor het opnieuw instellen van uw wachtwoord naar uw e-mailadres gestuurd. Als u binnen tien minuten geen e-mail ontvangt, controleer dan of het opgegeven adres correct is."
      },
      confirmPassword: {
        title: "Wachtwoord opnieuw instellen"
      },
      changePassword: {
        title: "Wachtwoord wijzigen"
      }
    },
    analytics: {
      title: "Analytics",
      subtitle: "Verken gedetailleerde inzichten van alle geüploade content voor platform- en API-inzendingen."
    },
    team: {
      title: "Teambeheer",
      visibility: {
        visible: "Inzendingen zijn zichtbaar voor het hele team",
        notVisible: "Inzendingen zijn niet zichtbaar voor het hele team",
        tooltip: {
          visible: "Uw teaminzendingen zijn zichtbaar voor alle teamleden.",
          notVisible: "Uw inzendingen zijn privé voor elk teamlid."
        },
        hint: "Als u uw teaminzendingen zichtbaar wilt maken voor alle teamleden, vink dan dit vakje aan. Vink het niet aan als u wilt dat de inzendingen van elk teamlid privé blijven."
      },
      addNewMemberModal: {
        title: "Nieuw teamlid toevoegen",
        desc: "Voer het e-mailadres van het nieuwe teamlid in.",
        existingMember: "Deze gebruiker is al lid van het team."
      },
      upgradeModal: {
        title: "Teambeheer is een premium functie",
        desc: "Door te klikken op \"Ik wil mijn plan upgraden\" wordt u gecontacteerd door uw accountmanager die de verschillende opties zal uitleggen.",
        confirm: "Wilt u doorgaan?",
        button: "Ik wil mijn plan upgraden"
      },
      deleteTeamMemberModal: {
        title: "Teamlid verwijderen",
        desc: "Deze gebruikers worden uit het team verwijderd. Weet u zeker dat u wilt doorgaan?"
      },
      createTeamModal: {
        title: "Nieuw team aanmaken",
        desc: "Voer een naam in voor uw team"
      },
      newUserTooltip: {
        analyst: "Neem contact op met uw teambeheerder om meer gebruikers toe te voegen.",
        admin: "Voeg nieuwe gebruikers toe aan uw team."
      },
    },
    deepfake: {
      title: "Deepfake-detectie",
      subtitle: "Analyseer verdachte media om audio- en video-deepfakes en door AI gegenereerde content te detecteren.",
      button: "Start deepfake-detectie",
      info: {
        automation: "Wilt u inzendingen automatiseren?",
        checkDocs: "Bekijk onze API-documentatie",
        checkDocsOnPrem: "Raadpleeg onze on-premise gebruikershandleiding om onze API-documentatie te bekijken.",
        contactSupport: "en neem contact met ons op via"
      },
      report: {
        overall: {
          user: "Gebruiker",
          fileName: "Bestandsnaam",
          fileSize: "Bestandsgrootte (KB)",
          fileHash: "Bestandshash (SHA256)",
          submissionDate: "Inzendingsdatum",
          submissionTime: "Inzendingstijd",
          fileResolution: "Bestandsresolutie",
          url: "URL",
          faces: "gezichten",
          face: "gezicht",
          processingMessage: "Videorapport wordt verwerkt. Dit kan enkele minuten duren.",
          selectFaceMessage: "Selecteer ten minste één gezicht om een videorapport te genereren.",
          enableVoiceAnalysisMessage: "Neem contact op met support@sensity.ai om de stemanalyseservice te activeren",
          exportingReport: "Rapport exporteren",
          processingVideo: "Video verwerken"
        },
        explanation: {
          fakeFacesDetected: "Nepgezichten gedetecteerd",
          faceExplanation: "Door gezichten te selecteren worden de bijbehorende kaders boven de media weergegeven.",
          faceExplanationVideo: "en in de geëxporteerde video.",
          selected: "geselecteerd",
          viewOnVideo: "Bekijken in video",
          exportVideoLabel: "Video exporteren met geselecteerde gezichten",
          faceResolution: "Gezichtsresolutie",
          low: "laag",
          lowResolutionInfo: "Lage gezichtsresolutie kan de nauwkeurigheid van de detector beïnvloeden",
          viewAt: "Bekijken op",
          pixelBasedAssessment: "Pixelgebaseerde beoordeling",
          pixelBasedInfo: "Dit gedeelte biedt visuele weergaven ter bevestiging van de bovenstaande resultaten",
          expandImage: "Afbeelding vergroten",
          expandVideoFrame: "Videoframe vergroten",
          heatmapInfo: "De heatmap toont de gebieden waar het model een hogere waarschijnlijkheid van synthese op pixelniveau heeft gedetecteerd",
          sectionDesc: "Dit gedeelte biedt visuele weergaven ter bevestiging van de bovenstaande resultaten",
          pixelAndSegmentTitle: "Pixel- en segmentgebaseerde beoordeling",
          faceAndPixelTitle: "Gezichtsuitlijning en pixelgebaseerde beoordeling",
          segmentBasedNotAvailable: "Segmentgebaseerde beoordeling is niet beschikbaar voor deze media",
          segmentBasedAssesment: "Segmentgebaseerde beoordeling",
          segmentBasedInfo: "De detector identificeert de objecten in de afbeelding die het meest waarschijnlijk door AI zijn gegenereerd",
          faceAlignmentAssesment: "Gezichtsuitlijningsbeoordeling",
          faceAlignmentInfo: "Door deze GAN-familie gegenereerde afbeeldingen kunnen worden herkend aan de vaste positie van ogen en mond",
          fakeSpeakersDetected: "Valse Sprekers Gedetecteerd"
        },
      },
      form: {
        title: "Media uploaden",
        tab: {
          file: "Bestand",
          url: "URL",
          urlNotAvailable: "URL-upload is niet beschikbaar bij on-premise"
        },
      }
    },
    document: {
      title: "Documentverificatie",
      subtitle: "Haal belangrijke informatie op en valideer de authenticiteit van documenten.",
      button: "Start Documentverificatie",
    },
    identity: {
      title: "Identiteitsverificatie",
      subtitle: "Voert identiteitsverificatie en levendigheidsdetectie uit.",
      button: "Start Identiteitsverificatie",
    },
    developer: {
      title: "Ontwikkelaar",
      subtitle: "Beheer uw API-sleutels en gebruik",
      apiToken: "API-token"
    }
  },
  name: {
    service: {
      deepfake: "Deepfake-detectie",
      aiGen: "Door AI gegenereerde content",
      faceMan: "Gezichtsmanipulatie",
      document: "Documentverificatie",
      forensic: "Forensische analyse",
      id: "Identiteitsverificatie",
      liveness: "Video-liveness",
      pixel: "Pixelanalyse",
      voice: "Stemanalyse",
      faceMatch: "Gezichtsvergelijking",
      title: "Sensity Enterprise",
      idDoc: "ID-documentlezer"
    },
    language: {
      english: "Engels",
      turkish: "Turks (Türkçe)",
      uzbek: "Oezbeeks (O'zbekcha)",
      spanish: "Spaans (Español)",
      korean: "Koreaans (한국어)",
      russian: "Russisch (Русский)",
      hindi: "Hindi (हिंदी)",
      german: "Duits (Deutsch)",
      chinese: "Chinees (中文)",
      arabic: "Arabisch (العربية)",
      indonesian: "Indonesisch (Bahasa)",
      vietnamese: "Vietnamees (Tiếng Việt)",
      japanese: "Japans (日本語)",
      italian: "Italiaans (Italiano)",
      french: "Frans (Français)"
    }
  }
}
