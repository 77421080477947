<script>
import { mapGetters } from "vuex";
import SidebarMenuLayout from "@/views/layouts/SidebarMenuLayout";
import SnsLogo from "@/components/SnsLogo.vue";

export default {
  name: "SidebarLayout",
  components: {
    SnsLogo,
    SidebarMenuLayout,
  },
  computed: {
    ...mapGetters(["getUser", "getNavigationOpen", "getTheme"]),
    // TODO: ssg refactor
    navigationOpen: {
      get() {
        return this.getNavigationOpen;
      },
      set(payload) {
        this.$store.commit("setNavigationOpen", payload);
      },
    },
    user() {
      return this.getUser;
    },
  },
  watch: {
    navigationOpen(payload) {
      if (payload) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
    $route() {
      this.$store.commit("setNavigationOpen", false);
    },
  },
};
</script>

<template>
  <div class="sidebar">
    <nav class="navigation" :class="{ open: navigationOpen }">
      <div class="navigation-inner">
        <div class="navigation-top">
          <div class="navigation-logo">
            <SnsLogo />

            <button
              class="navigation-close"
              @click="navigationOpen = !navigationOpen"
            >
              <i class="bi bi-x close-icon" />
            </button>
          </div>

          <SidebarMenuLayout v-if="user && user.id" />
        </div>

        <div class="text-xs text-base-content/70 px-4 py-2">
          v2.37.17
        </div>
      </div>
    </nav>
    <div
      v-if="navigationOpen"
      class="navigation-overlay"
      @click="navigationOpen = !navigationOpen"
    />
  </div>
</template>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  width: $sidebar-width-lg;
  transition: all 0.3s;

  @media screen and (max-width: 1320px) and (min-width: 768px) {
    width: $sidebar-width-md;

    div.navigation-inner {
      .navigation-top {
        .navigation-logo {
          img {
            width: 80%;
          }
          padding: 2rem 2rem;
        }
      }
    }
  }

  &.open {
    transform: translateX(0);
    z-index: 11;
  }

  @media screen and (max-width: 767px) {
    transform: translateX(-100%);
    width: 70%;
    max-width: $sidebar-width-xs;
  }

  .navigation-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @apply bg-base-100;
    position: relative;
    z-index: 10;
    height: 100%;
    overflow-x: auto;

    .navigation-top {
      .navigation-logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem;

        .navigation-close {
          padding: 0.2rem;
          font-size: 0;
          background-color: transparent;
          outline: none;
          border: none;

          @media screen and (min-width: $screen-size-tablet) {
            display: none;
          }
          .close-icon {
            font-size: 30px;
          }
        }
      }
    }
  }
}

.navigation-overlay {
  @apply bg-neutral bg-opacity-70;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media screen and (min-width: $screen-size-tablet) {
    display: none;
  }
}
</style>
