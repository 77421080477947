/* eslint-disable */
export default {
  common: {
    action: {
      submit: "提交",
      register: "注册",
      login: "登录",
      continue: "继续",
      confirm: "确认",
      close: "关闭",
      back: "返回",
      browse: "浏览文件",
      save: "保存",
      cancel: "取消",
      delete: "删除",
      create: "创建",
      invite: "邀请",
      analyze: "分析",
      select: "选择",
      selectAll: "全选",
      clearFilters: "清除筛选",
      exportReport: "导出报告",
      exportAsPDF: "导出为PDF",
      exportCSV: "导出CSV",
      analyzeVoice: "语音分析",
      backToAll: "返回所有报告",
      downloadVideoReport: "下载视频报告",
      generateVideoReport: "生成视频报告",
      startDeepfake: "开始深度伪造检测",
      changePassword: "修改密码",
      logout: "退出登录",
      editMember: "编辑团队成员",
      editTeam: "编辑团队",
      newUser: "新用户",
      createTeam: "创建新团队",
      copy: "复制",
      showThumbnails: "显示缩略图",
      blurThumbnails: "模糊缩略图",
      showMore: "显示更多",
      showLess: "显示更少",
    },
    loading: {
      submitting: "提交中",
      registering: "注册中",
      loggingIn: "登录中",
      sending: "发送中",
      inProgress: "进行中",
      changing: "修改中",
      processing: "处理中",
      exporting: "导出中",
      generating: "生成中",
      loading: "加载中"
    },
    placeholder: {
      email: "{'example@mail.com'}",
      password: "*********",
      enterUrl: "输入网址",
      enterEmail: "输入电子邮箱地址",
      enterTeamName: "输入团队名称",
      select: "请选择一个值",
      searchByNameAndEmail: "按姓名或电子邮箱搜索",
    },
    label: {
      email: "电子邮箱",
      password: "密码",
      confirmPassword: "确认密码",
      name: "姓名",
      firstName: "名字",
      lastName: "姓氏",
      phoneNumber: "电话号码",
      industry: "行业",
      companyWebsite: "公司网站",
      companyEmail: "公司邮箱",
      oldPassword: "旧密码",
      newPassword: "新密码",
      teamName: "团队名称",
      teamVisibility: "团队可见性",
      role: "角色",
      emailAddress: "电子邮箱地址",
      dragAndDrop: "将媒体文件拖放到此处",
      browseFiles: "浏览文件",
      or: "或",
      hide: "隐藏",
      show: "显示",
      all: "全部",
      selected: "已选择 {count} 项",
      minutes: "分钟",
      allResults: "所有结果",
      audio: "音频",
      video: "视频",
      image: "图片",
      pdf: "PDF",
      url: "网址",
      fileName: "文件名",
      reportId: "报告ID",
      searchType: "搜索类型",
      searchBy: "搜索依据",
      status: "状态",
      user: "用户",
      serviceType: "服务类型",
      date: "日期",
      report: "报告",
      reportStatus: "报告状态",
      extractedDetails: "提取的详细信息",
      settings: "设置",
      language: "语言",
      noTeamMembers: "未找到团队成员。",
      currentUser: "当前用户",
      inviteSent: "已发送邀请",
      members: "{count} 成员",
      timeRange: "时间范围",
      duration: "时长",
      speaker: "说话者"
    }
  },
  component: {
    list: {
      title: "结果列表",
      noData: "未找到数据",
      noDataForEmail: "未找到此电子邮箱的结果：",
      searchPlaceholder: "搜索电子邮箱...",
      resultsFound: "找到的结果：{count}",
      deleteModal: {
        title: "删除所选项目",
        message: "这些报告将被删除。您确定要继续吗？",
      },
      table: {
        header: {
          media: "媒体",
          info: "信息",
          result: "结果",
          riskScore: "文档风险分数"
        },
      },
    },
    status: {
      inProgress: "进行中",
      completed: "已完成",
      failed: "失败",
      verified: "已验证",
      notVerified: "未验证",
      valid: "有效",
      suspicious: "可疑",
      serverError: "服务器错误",
      extractionFailed: "提取失败",
      tooltip: {
        analyzing: "正在分析内容...",
        urlExtractionFailed: "URL内容提取失败",
        urlExtractionFailedDesc: "无法从提供的URL提取内容。",
        fakeDetected: "检测到虚假内容或欺骗尝试",
        fakeDetectedDesc: "此内容已被标记为虚假或包含欺骗尝试。",
        checkCompleted: "检查成功完成",
        checkCompletedDesc: "未发现异常或欺骗尝试。",
        serverErrorDesc: "发生错误。请稍后重试。"
      }
    },
    table: {
      detection: {
        faceManCheck: "人脸操作检查",
        aiGenCheck: "AI生成内容检查",
        forensicAnalysisCheck: "取证分析检查",
        voiceAnalysisCheck: "语音分析检查",
        confidence: "置信度",
        modelGenerator: "模型生成器",
        manipulationType: "操作类型",
        noFace: "人脸太小/无人脸",
        noVoice: "无语音",
        details: "详细信息",
        alert: "警告",
        creationDate: "创建日期",
        modifyDate: "修改日期",
        notAvailable: "不可用",
        description: {
          confidence: "高置信度表示明显的AI操作迹象，确信度至少为50%",
          confidenceAI: "高置信度表示明显的AI生成迹象，确信度至少为50%",
          modelGenerator: "模型生成器：显示时，检测器还可以识别使用了哪个生成器模型",
          manipulationType: "操作类型：显示时，检测器还可以识别这是换脸、人脸重演、唇形同步还是人脸动画"
        }
      }
    },
    pagination: {
      itemPerPage: "每页项目数：",
      range: "第 {from}-{to} 项，共 {total} 项",
    },
    modal: {
      upgradePlan: {
        title: "升级套餐",
        message: "您可以通过点击下方按钮联系您的客户经理升级套餐。",
        demoMessage: "点击\"我要升级套餐\"，您的客户经理将联系您并解释各种选项。\n您要继续吗？",
        licenceMessage: "您的许可证已过期。请联系我们续订许可证。",
        monthlyLimitReached: "已达到月度限制",
        trialLimitReached: "您已达到每月试用提交限制 {limit} 次。",
        action: "我要升级套餐",
      },
    }
  },
  feedback: {
    error: {
      label: "错误！",
      default: "发生错误",
      server: "服务器错误",
      generic: "出现问题。请稍后重试。",
      unsupportedFileType: "您的订阅不允许上传此类文件",
      terms: "请接受条款和条件！",
      upload: "文件上传失败",
    },
    success: {
      label: "成功！",
      default: "操作成功",
      passwordChanged: "密码修改成功",
      registration: "您的请求已提交等待批准",
      contact: "感谢您的请求，我们将尽快与您联系",
      copy: "已复制到剪贴板",
    },
    warning: {
      label: "警告！",
      trialDisclaimer: "您正在使用平台的试用版。升级到高级套餐以无限制使用平台。",
      submissionDisclaimer: "请勿提交个人信息。Sensity不对您提交的内容负责。"
    },
    info: {
      agreeTo: "我同意以下内容：",
      terms: "条款和条件",
      privacy: "隐私政策",
    },
    validation: {
      required: "此字段为必填项",
      url: "请输入有效的网址",
      email: "请输入有效的电子邮箱",
      password: "请输入有效的密码",
      passwordMismatch: "密码不匹配",
      numeric: "此字段必须为数字",
      workEmail: "请提供工作电子邮箱",
      website: "请输入有效的网站",
      phoneNumber: "请输入有效的电话号码",
      allFieldsRequired: "请填写所有字段！",
      allFieldsCorrect: "请正确填写所有字段！",
      termsRequired: "请接受条款和条件！"
    },
    limitation: {
      maxFileSize: "最大文件大小",
      maxFile: "同时上传的最大文件数",
      invalidFile: "无效的文件类型",
      singleFileWarning: "您只能放置一个文件",
      maxFileWarning: "您一次只能放置 {count} 个文件",
      maxFileNameLength: "文件名最多可以包含 {count} 个字符",
      badExtension: "文件扩展名无效",
      maxAudioDuration: "最大音频时长 {value} 分钟",
      maxVideoDuration: "最大视频时长 {value} 分钟",
      maxVideoResolution: "最大视频分辨率 {height}x{width} 或 {width}x{height}",
      fileAlreadyUploaded: "文件 {filename} 已经上传",
      unsupportedFileType: "您的订阅不支持此文件类型"
    }
  },
  page: {
    home: {
      title: "仪表板",
      subtitle: "查看使用情况和提交详情",
      stats: {
        monthlyLeftCredits: "剩余月度额度",
        monthlyUsage: "月度使用量",
        accountExpire: "账户将在以下日期过期",
        getStarted: "充分利用 Sensity",
        videoTitle: "开始使用 Sensity：功能和成果"
      }
    },
    auth: {
      login: {
        title: "登录",
        subtitle: "还没有账户？",
        signUpLink: "免费注册",
        forgotPassword: "忘记密码？"
      },
      register: {
        title: "创建账户",
        subtitle: "已有账户？",
        emailDisclaimer: "您的注册将由人工操作员审核。免费域名和临时域名默认不会被视为合法。",
        detailsPlaceholder: "详细信息 \n请告诉我们更多关于您的组织以及您希望使用 Sensity 实现的目标。如果没有详尽的信息，您的账户将被拒绝"
      },
      passwordRecovery: {
        title: "密码恢复",
        subtitle: "返回",
        message: "输入您账户使用的电子邮箱。",
        successMessage: "我们已经向您的电子邮箱发送了密码重置说明。如果十分钟内没有收到邮件，请检查提交的地址是否正确。"
      },
      confirmPassword: {
        title: "重置密码"
      },
      changePassword: {
        title: "修改密码"
      }
    },
    analytics: {
      title: "分析",
      subtitle: "探索平台和API提交的所有上传内容的详细见解。"
    },
    team: {
      title: "团队管理",
      visibility: {
        visible: "提交内容对所有团队可见",
        notVisible: "提交内容对团队不可见",
        tooltip: {
          visible: "您的团队提交内容对所有团队成员可见。",
          notVisible: "您的提交内容对每个团队成员是私密的。"
        },
        hint: "如果您想让团队提交内容对所有团队成员可见，请勾选此框。如果您希望每个团队成员的提交保持私密，请不要勾选。"
      },
      addNewMemberModal: {
        title: "添加新团队成员",
        desc: "请提供新团队成员的电子邮箱地址。",
        existingMember: "该用户已经是团队成员。"
      },
      upgradeModal: {
        title: "团队管理是高级功能",
        desc: "点击\"我要升级套餐\"，您的客户经理将联系您并解释各种选项。",
        confirm: "您要继续吗？",
        button: "我要升级套餐"
      },
      deleteTeamMemberModal: {
        title: "删除团队成员",
        desc: "这些用户将从团队中删除。您确定要继续吗？"
      },
      createTeamModal: {
        title: "创建新团队",
        desc: "请为您的团队提供一个名称"
      },
      newUserTooltip: {
        analyst: "请联系您的团队管理员添加更多用户。",
        admin: "向您的团队添加新用户。"
      },
    },
    deepfake: {
      title: "深度伪造检测",
      subtitle: "分析可疑媒体以检测音频和视频深度伪造以及AI生成的内容。",
      button: "开始深度伪造检测",
      info: {
        automation: "想要自动化提交？",
        checkDocs: "查看我们的API文档",
        checkDocsOnPrem: "查看我们的本地用户手册以查看API文档。",
        contactSupport: "并在以下地址联系我们"
      },
      report: {
        overall: {
          user: "用户",
          fileName: "文件名",
          fileSize: "文件大小（KB）",
          fileHash: "文件哈希值（SHA256）",
          submissionDate: "提交日期",
          submissionTime: "提交时间",
          fileResolution: "文件分辨率",
          url: "网址",
          faces: "张人脸",
          face: "张人脸",
          processingMessage: "正在处理视频报告。这可能需要几分钟。",
          selectFaceMessage: "请至少选择一张人脸以生成视频报告。",
          enableVoiceAnalysisMessage: "要启用语音分析服务，请联系 support@sensity.ai",
          exportingReport: "导出报告",
          processingVideo: "处理视频"
        },
        explanation: {
          fakeFacesDetected: "检测到虚假人脸",
          faceExplanation: "通过选择人脸，相关的边界框将显示在媒体上方。",
          faceExplanationVideo: "并在导出的视频中。",
          selected: "已选择",
          viewOnVideo: "在视频中查看",
          exportVideoLabel: "导出带有选定人脸的视频",
          faceResolution: "人脸分辨率",
          low: "低",
          lowResolutionInfo: "低人脸分辨率可能影响检测器的准确性",
          viewAt: "查看于",
          pixelBasedAssessment: "基于像素的评估",
          pixelBasedInfo: "本节提供视觉表示以确认上述结果",
          expandImage: "展开图片",
          expandVideoFrame: "展开视频帧",
          heatmapInfo: "热图显示模型在像素级别检测到合成可能性较高的区域",
          sectionDesc: "本节提供视觉表示以确认上述结果",
          pixelAndSegmentTitle: "基于像素和分段的评估",
          faceAndPixelTitle: "人脸对齐和基于像素的评估",
          segmentBasedNotAvailable: "此媒体不提供基于分段的评估",
          segmentBasedAssesment: "基于分段的评估",
          segmentBasedInfo: "检测器识别图像中最可能由AI生成的对象",
          faceAlignmentAssesment: "人脸对齐评估",
          faceAlignmentInfo: "这个GAN系列生成的图像可以通过眼睛和嘴巴的固定位置识别",
          fakeSpeakersDetected: "检测到虚假说话者",
        },
      },
      form: {
        title: "上传媒体",
        tab: {
          file: "文件",
          url: "网址",
          urlNotAvailable: "本地部署不提供URL上传"
        },
      }
    },
    document: {
      title: "文档验证",
      subtitle: "提取关键信息并验证文档的真实性。",
      button: "开始文档验证",
    },
    identity: {
      title: "身份验证",
      subtitle: "执行身份验证和活体检测。",
      button: "开始身份验证",
    },
    developer: {
      title: "开发者",
      subtitle: "管理您的 API 密钥和使用情况",
      apiToken: "API 令牌"
    }
  },
  name: {
    service: {
      deepfake: "深度伪造检测",
      aiGen: "AI生成内容",
      faceMan: "人脸操作",
      document: "文档验证",
      forensic: "取证分析",
      id: "身份验证",
      liveness: "视频活体",
      pixel: "像素分析",
      voice: "语音分析",
      faceMatch: "人脸匹配",
      title: "Sensity Enterprise",
      idDoc: "身份证件读取器"
    },
    language: {
      english: "英语 (English)",
      turkish: "土耳其语 (Türkçe)",
      uzbek: "乌兹别克语 (O'zbekcha)",
      spanish: "西班牙语 (Español)",
      korean: "韩语 (한국어)",
      russian: "俄语 (Русский)",
      hindi: "印地语 (हिंदी)",
      german: "德语 (Deutsch)",
      dutch: "荷兰语 (Nederlands)",
      chinese: "中文",
      arabic: "阿拉伯语 (العربية)",
      indonesian: "印尼语 (Bahasa)",
      vietnamese: "越南语 (Tiếng Việt)",
      japanese: "日语 (日本語)",
      italian: "意大利语 (Italiano)",
      french: "法语 (Français)"
    }
  }
}
