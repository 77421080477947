/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Soumettre",
      register: "S'inscrire",
      login: "Se connecter",
      continue: "Continuer",
      confirm: "Confirmer",
      close: "Fermer",
      back: "Retour",
      browse: "Parcourir les fichiers",
      save: "Enregistrer",
      cancel: "Annuler",
      delete: "Supprimer",
      create: "Créer",
      invite: "Inviter",
      analyze: "Analyser",
      select: "Sélectionner",
      selectAll: "Tout sélectionner",
      clearFilters: "Effacer les filtres",
      exportReport: "Exporter le Rapport",
      exportAsPDF: "Exporter en PDF",
      exportCSV: "Exporter CSV",
      analyzeVoice: "Analyser la Voix",
      backToAll: "Retour à tous les rapports",
      downloadVideoReport: "Télécharger le rapport vidéo",
      generateVideoReport: "Générer le rapport vidéo",
      startDeepfake: "Démarrer la détection Deepfake",
      changePassword: "Changer le mot de passe",
      logout: "Se déconnecter",
      editMember: "Modifier le membre de l'équipe",
      editTeam: "Modifier l'équipe",
      newUser: "Nouvel utilisateur",
      createTeam: "Créer une nouvelle équipe",
      copy: "Copier",
      showThumbnails: "Afficher les Vignettes",
      blurThumbnails: "Flouter les Vignettes",
      showMore: "Afficher Plus",
      showLess: "Afficher Moins",
    },
    loading: {
      submitting: "Soumission en cours",
      registering: "Inscription en cours",
      loggingIn: "Connexion en cours",
      sending: "Envoi en cours",
      inProgress: "En cours",
      changing: "Modification en cours",
      processing: "Traitement en cours",
      exporting: "Exportation en cours",
      generating: "Génération en cours",
      loading: "Chargement"
    },
    placeholder: {
      email: "{'exemple@mail.com'}",
      password: "*********",
      enterUrl: "Saisir l'URL",
      enterEmail: "Saisir l'adresse e-mail",
      enterTeamName: "Saisir le nom de l'équipe",
      select: "Veuillez sélectionner une valeur",
      searchByNameAndEmail: "Rechercher par nom ou adresse e-mail",
    },
    label: {
      email: "E-mail",
      password: "Mot de passe",
      confirmPassword: "Confirmer le mot de passe",
      name: "Nom",
      firstName: "Prénom",
      lastName: "Nom de famille",
      phoneNumber: "Numéro de téléphone",
      industry: "Secteur d'activité",
      companyWebsite: "Site web de l'entreprise",
      companyEmail: "E-mail professionnel",
      oldPassword: "Ancien mot de passe",
      newPassword: "Nouveau mot de passe",
      teamName: "Nom de l'équipe",
      teamVisibility: "Visibilité de l'équipe",
      role: "Rôle",
      emailAddress: "Adresse e-mail",
      dragAndDrop: "Glissez et déposez les fichiers médias ici",
      browseFiles: "Parcourir les fichiers",
      or: "ou",
      hide: "Masquer",
      show: "Afficher",
      all: "Tout",
      selected: "{count} Sélectionné(s)",
      minutes: "min",
      allResults: "Tous les résultats",
      audio: "audio",
      video: "vidéo",
      image: "image",
      pdf: "pdf",
      url: "URL",
      fileName: "Nom du fichier",
      reportId: "ID du rapport",
      searchType: "Type de recherche",
      searchBy: "Rechercher par",
      status: "Statut",
      user: "Utilisateur",
      serviceType: "Type de service",
      date: "Date",
      report: "Rapport",
      reportStatus: "Statut du rapport",
      extractedDetails: "Détails extraits",
      settings: "Paramètres",
      language: "Langue",
      noTeamMembers: "Aucun membre d'équipe trouvé.",
      currentUser: "Utilisateur actuel",
      inviteSent: "Invitation envoyée",
      members: "{count} Membres",
      timeRange: "Plage horaire",
      duration: "Durée",
      speaker: "Orateur"
    }
  },
  component: {
    list: {
      title: "Liste des résultats",
      noData: "Aucune donnée trouvée",
      noDataForEmail: "Aucun résultat trouvé pour cet e-mail :",
      searchPlaceholder: "Rechercher un e-mail...",
      resultsFound: "Résultats trouvés : {count}",
      deleteModal: {
        title: "Supprimer les éléments sélectionnés",
        message: "Ces rapports seront supprimés. Êtes-vous sûr de vouloir continuer ?",
      },
      table: {
        header: {
          media: "Média",
          info: "Info",
          result: "Résultat",
          riskScore: "Score de risque du document"
        },
      },
    },
    status: {
      inProgress: "En cours",
      completed: "Terminé",
      failed: "Échoué",
      verified: "Vérifié",
      notVerified: "Non vérifié",
      valid: "Valide",
      suspicious: "Suspect",
      serverError: "Erreur serveur",
      extractionFailed: "Extraction échouée",
      tooltip: {
        analyzing: "Analyse du contenu...",
        urlExtractionFailed: "Échec de l'extraction du contenu de l'URL",
        urlExtractionFailedDesc: "Le contenu n'a pas pu être extrait de l'URL fournie.",
        fakeDetected: "Contenu factice ou tentative d'usurpation détectée",
        fakeDetectedDesc: "Ce contenu a été signalé comme factice ou contient une tentative d'usurpation.",
        checkCompleted: "Vérification terminée avec succès",
        checkCompletedDesc: "Aucune anomalie ou tentative d'usurpation détectée.",
        serverErrorDesc: "Une erreur s'est produite. Veuillez réessayer plus tard."
      }
    },
    table: {
      detection: {
        faceManCheck: "Vérification de la manipulation faciale",
        aiGenCheck: "Vérification du contenu généré par IA",
        forensicAnalysisCheck: "Vérification de l'analyse médico-légale",
        voiceAnalysisCheck: "Vérification de l'analyse vocale",
        confidence: "Confiance",
        modelGenerator: "Générateur de modèle",
        manipulationType: "Type de manipulation",
        noFace: "Visage trop petit/Pas de visage",
        noVoice: "Pas de voix",
        details: "Détails",
        alert: "Alerte",
        creationDate: "Date de création",
        modifyDate: "Date de modification",
        notAvailable: "N/D",
        description: {
          confidence: "Une confiance élevée indique des signes clairs de manipulation par IA, avec une certitude d'au moins 50%",
          confidenceAI: "Une confiance élevée indique des signes clairs de génération par IA, avec une certitude d'au moins 50%",
          modelGenerator: "Générateur de modèle : lorsqu'il est affiché, le détecteur peut également identifier quel modèle a été utilisé comme générateur",
          manipulationType: "Type de manipulation : lorsqu'il est affiché, le détecteur peut également identifier s'il s'agit d'un échange de visage, d'une réanimation faciale, d'une synchronisation labiale ou d'une animation faciale"
        }
      }
    },
    pagination: {
      itemPerPage: "Éléments par page :",
      range: "{from}-{to} sur {total}",
    },
    modal: {
      upgradePlan: {
        title: "Mettre à niveau le forfait",
        message: "Vous pouvez contacter votre gestionnaire de compte pour mettre à niveau votre forfait en cliquant sur le bouton ci-dessous.",
        demoMessage: "En cliquant sur \"Je veux mettre à niveau mon forfait\", vous serez contacté par votre gestionnaire de compte qui vous expliquera les différentes options.\nVoulez-vous continuer ?",
        licenceMessage: "Votre licence a expiré. Veuillez nous contacter pour renouveler votre licence.",
        monthlyLimitReached: "Limite mensuelle atteinte",
        trialLimitReached: "Vous avez atteint votre limite mensuelle d'essai de {limit} soumissions.",
        action: "Je veux mettre à niveau mon forfait",
      },
    }
  },
  feedback: {
    error: {
      label: "Erreur !",
      default: "Une erreur s'est produite",
      server: "Une erreur serveur s'est produite",
      generic: "Une erreur s'est produite. Veuillez réessayer plus tard.",
      unsupportedFileType: "Votre abonnement ne permet pas de télécharger ce type de fichier",
      terms: "Veuillez accepter les Conditions Générales !",
      upload: "Échec du téléchargement du fichier",
    },
    success: {
      label: "Succès !",
      default: "Opération réussie",
      passwordChanged: "Mot de passe modifié avec succès",
      registration: "Votre demande a été envoyée pour approbation",
      contact: "Merci pour votre demande, nous vous contacterons bientôt",
      copy: "Copié dans le presse-papiers",
    },
    warning: {
      label: "Avertissement !",
      trialDisclaimer: "Vous utilisez la version d'essai de la plateforme. Passez au forfait premium pour utiliser la plateforme sans restrictions.",
      submissionDisclaimer: "Veuillez ne pas soumettre d'informations personnelles. Sensity n'est pas responsable du contenu de votre soumission."
    },
    info: {
      agreeTo: "J'accepte ce qui suit :",
      terms: "Conditions générales",
      privacy: "Politique de confidentialité",
    },
    validation: {
      required: "Ce champ est obligatoire",
      url: "Veuillez saisir une URL valide",
      email: "Veuillez saisir une adresse e-mail valide",
      password: "Veuillez saisir un mot de passe valide",
      passwordMismatch: "Les mots de passe ne correspondent pas",
      numeric: "Ce champ doit être numérique",
      workEmail: "Veuillez fournir un e-mail professionnel",
      website: "Veuillez saisir un site web valide",
      phoneNumber: "Veuillez saisir un numéro de téléphone valide",
      allFieldsRequired: "Veuillez remplir tous les champs !",
      allFieldsCorrect: "Veuillez remplir tous les champs correctement !",
      termsRequired: "Veuillez accepter les conditions générales !"
    },
    limitation: {
      maxFileSize: "Taille maximale du fichier",
      maxFile: "Nombre maximal de fichiers à télécharger en même temps",
      invalidFile: "Type de fichier invalide",
      singleFileWarning: "Vous ne pouvez déposer qu'un seul fichier",
      maxFileWarning: "Vous ne pouvez déposer que {count} fichiers à la fois",
      maxFileNameLength: "Le nom du fichier peut contenir au maximum {count} caractères",
      badExtension: "Extension de fichier incorrecte",
      maxAudioDuration: "Durée audio maximale {value} minutes",
      maxVideoDuration: "Durée vidéo maximale {value} minutes",
      maxVideoResolution: "Résolution vidéo maximale {height}x{width} ou {width}x{height}",
      fileAlreadyUploaded: "Le fichier {filename} est déjà téléchargé",
      unsupportedFileType: "Type de fichier non pris en charge pour votre abonnement"
    }
  },
  page: {
    home: {
      title: "Tableau de bord",
      subtitle: "Voir les détails d'utilisation et de soumission",
      stats: {
        monthlyLeftCredits: "Crédits mensuels restants",
        monthlyUsage: "Utilisation mensuelle",
        accountExpire: "Le compte expirera le",
        getStarted: "Tirez le meilleur parti de Sensity",
        videoTitle: "Débuter avec Sensity : fonctionnalités et résultats"
      }
    },
    auth: {
      login: {
        title: "Se connecter",
        subtitle: "Vous n'avez pas de compte ?",
        signUpLink: "Inscrivez-vous gratuitement",
        forgotPassword: "Mot de passe oublié ?"
      },
      register: {
        title: "Créer un compte",
        subtitle: "Vous avez déjà un compte ?",
        emailDisclaimer: "Votre inscription sera approuvée par un opérateur humain. Les domaines gratuits et temporaires ne seront pas considérés comme légitimes par défaut",
        detailsPlaceholder: "Détails \nVeuillez nous en dire plus sur votre organisation et les objectifs que vous souhaitez atteindre en utilisant Sensity. Sans informations exhaustives, votre compte sera rejeté"
      },
      passwordRecovery: {
        title: "Récupération du mot de passe",
        subtitle: "Retour à",
        message: "Saisissez l'e-mail que vous utilisez pour votre compte.",
        successMessage: "Nous avons envoyé les instructions de réinitialisation du mot de passe à votre adresse e-mail. Si vous ne recevez pas d'e-mail dans les dix minutes, vérifiez que l'adresse soumise est correcte."
      },
      confirmPassword: {
        title: "Réinitialiser le mot de passe"
      },
      changePassword: {
        title: "Changer le mot de passe"
      }
    },
    analytics: {
      title: "Analytique",
      subtitle: "Explorez les informations détaillées de tout le contenu téléchargé pour les soumissions via la plateforme et l'API."
    },
    team: {
      title: "Gestion d'équipe",
      visibility: {
        visible: "Les soumissions sont visibles pour toute l'équipe",
        notVisible: "Les soumissions ne sont pas visibles pour toute l'équipe",
        tooltip: {
          visible: "Les soumissions de votre équipe sont visibles par tous les membres de l'équipe.",
          notVisible: "Vos soumissions sont privées pour chaque membre de l'équipe."
        },
        hint: "Si vous souhaitez rendre les soumissions de votre équipe visibles pour tous les membres de l'équipe, veuillez cocher cette case. Ne la cochez pas si vous souhaitez que les soumissions de chaque membre de l'équipe restent privées."
      },
      addNewMemberModal: {
        title: "Ajouter un nouveau membre d'équipe",
        desc: "Veuillez fournir l'adresse e-mail du nouveau membre de l'équipe.",
        existingMember: "Cet utilisateur est déjà membre de l'équipe."
      },
      upgradeModal: {
        title: "La gestion d'équipe est une fonctionnalité premium",
        desc: "En cliquant sur \"Je veux mettre à niveau mon forfait\", vous serez contacté par votre gestionnaire de compte qui vous expliquera les différentes options.",
        confirm: "Voulez-vous continuer ?",
        button: "Je veux mettre à niveau mon forfait"
      },
      deleteTeamMemberModal: {
        title: "Supprimer un membre de l'équipe",
        desc: "Ces utilisateurs seront supprimés de l'équipe. Êtes-vous sûr de vouloir continuer ?"
      },
      createTeamModal: {
        title: "Créer une nouvelle équipe",
        desc: "Veuillez fournir un nom pour votre équipe"
      },
      newUserTooltip: {
        analyst: "Veuillez contacter votre administrateur d'équipe pour ajouter plus d'utilisateurs.",
        admin: "Ajoutez de nouveaux utilisateurs à votre équipe."
      },
    },
    deepfake: {
      title: "Détection Deepfake",
      subtitle: "Analysez les médias suspects pour détecter les deepfakes audio et vidéo et le contenu généré par l'IA.",
      button: "Démarrer la détection Deepfake",
      info: {
        automation: "Vous voulez automatiser les soumissions ?",
        checkDocs: "Consultez notre documentation API",
        checkDocsOnPrem: "Consultez notre manuel d'utilisation sur site pour voir notre documentation API.",
        contactSupport: "et contactez-nous à"
      },
      report: {
        overall: {
          user: "Utilisateur",
          fileName: "Nom du fichier",
          fileSize: "Taille du fichier (KB)",
          fileHash: "Hash du fichier (SHA256)",
          submissionDate: "Date de soumission",
          submissionTime: "Heure de soumission",
          fileResolution: "Résolution du fichier",
          url: "URL",
          faces: "visages",
          face: "visage",
          processingMessage: "Traitement du rapport vidéo. Cela peut prendre quelques minutes.",
          selectFaceMessage: "Veuillez sélectionner au moins un visage pour générer le rapport vidéo.",
          enableVoiceAnalysisMessage: "Pour activer le service d'analyse vocale, veuillez contacter support@sensity.ai",
          exportingReport: "Exportation du rapport",
          processingVideo: "Traitement de la vidéo"
        },
        explanation: {
          fakeFacesDetected: "Visages factices détectés",
          faceExplanation: "En sélectionnant les visages, les cadres délimitants relatifs s'afficheront au-dessus du média.",
          faceExplanationVideo: "et dans la vidéo exportée.",
          selected: "sélectionné",
          viewOnVideo: "Voir sur la vidéo",
          exportVideoLabel: "Exporter la vidéo avec les visages sélectionnés",
          faceResolution: "Résolution du visage",
          low: "faible",
          lowResolutionInfo: "Une faible résolution du visage peut compromettre la précision du détecteur",
          viewAt: "Voir à",
          pixelBasedAssessment: "Évaluation basée sur les pixels",
          pixelBasedInfo: "Cette section fournit des représentations visuelles confirmant les résultats ci-dessus",
          expandImage: "Agrandir l'image",
          expandVideoFrame: "Agrandir l'image vidéo",
          heatmapInfo: "La carte thermique représente les zones où le modèle a détecté une plus grande probabilité de synthèse au niveau des pixels",
          sectionDesc: "Cette section fournit des représentations visuelles confirmant les résultats ci-dessus",
          pixelAndSegmentTitle: "Évaluation basée sur les pixels et les segments",
          faceAndPixelTitle: "Alignement du visage et évaluation basée sur les pixels",
          segmentBasedNotAvailable: "L'évaluation basée sur les segments n'est pas disponible pour ce média",
          segmentBasedAssesment: "Évaluation basée sur les segments",
          segmentBasedInfo: "Le détecteur identifie les objets de l'image les plus susceptibles d'être générés par l'IA",
          faceAlignmentAssesment: "Évaluation de l'alignement du visage",
          faceAlignmentInfo: "Les images générées par cette famille de GAN peuvent être reconnues par la position fixe des yeux et de la bouche",
          fakeSpeakersDetected: "Locuteurs Falsifiés Détectés",
        },
      },
      form: {
        title: "Télécharger un média",
        tab: {
          file: "Fichier",
          url: "URL",
          urlNotAvailable: "Le téléchargement par URL n'est pas disponible sur site"
        },
      }
    },
    document: {
      title: "Vérification de Documents",
      subtitle: "Extrait des informations clés et valide l'authenticité des documents.",
      button: "Démarrer la Vérification de Documents",
    },
    identity: {
      title: "Vérification d'Identité",
      subtitle: "Effectue la vérification d'identité et la détection de présence réelle.",
      button: "Démarrer la Vérification d'Identité",
    },
    developer: {
      title: "Développeur",
      subtitle: "Gérez vos clés API et votre utilisation",
      apiToken: "Jeton API"
    },
  },
  name: {
    service: {
      deepfake: "Détection Deepfake",
      aiGen: "Contenu généré par IA",
      faceMan: "Manipulation faciale",
      document: "Vérification de document",
      forensic: "Analyse médico-légale",
      id: "Vérification d'identité",
      liveness: "Vidéo en direct",
      pixel: "Analyse de pixels",
      voice: "Analyse vocale",
      faceMatch: "Correspondance faciale",
      title: "Sensity Enterprise",
      idDoc: "Lecteur de documents d'identité"
    },
    language: {
      english: "Anglais (English)",
      turkish: "Turc (Türkçe)",
      uzbek: "Ouzbek (O'zbekcha)",
      spanish: "Espagnol (Español)",
      korean: "Coréen (한국어)",
      russian: "Russe (Русский)",
      hindi: "Hindi (हिंदी)",
      german: "Allemand (Deutsch)",
      dutch: "Néerlandais (Nederlands)",
      chinese: "Chinois (中文)",
      arabic: "Arabe (العربية)",
      indonesian: "Indonésien (Bahasa)",
      vietnamese: "Vietnamien (Tiếng Việt)",
      japanese: "Japonais (日本語)",
      italian: "Italien (Italiano)",
      french: "Français"
    }
  }
}
