/* eslint-disable */
export default {
  common: {
    action: {
      copy: "Copy",
      submit: "Submit",
      register: "Register",
      login: "Log In",
      continue: "Continue",
      confirm: "Confirm",
      close: "Close",
      back: "Back",
      browse: "Browse files",
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
      create: "Create",
      invite: "Invite",
      analyze: "Analyze",
      select: "Select",
      selectAll: "Select All",
      clearFilters: "Clear Filters",
      exportReport: "Export Report",
      exportAsPDF: "Export as PDF",
      exportCSV: "Export CSV",
      analyzeVoice: "Analyze Voice",
      backToAll: "Back to All Reports",
      downloadVideoReport: "Download Video Report",
      generateVideoReport: "Generate Video Report",
      startDeepfake: "Start Deepfake Detection",
      changePassword: "Change Password",
      logout: "Logout",
      // team actions
      editMember: "Edit Team Member",
      editTeam: "Edit Team",
      newUser: "New User",
      createTeam: "Create New Team",
      showThumbnails: "Show Thumbnails",
      blurThumbnails: "Blur Thumbnails",
      showMore: "Show More",
      showLess: "Show Less",
    },
    loading: {
      // Combined from all loading states
      submitting: "Submitting",
      registering: "Registering",
      loggingIn: "Logging In",
      sending: "Sending",
      inProgress: "In Progress",
      changing: "Changing",
      processing: "Processing",
      exporting: "Exporting",
      generating: "Generating",
      loading: "Loading"
    },
    placeholder: {
      email: "{'example@mail.com'}",
      password: "*********",
      enterUrl: "Enter URL",
      enterEmail: "Enter email address",
      enterTeamName: "Enter team name",
      select: "Please select a value",
      searchByNameAndEmail: "Search by name or email address",
    },
    label: {
      // Combined all labels including media types
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      name: "Name",
      firstName: "First Name",
      lastName: "Last Name",
      phoneNumber: "Phone Number",
      industry: "Industry",
      companyWebsite: "Company Website",
      companyEmail: "Company Email",
      oldPassword: "Old password",
      newPassword: "New password",
      teamName: "Team Name",
      teamVisibility: "Team Visibility",
      role: "Role",
      emailAddress: "Email address",
      dragAndDrop: "Drag & drop media files here",
      browseFiles: "Browse files",
      or: "or",
      hide: "Hide",
      show: "Show",
      all: "All",
      selected: "{count} Selected",
      minutes: "min",
      allResults: "All Results",
      // Media types
      audio: "audio",
      video: "video",
      image: "image",
      pdf: "pdf",
      url: "URL",
      // Added from filters
      fileName: "File Name",
      reportId: "Report ID",
      searchType: "Search Type",
      searchBy: "Search By",
      status: "Status",
      user: "User",
      serviceType: "Service Type",
      date: "Date",
      // misc
      report: "Report",
      reportStatus: "Report Status",
      extractedDetails: "Extracted Details",
      settings: "Settings",
      language: "Language",
      // team
      noTeamMembers: "No team members found.",
      currentUser: "Current user",
      inviteSent: "Invite Sent",
      members: "{count} Members",
      timeRange: "Time Range",
      duration: "Duration",
      speaker: "Speaker"
    }
  },
  component: {
    list: {
      // Formerly results
      title: "Result List",
      noData: "No data found",
      noDataForEmail: "No results found for this email:",
      searchPlaceholder: "Search for email...",
      resultsFound: "Results Found: {count}",
      deleteModal: {
        title: "Delete Selected Items",
        message: "These reports will be deleted. Are you sure you want to proceed?",
      },
      table: {
        header: {
          media: "Media",
          info: "Info",
          result: "Result",
          riskScore: "Document Risk Score"
        },
      },
    },
    status: {
      // Moved from common.status
      inProgress: "In Progress",
      completed: "Completed",
      failed: "Failed",
      verified: "Verified",
      notVerified: "Not Verified",
      valid: "Valid",
      suspicious: "Suspicious",
      serverError: "Server Error",
      extractionFailed: "Extraction Failed",
      tooltip: {
        analyzing: "Analyzing Content...",
        urlExtractionFailed: "URL Content Extraction Failed",
        urlExtractionFailedDesc: "The content could not be extracted from the provided URL.",
        fakeDetected: "Fake Content or Spoofing Attempt Detected",
        fakeDetectedDesc: "This content has been flagged as fake or contains a spoofing attempt.",
        checkCompleted: "Check Completed Successfully",
        checkCompletedDesc: "No anomalies or spoofing attempts found.",
        serverErrorDesc: "Something went wrong. Please try again later."
      }
    },
    table: {
      // Including detection
      detection: {
        faceManCheck: "Face Manipulation Check",
        aiGenCheck: "AI Generated Content Check",
        forensicAnalysisCheck: "Forensic Analysis Check",
        voiceAnalysisCheck: "Voice Analysis Check",
        confidence: "Confidence",
        modelGenerator: "Model Generator",
        manipulationType: "Manipulation Type",
        noFace: "Face too small/No face",
        noVoice: "No Voice",
        details: "Details",
        alert: "Alert",
        creationDate: "Creation Date",
        modifyDate: "Modify Date",
        notAvailable: "N/A",
        description: {
          confidence: "High confidence indicates clear signs of AI manipulation, with at least 50% certainty",
          confidenceAI: "High confidence indicates clear signs of AI generation, with at least 50% certainty",
          modelGenerator: "Model Generator: when shown, the detector can also identify which model was used as generator",
          manipulationType: "Manipulation type: when showed, the detector can also identify if this is either a face swap, face reenactment, lipsync or face animation"
        }
      }
    },
    pagination: {
      itemPerPage: "Items per page:",
      range: "{from}-{to} of {total}",
    },
    modal: {
      upgradePlan: {
        title: "Upgrade Plan",
        message: "You can contact your account manager to upgrade your plan by clicking on the button below.",
        demoMessage: "By clicking on \"I want to upgrade my plan\" you will be contacted by your account manager who will explain the various options.\n Do you want to proceed?",
        licenceMessage: "Your license has expired. Please contact us to renew your license.",
        monthlyLimitReached: "Monthly Limit Reached",
        trialLimitReached: " You've reached your monthly trial submission limit of { limit }.",
        action: "I want to upgrade my plan",
      },
    }
  },
  feedback: {
    error: {
      label: "Error!",
      default: "An Error Occurred",
      server: "Server error occurred",
      generic: "Something went wrong. Please try again later.",
      unsupportedFileType: "Your subscription does not allow uploading this file type",
      terms: "Please accept the Terms and Conditions !",
      upload: "Failed to upload file",
    },
    success: {
      label: "Success!",
      default: "Operation successful",
      copy: "Successfully copied to clipboard",
      passwordChanged: "Password changed successfully",
      registration: "Your request has been sent for approval",
      contact: "Thanks for your request, we will contact you soon"
    },
    warning: {
      label: "Warning!",
      trialDisclaimer: "You are using trial version of the platform. Upgrade to the premium plan to use the platform without restrictions.",
      submissionDisclaimer: "Please do not submit any personal information. Sensity is not responsible for the content of your submission."
    },
    info: {
      agreeTo: "I agree to the following:",
      terms: "Terms and Conditions",
      privacy: "Privacy Policy",
    },
    validation: {
      required: "This field is required",
      url: "Please enter a valid URL",
      email: "Please enter a valid email",
      password: "Please enter a valid password",
      passwordMismatch: "Passwords do not match",
      numeric: "This field must be numeric",
      workEmail: "Please provide a work email",
      website: "Please enter a valid website",
      phoneNumber: "Please enter a valid phone number",
      allFieldsRequired: "Please fill all of the fields !",
      allFieldsCorrect: "Please fill all of the fields correctly !",
      termsRequired: "Please accept the Terms and Conditions !"
    },
    limitation: {
      maxFileSize: "Maximum file size",
      maxFile: "Maximum number of files to upload at same time",
      invalidFile: "Invalid file type",
      singleFileWarning: "You can drop only one file",
      maxFileWarning: "You can drop only {count} files at once",
      maxFileNameLength: "Maximum file name can be {count} characters",
      badExtension: "Bad file extension",
      maxAudioDuration: "Maximum audio duration {value} minutes",
      maxVideoDuration: "Maximum video duration {value} minutes",
      maxVideoResolution: "Maximum video resolution {height}x{width} or {width}x{height}",
      fileAlreadyUploaded: "File {filename} is already uploaded",
      unsupportedFileType: "Unsupported file type for your subscription"
    }
  },
  page: {
    home: {
      title: "Dashboard",
      subtitle: "See usage and submission details",
      stats: {
        monthlyLeftCredits: "Monthly Left Credits",
        monthlyUsage: "Monthly Usage",
        accountExpire: "Account will expire on",
        getStarted: "Get the most from Sensity",
        videoTitle: "Getting started on Sensity: features and outcomes"
      }
    },
    auth: {
      login: {
        title: "Log In",
        subtitle: "Don't have an account ?",
        signUpLink: "Sign up for free",
        forgotPassword: "Forgot your password ?"
      },
      register: {
        title: "Create an account",
        subtitle: "Already have an account ?",
        emailDisclaimer: "Your registration will be approved by a human operator. Free domains and temporary domains will not be considered legit by default",
        detailsPlaceholder: "Details \nPlease tell us more about your organization and what goals you want to achieve using Sensity. Without exhaustive information your account will be rejected"
      },
      passwordRecovery: {
        title: "Password Recovery",
        subtitle: "Back to",
        message: "Enter the email you're using for your account.",
        successMessage: "We've sent password reset instructions to your email address. If no email is received within ten minutes, check that the submitted address is correct."
      },
      confirmPassword: {
        title: "Reset Password"
      },
      changePassword: {
        title: "Change password"
      }
    },
    analytics: {
      title: "Analytics",
      subtitle: "Explore detailed insights of all the uploaded content for both platform and API submissions."
    },
    developer: {
      title: "Developer",
      subtitle: "Manage your API keys and usage",
      apiToken: "API Token",
    },
    team: {
      title: "Team Management",
      visibility: {
        visible: "Submissions are visible to all Team",
        notVisible: "Submissions are not visible to all Team",
        tooltip: {
          visible: "Your team submissions are visible to all team members.",
          notVisible: "Your submissions are private to each team members."
        },
        hint: "If you want to make your team submission visible to all team members, please tick this box. Do not tick it if you want each team member submission to be private."
      },
      addNewMemberModal: {
        title: "Add New Team Member",
        desc: "Please provide the email address of the new team member.",
        existingMember: "This user is already a member of the team."
      },
      upgradeModal: {
        title: "Team management is a premium feature",
        desc: "By clicking on \"I want to upgrade my plan\" you will be contacted by your account manager who will explain the various options.",
        confirm: "Do you want to proceed?",
        button: "I want to upgrade my plan"
      },
      deleteTeamMemberModal: {
        title: "Delete Team Member",
        desc: "These users will be deleted from the team. Are you sure you want to proceed?"
      },
      createTeamModal: {
        title: "Create New Team",
        desc: "Please provide a name for your team"
      },
      newUserTooltip: {
        analyst: "Please contact your team administrator to add more users.",
        admin: "Add new users to your team."
      },
    },
    deepfake: {
      title: "Deepfake Detection",
      subtitle: "Analyze suspicious media to detect audio and video deepfakes and AI-generated content.",
      button: "Start Deepfake Detection",
      info: {
        automation: "Do you want to automate submissions?",
        checkDocs: "Check our API docs",
        checkDocsOnPrem: "Check our on-premise user manual to view our API documentation.",
        contactSupport: "and contact us at"
      },
      report: {
        overall: {
          user: "User",
          fileName: "File Name",
          fileSize: "File Size (KB)",
          fileHash: "File Hash (SHA256)",
          submissionDate: "Submission Date",
          submissionTime: "Submission Time",
          fileResolution: "File Resolution",
          url: "URL",
          faces: "faces",
          face: "face",
          processingMessage: "Processing video report. This may take a few minutes.",
          selectFaceMessage: "Please select at least one face to generate video report.",
          enableVoiceAnalysisMessage: "To enable the voice analysis service, please contact support@sensity.ai",
          exportingReport: "Exporting Report",
          processingVideo: "Processing Video"
        },
        explanation: {
          fakeFacesDetected: "Fake Faces Detected",
          faceExplanation: "By selecting the faces, the relative bounding boxes will be displayed above media.",
          faceExplanationVideo: "and in exported video.",
          selected: "selected",
          viewOnVideo: "View on video",
          exportVideoLabel: "Export video with selected faces",
          faceResolution: "Face Resolution",
          low: "low",
          lowResolutionInfo: "Low face resolution may compromise the accuracy of the detector",
          viewAt: "View at",
          pixelBasedAssessment: "Pixel based assessment",
          pixelBasedInfo: "This section provides visual representations confirming the results above",
          expandImage: "Expand Image",
          expandVideoFrame: "Expand Video Frame",
          heatmapInfo: "The heatmap depicts the areas where the model detected a higher likelihood of synthesis at the pixel level",
          sectionDesc: "This section provides visual representations confirming the results above",
          pixelAndSegmentTitle: "Pixel and Segment based assessment",
          faceAndPixelTitle: "Face Alignment and Pixel based assessment",
          segmentBasedNotAvailable: "Segment based assessment is not available for this media",
          segmentBasedAssesment: "Segment Based Assessment",
          segmentBasedInfo: "The detector identifies the objects in the image most likely to be AI-generated",
          faceAlignmentAssesment: "Face Alignment Assessment",
          faceAlignmentInfo: "Images generated by this family of GANs can be recognized by the eyes and mouth fixed position",
          fakeSpeakersDetected: "Fake Speakers Detected",
        },
      },
      form: {
        title: "Upload Media",
        tab: {
          file: "File",
          url: "URL",
          urlNotAvailable: "URL upload is not available on-premise"
        },
      }
    },
    document: {
      title: "Document Verification",
      subtitle: "Extract key information and validate the authenticity of documents.",
      button: "Start Document Verification",
    },
    identity: {
      title: "Identity Verification",
      subtitle: "Performs identity verification and liveness detection.",
      button: "Start Identity Verification",
    }
  },
  name: {
    service: {
      deepfake: "Deepfake Detection",
      aiGen: "AI Generated Content",
      faceMan: "Face Manipulation",
      document: "Document Verification",
      forensic: "Forensic Analysis",
      id: "Identity Verification",
      liveness: "Video Liveness",
      pixel: "Pixel Analysis",
      voice: "Voice Analysis",
      faceMatch: "Face Matching",
      title: "Sensity Enterprise",
      idDoc: "ID Document Reader"
    },
    language: {
      english: "English",
      turkish: "Turkish (Türkçe)",
      uzbek: "Uzbek (O'zbekcha)",
      spanish: "Spanish (Español)",
      korean: "Korean (한국어)",
      russian: "Russian (Русский)",
      hindi: "Hindi (हिंदी)",
      german: "German (Deutsch)",
      dutch: "Dutch (Nederlands)",
      chinese: "Chinese (中文)",
      arabic: "Arabic (العربية)",
      indonesian: "Indonesian (Bahasa)",
      vietnamese: "Vietnamese (Tiếng Việt)",
      japanese: "Japanese (日本語)",
      italian: "Italian (Italiano)",
      french: "French (Français)"
    }
  },
}
