/* eslint-disable */
export default {
  common: {
    action: {
      submit: "إرسال",
      register: "تسجيل",
      login: "تسجيل الدخول",
      continue: "متابعة",
      confirm: "تأكيد",
      close: "إغلاق",
      back: "رجوع",
      browse: "تصفح الملفات",
      save: "حفظ",
      cancel: "إلغاء",
      delete: "حذف",
      create: "إنشاء",
      invite: "دعوة",
      analyze: "تحليل",
      select: "اختيار",
      selectAll: "اختيار الكل",
      clearFilters: "مسح التصفية",
      exportReport: "تصدير التقرير",
      exportAsPDF: "تصدير كملف PDF",
      exportCSV: "تصدير CSV",
      analyzeVoice: "تحليل الصوت",
      backToAll: "العودة إلى جميع التقارير",
      downloadVideoReport: "تحميل تقرير الفيديو",
      generateVideoReport: "إنشاء تقرير الفيديو",
      startDeepfake: "بدء كشف التزييف العميق",
      changePassword: "تغيير كلمة المرور",
      logout: "تسجيل الخروج",
      editMember: "تعديل عضو الفريق",
      editTeam: "تعديل الفريق",
      newUser: "مستخدم جديد",
      createTeam: "إنشاء فريق جديد",
      copy: "نسخ",
      showThumbnails: "إظهار المصغرات",
      blurThumbnails: "تمويه المصغرات",
      showMore: "عرض المزيد",
      showLess: "عرض أقل",
    },
    loading: {
      submitting: "جارٍ الإرسال",
      registering: "جارٍ التسجيل",
      loggingIn: "جارٍ تسجيل الدخول",
      sending: "جارٍ الإرسال",
      inProgress: "قيد التنفيذ",
      changing: "جارٍ التغيير",
      processing: "جارٍ المعالجة",
      exporting: "جارٍ التصدير",
      generating: "جارٍ الإنشاء",
      loading: "جارٍ التحميل"
    },
    placeholder: {
      email: "{'example@mail.com'}",
      password: "*********",
      enterUrl: "أدخل الرابط",
      enterEmail: "أدخل البريد الإلكتروني",
      enterTeamName: "أدخل اسم الفريق",
      select: "الرجاء اختيار قيمة",
      searchByNameAndEmail: "البحث بالاسم أو البريد الإلكتروني",
    },
    label: {
      email: "البريد الإلكتروني",
      password: "كلمة المرور",
      confirmPassword: "تأكيد كلمة المرور",
      name: "الاسم",
      firstName: "الاسم الأول",
      lastName: "اسم العائلة",
      phoneNumber: "رقم الهاتف",
      industry: "الصناعة",
      companyWebsite: "موقع الشركة",
      companyEmail: "البريد الإلكتروني للشركة",
      oldPassword: "كلمة المرور القديمة",
      newPassword: "كلمة المرور الجديدة",
      teamName: "اسم الفريق",
      teamVisibility: "رؤية الفريق",
      role: "الدور",
      emailAddress: "عنوان البريد الإلكتروني",
      dragAndDrop: "اسحب وأفلت ملفات الوسائط هنا",
      browseFiles: "تصفح الملفات",
      or: "أو",
      hide: "إخفاء",
      show: "إظهار",
      all: "الكل",
      selected: "{count} محدد",
      minutes: "دقيقة",
      allResults: "كل النتائج",
      audio: "صوت",
      video: "فيديو",
      image: "صورة",
      pdf: "PDF",
      url: "الرابط",
      fileName: "اسم الملف",
      reportId: "معرف التقرير",
      searchType: "نوع البحث",
      searchBy: "البحث حسب",
      status: "الحالة",
      user: "المستخدم",
      serviceType: "نوع الخدمة",
      date: "التاريخ",
      report: "التقرير",
      reportStatus: "حالة التقرير",
      extractedDetails: "التفاصيل المستخرجة",
      settings: "الإعدادات",
      language: "اللغة",
      noTeamMembers: "لم يتم العثور على أعضاء في الفريق.",
      currentUser: "المستخدم الحالي",
      inviteSent: "تم إرسال الدعوة",
      members: "{count} أعضاء",
      timeRange: "النطاق الزمني",
      duration: "المدة",
      speaker: "المتحدث"
    }
  },
  component: {
    list: {
      title: "قائمة النتائج",
      noData: "لم يتم العثور على بيانات",
      noDataForEmail: "لم يتم العثور على نتائج لهذا البريد الإلكتروني:",
      searchPlaceholder: "البحث عن بريد إلكتروني...",
      resultsFound: "النتائج التي تم العثور عليها: {count}",
      deleteModal: {
        title: "حذف العناصر المحددة",
        message: "سيتم حذف هذه التقارير. هل أنت متأكد من أنك تريد المتابعة؟",
      },
      table: {
        header: {
          media: "الوسائط",
          info: "المعلومات",
          result: "النتيجة",
          riskScore: "درجة مخاطر المستند"
        },
      },
    },
    status: {
      inProgress: "قيد التنفيذ",
      completed: "مكتمل",
      failed: "فشل",
      verified: "تم التحقق",
      notVerified: "لم يتم التحقق",
      valid: "صالح",
      suspicious: "مشبوه",
      serverError: "خطأ في الخادم",
      extractionFailed: "فشل الاستخراج",
      tooltip: {
        analyzing: "جارٍ تحليل المحتوى...",
        urlExtractionFailed: "فشل استخراج محتوى الرابط",
        urlExtractionFailedDesc: "تعذر استخراج المحتوى من الرابط المقدم.",
        fakeDetected: "تم اكتشاف محتوى مزيف أو محاولة انتحال",
        fakeDetectedDesc: "تم تمييز هذا المحتوى كمزيف أو يحتوي على محاولة انتحال.",
        checkCompleted: "اكتمل الفحص بنجاح",
        checkCompletedDesc: "لم يتم العثور على أي شذوذ أو محاولات انتحال.",
        serverErrorDesc: "حدث خطأ. يرجى المحاولة مرة أخرى لاحقاً."
      }
    },
    table: {
      detection: {
        faceManCheck: "فحص التلاعب بالوجه",
        aiGenCheck: "فحص المحتوى المنشأ بواسطة الذكاء الاصطناعي",
        forensicAnalysisCheck: "فحص التحليل الجنائي",
        voiceAnalysisCheck: "فحص تحليل الصوت",
        confidence: "درجة الثقة",
        modelGenerator: "نموذج التوليد",
        manipulationType: "نوع التلاعب",
        noFace: "الوجه صغير جداً/لا يوجد وجه",
        noVoice: "لا يوجد صوت",
        details: "التفاصيل",
        alert: "تنبيه",
        creationDate: "تاريخ الإنشاء",
        modifyDate: "تاريخ التعديل",
        notAvailable: "غير متوفر",
        description: {
          confidence: "درجة الثقة العالية تشير إلى علامات واضحة على التلاعب بالذكاء الاصطناعي، بيقين لا يقل عن 50%",
          confidenceAI: "درجة الثقة العالية تشير إلى علامات واضحة على التوليد بالذكاء الاصطناعي، بيقين لا يقل عن 50%",
          modelGenerator: "نموذج التوليد: عند عرضه، يمكن للكاشف أيضاً تحديد النموذج الذي تم استخدامه كمولد",
          manipulationType: "نوع التلاعب: عند عرضه، يمكن للكاشف أيضاً تحديد ما إذا كان هذا تبديل وجه، أو إعادة تحريك وجه، أو مزامنة شفاه، أو تحريك وجه"
        }
      }
    },
    pagination: {
      itemPerPage: "العناصر في الصفحة:",
      range: "{from}-{to} من {total}",
    },
    modal: {
      upgradePlan: {
        title: "ترقية الخطة",
        message: "يمكنك التواصل مع مدير حسابك لترقية خطتك بالنقر على الزر أدناه.",
        demoMessage: "بالنقر على \"أريد ترقية خطتي\" سيتصل بك مدير حسابك الذي سيشرح لك الخيارات المختلفة.\nهل تريد المتابعة؟",
        licenceMessage: "انتهت صلاحية ترخيصك. يرجى الاتصال بنا لتجديد ترخيصك.",
        monthlyLimitReached: "تم الوصول إلى الحد الشهري",
        trialLimitReached: "لقد وصلت إلى حد الإرسال التجريبي الشهري البالغ {limit}.",
        action: "أريد ترقية خطتي",
      },
    }
  },
  feedback: {
    error: {
      label: "خطأ!",
      default: "حدث خطأ",
      server: "حدث خطأ في الخادم",
      generic: "حدث خطأ ما. يرجى المحاولة مرة أخرى لاحقاً.",
      unsupportedFileType: "اشتراكك لا يسمح برفع هذا النوع من الملفات",
      terms: "يرجى قبول الشروط والأحكام!",
      upload: "فشل في رفع الملف",
    },
    success: {
      label: "نجاح!",
      default: "تمت العملية بنجاح",
      passwordChanged: "تم تغيير كلمة المرور بنجاح",
      registration: "تم إرسال طلبك للموافقة",
      contact: "شكراً لطلبك، سنتواصل معك قريباً",
      copy: "تم النسخ إلى الحافظة",
    },
    warning: {
      label: "تحذير!",
      trialDisclaimer: "أنت تستخدم النسخة التجريبية من المنصة. قم بالترقية إلى الخطة المتميزة لاستخدام المنصة بدون قيود.",
      submissionDisclaimer: "يرجى عدم إرسال معلومات شخصية. Sensity غير مسؤولة عن محتوى إرسالك."
    },
    info: {
      agreeTo: "أوافق على ما يلي:",
      terms: "الشروط والأحكام",
      privacy: "سياسة الخصوصية",
    },
    validation: {
      required: "هذا الحقل مطلوب",
      url: "يرجى إدخال رابط صالح",
      email: "يرجى إدخال بريد إلكتروني صالح",
      password: "يرجى إدخال كلمة مرور صالحة",
      passwordMismatch: "كلمات المرور غير متطابقة",
      numeric: "يجب أن يكون هذا الحقل رقمياً",
      workEmail: "يرجى تقديم بريد إلكتروني للعمل",
      website: "يرجى إدخال موقع إلكتروني صالح",
      phoneNumber: "يرجى إدخال رقم هاتف صالح",
      allFieldsRequired: "يرجى ملء جميع الحقول!",
      allFieldsCorrect: "يرجى ملء جميع الحقول بشكل صحيح!",
      termsRequired: "يرجى قبول الشروط والأحكام!"
    },
    limitation: {
      maxFileSize: "الحد الأقصى لحجم الملف",
      maxFile: "الحد الأقصى لعدد الملفات التي يمكن رفعها في نفس الوقت",
      invalidFile: "نوع ملف غير صالح",
      singleFileWarning: "يمكنك إسقاط ملف واحد فقط",
      maxFileWarning: "يمكنك إسقاط {count} ملفات فقط في المرة الواحدة",
      maxFileNameLength: "يمكن أن يكون اسم الملف {count} حرف كحد أقصى",
      badExtension: "امتداد ملف غير صالح",
      maxAudioDuration: "الحد الأقصى لمدة الصوت {value} دقيقة",
      maxVideoDuration: "الحد الأقصى لمدة الفيديو {value} دقيقة",
      maxVideoResolution: "الحد الأقصى لدقة الفيديو {height}x{width} أو {width}x{height}",
      fileAlreadyUploaded: "الملف {filename} تم رفعه بالفعل",
      unsupportedFileType: "نوع الملف غير مدعوم لاشتراكك"
    }
  },
  page: {
    home: {
      title: "لوحة التحكم",
      subtitle: "عرض تفاصيل الاستخدام والإرسال",
      stats: {
        monthlyLeftCredits: "الرصيد الشهري المتبقي",
        monthlyUsage: "الاستخدام الشهري",
        accountExpire: "ستنتهي صلاحية الحساب في",
        getStarted: "احصل على أقصى استفادة من Sensity",
        videoTitle: "بدء استخدام Sensity: الميزات والنتائج"
      }
    },
    auth: {
      login: {
        title: "تسجيل الدخول",
        subtitle: "ليس لديك حساب؟",
        signUpLink: "سجل مجاناً",
        forgotPassword: "نسيت كلمة المرور؟"
      },
      register: {
        title: "إنشاء حساب",
        subtitle: "لديك حساب بالفعل؟",
        emailDisclaimer: "سيتم الموافقة على تسجيلك من قبل مشغل بشري. لن يتم اعتبار النطاقات المجانية والمؤقتة صالحة بشكل افتراضي",
        detailsPlaceholder: "التفاصيل \nيرجى إخبارنا المزيد عن مؤسستك والأهداف التي تريد تحقيقها باستخدام Sensity. بدون معلومات شاملة سيتم رفض حسابك"
      },
      passwordRecovery: {
        title: "استعادة كلمة المرور",
        subtitle: "العودة إلى",
        message: "أدخل البريد الإلكتروني الذي تستخدمه لحسابك.",
        successMessage: "لقد أرسلنا تعليمات إعادة تعيين كلمة المرور إلى بريدك الإلكتروني. إذا لم تتلق رسالة خلال عشر دقائق، تحقق من صحة العنوان المقدم."
      },
      confirmPassword: {
        title: "إعادة تعيين كلمة المرور"
      },
      changePassword: {
        title: "تغيير كلمة المرور"
      }
    },
    analytics: {
      title: "التحليلات",
      subtitle: "استكشف رؤى مفصلة لجميع المحتوى المرفوع للإرسال عبر المنصة وواجهة برمجة التطبيقات."
    },
    team: {
      title: "إدارة الفريق",
      visibility: {
        visible: "الإرسالات مرئية لجميع الفريق",
        notVisible: "الإرسالات غير مرئية لجميع الفريق",
        tooltip: {
          visible: "إرسالات فريقك مرئية لجميع أعضاء الفريق.",
          notVisible: "إرسالاتك خاصة لكل عضو في الفريق."
        },
        hint: "إذا كنت تريد جعل إرسالات فريقك مرئية لجميع أعضاء الفريق، يرجى تحديد هذا المربع. لا تحدده إذا كنت تريد إبقاء إرسالات كل عضو في الفريق خاصة."
      },
      addNewMemberModal: {
        title: "إضافة عضو جديد للفريق",
        desc: "يرجى تقديم البريد الإلكتروني لعضو الفريق الجديد.",
        existingMember: "هذا المستخدم عضو بالفعل في الفريق."
      },
      upgradeModal: {
        title: "إدارة الفريق ميزة متميزة",
        desc: "بالنقر على \"أريد ترقية خطتي\" سيتصل بك مدير حسابك الذي سيشرح لك الخيارات المختلفة.",
        confirm: "هل تريد المتابعة؟",
        button: "أريد ترقية خطتي"
      },
      deleteTeamMemberModal: {
        title: "حذف عضو الفريق",
        desc: "سيتم حذف هؤلاء المستخدمين من الفريق. هل أنت متأكد من أنك تريد المتابعة؟"
      },
      createTeamModal: {
        title: "إنشاء فريق جديد",
        desc: "يرجى تقديم اسم لفريقك"
      },
      newUserTooltip: {
        analyst: "يرجى الاتصال بمسؤول الفريق لإضافة المزيد من المستخدمين.",
        admin: "إضافة مستخدمين جدد إلى فريقك."
      },
    },
    deepfake: {
      title: "كشف التزييف العميق",
      subtitle: "تحليل الوسائط المشبوهة للكشف عن التزييف العميق للصوت والفيديو والمحتوى المنشأ بواسطة الذكاء الاصطناعي.",
      button: "بدء كشف التزييف العميق",
      info: {
        automation: "هل تريد أتمتة الإرسال؟",
        checkDocs: "راجع وثائق واجهة برمجة التطبيقات لدينا",
        checkDocsOnPrem: "راجع دليل المستخدم الداخلي لدينا لعرض وثائق واجهة برمجة التطبيقات.",
        contactSupport: "واتصل بنا على"
      },
      report: {
        overall: {
          user: "المستخدم",
          fileName: "اسم الملف",
          fileSize: "حجم الملف (كيلوبايت)",
          fileHash: "تجزئة الملف (SHA256)",
          submissionDate: "تاريخ الإرسال",
          submissionTime: "وقت الإرسال",
          fileResolution: "دقة الملف",
          url: "الرابط",
          faces: "وجوه",
          face: "وجه",
          processingMessage: "معالجة تقرير الفيديو. قد يستغرق هذا بضع دقائق.",
          selectFaceMessage: "يرجى تحديد وجه واحد على الأقل لإنشاء تقرير الفيديو.",
          enableVoiceAnalysisMessage: "لتفعيل خدمة تحليل الصوت، يرجى الاتصال بـ support@sensity.ai",
          exportingReport: "تصدير التقرير",
          processingVideo: "معالجة الفيديو"
        },
        explanation: {
          fakeFacesDetected: "تم اكتشاف وجوه مزيفة",
          faceExplanation: "عند تحديد الوجوه، سيتم عرض المربعات المحيطة المتعلقة فوق الوسائط.",
          faceExplanationVideo: "وفي الفيديو المصدَّر.",
          selected: "محدد",
          viewOnVideo: "عرض على الفيديو",
          exportVideoLabel: "تصدير الفيديو مع الوجوه المحددة",
          faceResolution: "دقة الوجه",
          low: "منخفضة",
          lowResolutionInfo: "دقة الوجه المنخفضة قد تؤثر على دقة الكاشف",
          viewAt: "عرض في",
          pixelBasedAssessment: "تقييم على مستوى البكسل",
          pixelBasedInfo: "يوفر هذا القسم تمثيلات مرئية تؤكد النتائج أعلاه",
          expandImage: "توسيع الصورة",
          expandVideoFrame: "توسيع إطار الفيديو",
          heatmapInfo: "تظهر خريطة الحرارة المناطق التي اكتشف فيها النموذج احتمالية أعلى للتوليف على مستوى البكسل",
          sectionDesc: "يوفر هذا القسم تمثيلات مرئية تؤكد النتائج أعلاه",
          pixelAndSegmentTitle: "تقييم على مستوى البكسل والقطاع",
          faceAndPixelTitle: "محاذاة الوجه وتقييم على مستوى البكسل",
          segmentBasedNotAvailable: "التقييم على مستوى القطاع غير متوفر لهذه الوسائط",
          segmentBasedAssesment: "تقييم على مستوى القطاع",
          segmentBasedInfo: "يحدد الكاشف الكائنات في الصورة الأكثر احتمالاً لتكون منشأة بواسطة الذكاء الاصطناعي",
          faceAlignmentAssesment: "تقييم محاذاة الوجه",
          faceAlignmentInfo: "يمكن التعرف على الصور المنشأة بواسطة عائلة GAN هذه من خلال الموضع الثابت للعيون والفم",
          fakeSpeakersDetected: "تم الكشف عن متحدثين مزيفين"
        },
      },
      form: {
        title: "رفع الوسائط",
        tab: {
          file: "ملف",
          url: "رابط",
          urlNotAvailable: "الرفع عبر الرابط غير متوفر في النشر الداخلي"
        },
      }
    },
    document: {
      title: "التحقق من المستند",
      subtitle: "استخراج المعلومات الرئيسية والتحقق من صحة المستندات.",
      button: "بدء التحقق من المستند",
    },
    identity: {
      title: "التحقق من الهوية",
      subtitle: "يقوم بإجراء التحقق من الهوية واكتشاف الحيوية.",
      button: "بدء التحقق من الهوية",
    },
    developer: {
      title: "المطور",
      subtitle: "إدارة مفاتيح API والاستخدام",
      apiToken: "رمز API"
    }
  },
  name: {
    service: {
      deepfake: "كشف التزييف العميق",
      aiGen: "محتوى منشأ بالذكاء الاصطناعي",
      faceMan: "التلاعب بالوجه",
      document: "التحقق من المستند",
      forensic: "التحليل الجنائي",
      id: "التحقق من الهوية",
      liveness: "فيديو حي",
      pixel: "تحليل البكسل",
      voice: "تحليل الصوت",
      faceMatch: "مطابقة الوجه",
      title: "Sensity Enterprise",
      idDoc: "قارئ وثائق الهوية"
    },
    language: {
      english: "الإنجليزية (English)",
      turkish: "التركية (Türkçe)",
      uzbek: "الأوزبكية (O'zbekcha)",
      spanish: "الإسبانية (Español)",
      korean: "الكورية (한국어)",
      russian: "الروسية (Русский)",
      hindi: "الهندية (हिंदी)",
      german: "الألمانية (Deutsch)",
      dutch: "الهولندية (Nederlands)",
      chinese: "الصينية (中文)",
      arabic: "العربية",
      indonesian: "الإندونيسية (Bahasa)",
      vietnamese: "الفيتنامية (Tiếng Việt)",
      japanese: "اليابانية (日本語)",
      italian: "الإيطالية (Italiano)",
      french: "الفرنسية (Français)"
    }
  }
}
