import * as Sentry from "@sentry/vue";

const initSentry = (app, router) => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: [
      "localhost",
      /sensity-testing-.*\.surge\.sh/, // Match domains like x-testing-my-pr-name.surge.sh
      /^https:\/\/platform\.sensity\.ai/,
      /^https:\/\/platform\.staging\.sensity\.ai/
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export default initSentry;
