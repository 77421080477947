/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Enviar",
      register: "Registrarse",
      login: "Iniciar Sesión",
      continue: "Continuar",
      confirm: "Confirmar",
      close: "Cerrar",
      back: "Atrás",
      browse: "Explorar archivos",
      save: "Guardar",
      cancel: "Cancelar",
      delete: "Eliminar",
      create: "Crear",
      invite: "Invitar",
      analyze: "Analizar",
      selectAll: "Seleccionar Todo",
      clearFilters: "Limpiar Filtros",
      exportReport: "Exportar Informe",
      exportAsPDF: "Exportar como PDF",
      exportCSV: "Exportar CSV",
      analyzeVoice: "Analizar Voz",
      backToAll: "Volver a Todos los Informes",
      downloadVideoReport: "Descargar Informe de Video",
      generateVideoReport: "Generar Informe de Video",
      startDeepfake: "Iniciar Detección Deepfake",
      changePassword: "Cambiar Contraseña",
      logout: "Cerrar Sesión",
      editMember: "Editar Miembro del Equipo",
      editTeam: "Editar Equipo",
      newUser: "Nuevo Usuario",
      createTeam: "Crear Nuevo Equipo",
      select: "Seleccionar",
      copy: "Copiar",
      showThumbnails: "Mostrar Miniaturas",
      blurThumbnails: "Desenfocar Miniaturas",
      showMore: "Mostrar Más",
      showLess: "Mostrar Menos"
    },
    loading: {
      submitting: "Enviando",
      registering: "Registrando",
      loggingIn: "Iniciando Sesión",
      sending: "Enviando",
      inProgress: "En Progreso",
      changing: "Cambiando",
      processing: "Procesando",
      exporting: "Exportando",
      generating: "Generando",
      loading: "Cargando"
    },
    placeholder: {
      email: "{'ejemplo@correo.com'}",
      password: "*********",
      enterUrl: "Ingrese URL",
      enterEmail: "Ingrese dirección de correo",
      enterTeamName: "Ingrese nombre del equipo",
      select: "Por favor seleccione un valor",
      searchByNameAndEmail: "Buscar por nombre o correo electrónico"
    },
    label: {
      email: "Correo electrónico",
      password: "Contraseña",
      confirmPassword: "Confirmar Contraseña",
      name: "Nombre",
      firstName: "Nombre",
      lastName: "Apellido",
      phoneNumber: "Número de Teléfono",
      industry: "Industria",
      companyWebsite: "Sitio Web de la Empresa",
      companyEmail: "Correo de la Empresa",
      oldPassword: "Contraseña anterior",
      newPassword: "Nueva contraseña",
      teamName: "Nombre del Equipo",
      teamVisibility: "Visibilidad del Equipo",
      role: "Rol",
      emailAddress: "Dirección de correo",
      dragAndDrop: "Arrastra y suelta archivos multimedia aquí",
      browseFiles: "Explorar archivos",
      or: "o",
      hide: "Ocultar",
      show: "Mostrar",
      all: "Todo",
      selected: "{count} Seleccionados",
      minutes: "min",
      allResults: "Todos los resultados",
      audio: "audio",
      video: "video",
      image: "imagen",
      pdf: "pdf",
      url: "URL",
      fileName: "Nombre del Archivo",
      reportId: "ID del Informe",
      searchType: "Tipo de Búsqueda",
      searchBy: "Buscar por",
      status: "Estado",
      user: "Usuario",
      serviceType: "Tipo de Servicio",
      date: "Fecha",
      report: "Informe",
      reportStatus: "Estado del Informe",
      extractedDetails: "Detalles Extraídos",
      settings: "Configuración",
      language: "Idioma",
      noTeamMembers: "No se encontraron miembros del equipo.",
      currentUser: "Usuario actual",
      inviteSent: "Invitación enviada",
      members: "{count} Miembros",
      timeRange: "Rango de Tiempo",
      duration: "Duración",
      speaker: "Hablante"
    }
  },
  component: {
    list: {
      title: "Lista de Resultados",
      noData: "No se encontraron datos",
      noDataForEmail: "No se encontraron resultados para este correo:",
      searchPlaceholder: "Buscar por correo...",
      resultsFound: "Resultados Encontrados: {count}",
      deleteModal: {
        title: "Eliminar Elementos Seleccionados",
        message: "Estos informes serán eliminados. ¿Está seguro de que desea continuar?"
      },
      table: {
        header: {
          media: "Multimedia",
          info: "Información",
          result: "Resultado",
          riskScore: "Puntuación de Riesgo del Documento"
        }
      }
    },
    status: {
      inProgress: "En Progreso",
      completed: "Completado",
      failed: "Fallido",
      verified: "Verificado",
      notVerified: "No Verificado",
      valid: "Válido",
      suspicious: "Sospechoso",
      serverError: "Error del Servidor",
      extractionFailed: "Extracción Fallida",
      tooltip: {
        analyzing: "Analizando Contenido...",
        urlExtractionFailed: "Falló la Extracción del Contenido URL",
        urlExtractionFailedDesc: "No se pudo extraer el contenido de la URL proporcionada.",
        fakeDetected: "Contenido Falso o Intento de Suplantación Detectado",
        fakeDetectedDesc: "Este contenido ha sido marcado como falso o contiene un intento de suplantación.",
        checkCompleted: "Verificación Completada Exitosamente",
        checkCompletedDesc: "No se encontraron anomalías ni intentos de suplantación.",
        serverErrorDesc: "Algo salió mal. Por favor intente nuevamente."
      }
    },
    table: {
      detection: {
        faceManCheck: "Verificación de Manipulación Facial",
        aiGenCheck: "Verificación de Contenido Generado por IA",
        forensicAnalysisCheck: "Verificación de Análisis Forense",
        voiceAnalysisCheck: "Verificación de Análisis de Voz",
        confidence: "Confianza",
        modelGenerator: "Modelo Generador",
        manipulationType: "Tipo de Manipulación",
        noFace: "Rostro muy pequeño/Sin rostro",
        noVoice: "Sin Voz",
        details: "Detalles",
        alert: "Alerta",
        creationDate: "Fecha de Creación",
        modifyDate: "Fecha de Modificación",
        notAvailable: "N/D",
        description: {
          confidence: "Alta confianza indica señales claras de manipulación por IA, con al menos 50% de certeza",
          confidenceAI: "Alta confianza indica señales claras de generación por IA, con al menos 50% de certeza",
          modelGenerator: "Modelo Generador: cuando se muestra, el detector también puede identificar qué modelo se usó como generador",
          manipulationType: "Tipo de manipulación: cuando se muestra, el detector también puede identificar si es un intercambio de rostro, reanimación facial, sincronización labial o animación facial"
        }
      }
    },
    pagination: {
      itemPerPage: "Elementos por página:",
      range: "{from}-{to} de {total}",
    },
    modal: {
      upgradePlan: {
        title: "Actualizar Plan",
        message: "Puede contactar a su gerente de cuenta para actualizar su plan haciendo clic en el botón de abajo.",
        demoMessage: "Al hacer clic en \"Quiero actualizar mi plan\", su gerente de cuenta se pondrá en contacto con usted para explicarle las diversas opciones.\n¿Desea continuar?",
        licenceMessage: "Su licencia ha expirado. Por favor, contáctenos para renovar su licencia.",
        monthlyLimitReached: "Límite Mensual Alcanzado",
        trialLimitReached: "Ha alcanzado su límite mensual de prueba de { limit }.",
        action: "Quiero actualizar mi plan",
      },
    }
  },
  feedback: {
    error: {
      label: "¡Error!",
      default: "Se produjo un error",
      server: "Se produjo un error del servidor",
      generic: "Algo salió mal. Por favor, inténtelo de nuevo más tarde.",
      unsupportedFileType: "Su suscripción no permite cargar este tipo de archivo",
      terms: "¡Por favor, acepte los Términos y Condiciones!",
      upload: "Error al subir el archivo"
    },
    success: {
      label: "¡Éxito!",
      default: "Operación exitosa",
      passwordChanged: "Contraseña cambiada exitosamente",
      registration: "Su solicitud ha sido enviada para aprobación",
      contact: "Gracias por su solicitud, nos pondremos en contacto pronto",
      copy: "Copiado al portapapeles"
    },
    warning: {
      label: "¡Advertencia!",
      trialDisclaimer: "Está usando la versión de prueba de la plataforma. Actualice al plan premium para usar la plataforma sin restricciones.",
      submissionDisclaimer: "Por favor no envíe información personal. Sensity no es responsable del contenido de su envío."
    },
    info: {
      agreeTo: "Acepto lo siguiente:",
      terms: "Términos y Condiciones",
      privacy: "Política de Privacidad"
    },
    validation: {
      required: "Este campo es obligatorio",
      url: "Por favor ingrese una URL válida",
      email: "Por favor ingrese un correo válido",
      password: "Por favor ingrese una contraseña válida",
      passwordMismatch: "Las contraseñas no coinciden",
      numeric: "Este campo debe ser numérico",
      workEmail: "Por favor proporcione un correo de trabajo",
      website: "Por favor ingrese un sitio web válido",
      phoneNumber: "Por favor ingrese un número de teléfono válido",
      allFieldsRequired: "Por favor complete todos los campos",
      allFieldsCorrect: "Por favor complete todos los campos correctamente",
      termsRequired: "Por favor acepte los Términos y Condiciones"
    },
    limitation: {
      maxFileSize: "Tamaño máximo de archivo",
      maxFile: "Número máximo de archivos para cargar al mismo tiempo",
      invalidFile: "Tipo de archivo inválido",
      singleFileWarning: "Solo puede soltar un archivo",
      maxFileWarning: "Solo puede soltar {count} archivos a la vez",
      maxFileNameLength: "El nombre del archivo puede tener máximo {count} caracteres",
      badExtension: "Extensión de archivo incorrecta",
      maxAudioDuration: "Duración máxima de audio {value} minutos",
      maxVideoDuration: "Duración máxima de video {value} minutos",
      maxVideoResolution: "Resolución máxima de video {height}x{width} o {width}x{height}",
      fileAlreadyUploaded: "El archivo {filename} ya está cargado",
      unsupportedFileType: "Tipo de archivo no soportado para su suscripción"
    }
  },
  page: {
    home: {
      title: "Panel de Control",
      subtitle: "Ver detalles de uso y envíos",
      stats: {
        monthlyLeftCredits: "Créditos Mensuales Restantes",
        monthlyUsage: "Uso Mensual",
        accountExpire: "La cuenta vencerá el",
        getStarted: "Aproveche al máximo Sensity",
        videoTitle: "Comenzando en Sensity: características y resultados"
      }
    },
    auth: {
      login: {
        title: "Iniciar Sesión",
        subtitle: "¿No tiene una cuenta?",
        signUpLink: "Regístrese gratis",
        forgotPassword: "¿Olvidó su contraseña?"
      },
      register: {
        title: "Crear una cuenta",
        subtitle: "¿Ya tiene una cuenta?",
        emailDisclaimer: "Su registro será aprobado por un operador humano. Los dominios gratuitos y temporales no se considerarán legítimos por defecto",
        detailsPlaceholder: "Detalles\nPor favor cuéntenos más sobre su organización y qué objetivos desea alcanzar usando Sensity. Sin información exhaustiva su cuenta será rechazada"
      },
      passwordRecovery: {
        title: "Recuperación de Contraseña",
        subtitle: "Volver a",
        message: "Ingrese el correo que usa para su cuenta.",
        successMessage: "Hemos enviado instrucciones para restablecer la contraseña a su dirección de correo. Si no recibe el correo dentro de diez minutos, verifique que la dirección proporcionada sea correcta."
      },
      confirmPassword: {
        title: "Restablecer Contraseña"
      },
      changePassword: {
        title: "Cambiar contraseña"
      }
    },
    analytics: {
      title: "Analítica",
      subtitle: "Explore información detallada de todo el contenido cargado tanto en la plataforma como en envíos API."
    },
    team: {
      title: "Gestión de Equipo",
      visibility: {
        visible: "Los envíos son visibles para todo el Equipo",
        notVisible: "Los envíos no son visibles para todo el Equipo",
        tooltip: {
          visible: "Los envíos de su equipo son visibles para todos los miembros del equipo.",
          notVisible: "Sus envíos son privados para cada miembro del equipo."
        },
        hint: "Si desea hacer que los envíos del equipo sean visibles para todos los miembros del equipo, marque esta casilla. No la marque si desea que los envíos de cada miembro del equipo sean privados."
      },
      addNewMemberModal: {
        title: "Agregar Nuevo Miembro al Equipo",
        desc: "Por favor proporcione la dirección de correo del nuevo miembro del equipo.",
        existingMember: "Este usuario ya es miembro del equipo."
      },
      upgradeModal: {
        title: "La gestión de equipo es una característica premium",
        desc: "Al hacer clic en \"Quiero actualizar mi plan\" será contactado por su gestor de cuenta quien le explicará las distintas opciones.",
        confirm: "¿Desea continuar?",
        button: "Quiero actualizar mi plan"
      },
      deleteTeamMemberModal: {
        title: "Eliminar Miembro del Equipo",
        desc: "Estos usuarios serán eliminados del equipo. ¿Está seguro de que desea continuar?"
      },
      createTeamModal: {
        title: "Crear Nuevo Equipo",
        desc: "Por favor proporcione un nombre para su equipo"
      },
      newUserTooltip: {
        analyst: "Por favor contacte a su administrador de equipo para agregar más usuarios.",
        admin: "Agregue nuevos usuarios a su equipo."
      }
    },
    deepfake: {
      title: "Detección de Deepfake",
      subtitle: "Analice medios sospechosos para detectar deepfakes de audio y video y contenido generado por IA.",
      button: "Iniciar Detección Deepfake",
      info: {
        automation: "¿Desea automatizar los envíos?",
        checkDocs: "Consulte nuestra documentación API",
        checkDocsOnPrem: "Consulte nuestro manual local para ver nuestra documentación API.",
        contactSupport: "y contáctenos en"
      },
      report: {
        overall: {
          user: "Usuario",
          fileName: "Nombre del Archivo",
          fileSize: "Tamaño del Archivo (KB)",
          fileHash: "Hash del Archivo (SHA256)",
          submissionDate: "Fecha de Envío",
          submissionTime: "Hora de Envío",
          fileResolution: "Resolución del Archivo",
          url: "URL",
          faces: "rostros",
          face: "rostro",
          processingMessage: "Procesando informe de video. Esto puede tardar unos minutos.",
          selectFaceMessage: "Por favor seleccione al menos un rostro para generar el informe de video.",
          enableVoiceAnalysisMessage: "Para habilitar el servicio de análisis de voz, por favor contacte a support@sensity.ai",
          exportingReport: "Exportando Informe",
          processingVideo: "Procesando Video"
        },
        explanation: {
          fakeFacesDetected: "Rostros Falsos Detectados",
          faceExplanation: "Al seleccionar los rostros, los cuadros delimitadores relativos se mostrarán sobre el medio.",
          faceExplanationVideo: "y en el video exportado.",
          selected: "seleccionado",
          viewOnVideo: "Ver en video",
          exportVideoLabel: "Exportar video con rostros seleccionados",
          faceResolution: "Resolución del Rostro",
          low: "baja",
          lowResolutionInfo: "La baja resolución del rostro puede comprometer la precisión del detector",
          viewAt: "Ver en",
          pixelBasedAssessment: "Evaluación basada en píxeles",
          pixelBasedInfo: "Esta sección proporciona representaciones visuales que confirman los resultados anteriores",
          expandImage: "Expandir Imagen",
          expandVideoFrame: "Expandir Fotograma de Video",
          heatmapInfo: "El mapa de calor muestra las áreas donde el modelo detectó una mayor probabilidad de síntesis a nivel de píxel",
          sectionDesc: "Esta sección proporciona representaciones visuales que confirman los resultados anteriores",
          pixelAndSegmentTitle: "Evaluación basada en píxeles y segmentos",
          faceAndPixelTitle: "Evaluación de alineación facial y basada en píxeles",
          segmentBasedNotAvailable: "La evaluación basada en segmentos no está disponible para este medio",
          segmentBasedAssesment: "Evaluación Basada en Segmentos",
          segmentBasedInfo: "El detector identifica los objetos en la imagen que más probablemente sean generados por IA",
          faceAlignmentAssesment: "Evaluación de Alineación Facial",
          faceAlignmentInfo: "Las imágenes generadas por esta familia de GANs pueden reconocerse por la posición fija de ojos y boca",
          fakeSpeakersDetected: "Oradores Falsos Detectados"
        }
      },
      form: {
        title: "Cargar Medio",
        tab: {
          file: "Archivo",
          url: "URL",
          urlNotAvailable: "La carga por URL no está disponible en la versión local"
        },
      }
    },
    document: {
      title: "Verificación de Documentos",
      subtitle: "Extrae información clave y valida la autenticidad de documentos.",
      button: "Iniciar Verificación de Documentos",
    },
    identity: {
      title: "Verificación de Identidad",
      subtitle: "Realiza verificación de identidad y detección de vivacidad.",
      button: "Iniciar Verificación de Identidad",
    },
    developer: {
      title: "Desarrollador",
      subtitle: "Gestione sus claves API y uso",
      apiToken: "Token API"
    }
  },
  name: {
    service: {
      deepfake: "Detección de Deepfake",
      aiGen: "Contenido Generado por IA",
      faceMan: "Manipulación Facial",
      document: "Verificación de Documentos",
      forensic: "Análisis Forense",
      id: "Verificación de Identidad",
      liveness: "Video en Vivo",
      pixel: "Análisis de Píxeles",
      voice: "Análisis de Voz",
      faceMatch: "Coincidencia Facial",
      title: "Sensity Enterprise",
      description: "Plataforma de Detección de IA"
    },
    language: {
      english: "Inglés (English)",
      turkish: "Turco (Türkçe)",
      uzbek: "Uzbeko (O'zbekcha)",
      spanish: "Español",
      korean: "Coreano (한국어)",
      russian: "Ruso (Русский)",
      hindi: "Hindi (हिंदी)",
      german: "Alemán (Deutsch)",
      dutch: "Holandés (Nederlands)",
      chinese: "Chino (中文)",
      arabic: "Árabe (العربية)",
      indonesian: "Indonesio (Bahasa)",
      vietnamese: "Vietnamita (Tiếng Việt)",
      japanese: "Japonés (日本語)",
      italian: "Italiano (Italiano)",
      french: "Francés (Français)"
    }
  }
}
