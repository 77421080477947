<script>
import SnsSelect from "@/components/SnsSelect.vue";
import SnsButton from "@/components/SnsButton.vue";

export default {
  name: "LanguageSwitcher",
  components: {
    SnsSelect,
    SnsButton,
  },
  data() {
    return {
      selectedLocale: {},
      languages: [
        { text: this.$t("name.language.english"), value: "en" },
        { text: this.$t("name.language.arabic"), value: "ar" },
        { text: this.$t("name.language.german"), value: "de" },
        { text: this.$t("name.language.spanish"), value: "es" },
        { text: this.$t("name.language.french"), value: "fr" },
        { text: this.$t("name.language.hindi"), value: "hi" },
        { text: this.$t("name.language.indonesian"), value: "id" },
        { text: this.$t("name.language.italian"), value: "it" },
        { text: this.$t("name.language.japanese"), value: "ja" },
        { text: this.$t("name.language.korean"), value: "ko" },
        { text: this.$t("name.language.dutch"), value: "nl" },
        { text: this.$t("name.language.russian"), value: "ru" },
        { text: this.$t("name.language.turkish"), value: "tr" },
        { text: this.$t("name.language.uzbek"), value: "uz" },
        { text: this.$t("name.language.vietnamese"), value: "vn" },
        { text: this.$t("name.language.chinese"), value: "zh" },
      ]
    };
  },
  computed: {
    localeCanChange() {
      return this.selectedLocale.value !== this.$i18n.locale;
    },
    sortedLanguages() {
      return [...this.languages].sort((a, b) => {
        if (a.value === this.selectedLocale.value) return -1;
        if (b.value === this.selectedLocale.value) return 1;
        if (a.value === "en") return -1;
        if (b.value === "en") return 1;
        return a.text.localeCompare(b.text);
      });
    }
  },
  mounted() {
    this.selectedLocale = this.languages.find(lang => lang.value === this.$i18n.locale)
      || this.languages.find(lang => lang.value === "en")
      || this.languages[0];
  },
  methods: {
    switchLanguage() {
      this.$i18n.locale = this.selectedLocale.value;
      localStorage.setItem("locale", this.selectedLocale.value);
      window.location.reload();
    }
  },
};
</script>

<template>
  <div class="flex items-center w-full">
    <div class="basis-5/6">
      <SnsSelect
        v-model="selectedLocale"
        :items="sortedLanguages"
      />
    </div>

    <div class="basis-1/6 pl-2">
      <SnsButton
        custom-class="w-full"
        :disabled="!localeCanChange"
        @click="switchLanguage"
      >
        <div class="flex">
          <i class="bi-floppy-fill mr-2" />
          {{ $t("common.action.save") }}
        </div>
      </SnsButton>
    </div>
  </div>
</template>
