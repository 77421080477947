/* eslint-disable */
export default {
  common: {
    action: {
      submit: "जमा करें",
      register: "पंजीकरण करें",
      login: "लॉग इन करें",
      continue: "जारी रखें",
      confirm: "पुष्टि करें",
      close: "बंद करें",
      back: "वापस जाएं",
      browse: "फ़ाइलें ब्राउज़ करें",
      save: "सहेजें",
      cancel: "रद्द करें",
      delete: "हटाएं",
      create: "बनाएं",
      invite: "आमंत्रित करें",
      analyze: "विश्लेषण करें",
      selectAll: "सभी चुनें",
      clearFilters: "फ़िल्टर साफ़ करें",
      exportReport: "रिपोर्ट निर्यात करें",
      exportAsPDF: "PDF के रूप में निर्यात करें",
      exportCSV: "CSV निर्यात करें",
      analyzeVoice: "आवाज का विश्लेषण करें",
      backToAll: "सभी रिपोर्ट पर वापस जाएं",
      downloadVideoReport: "वीडियो रिपोर्ट डाउनलोड करें",
      generateVideoReport: "वीडियो रिपोर्ट जनरेट करें",
      startDeepfake: "डीपफ़ेक पहचान शुरू करें",
      changePassword: "पासवर्ड बदलें",
      logout: "लॉग आउट करें",
      editMember: "सदस्य संपादित करें",
      editTeam: "टीम संपादित करें",
      newUser: "नया उपयोगकर्ता",
      createTeam: "नई टीम बनाएं",
      select: "चुनें",
      copy: "कॉपी करें",
      showThumbnails: "थंबनेल दिखाएं",
      blurThumbnails: "थंबनेल धुंधला करें",
      showMore: "और दिखाएं",
      showLess: "कम दिखाएं"
    },
    loading: {
      submitting: "जमा किया जा रहा है",
      registering: "पंजीकरण हो रहा है",
      loggingIn: "लॉग इन हो रहा है",
      sending: "भेजा जा रहा है",
      inProgress: "प्रगति में है",
      changing: "बदला जा रहा है",
      processing: "प्रोसेस हो रहा है",
      exporting: "निर्यात हो रहा है",
      generating: "जनरेट हो रहा है",
      loading: "लोड हो रहा है"
    },
    placeholder: {
      email: "{'example@mail.com'}",
      password: "*********",
      enterUrl: "URL दर्ज करें",
      enterEmail: "ईमेल पता दर्ज करें",
      enterTeamName: "टीम का नाम दर्ज करें",
      select: "कृपया एक मान चुनें",
      searchByNameAndEmail: "नाम या ईमेल से खोजें"
    },
    label: {
      email: "ईमेल",
      password: "पासवर्ड",
      confirmPassword: "पासवर्ड की पुष्टि करें",
      name: "नाम",
      firstName: "पहला नाम",
      lastName: "अंतिम नाम",
      phoneNumber: "फ़ोन नंबर",
      industry: "उद्योग",
      companyWebsite: "कंपनी वेबसाइट",
      companyEmail: "कंपनी ईमेल",
      oldPassword: "पुराना पासवर्ड",
      newPassword: "नया पासवर्ड",
      teamName: "टीम का नाम",
      teamVisibility: "टीम की दृश्यता",
      role: "भूमिका",
      emailAddress: "ईमेल पता",
      dragAndDrop: "मीडिया फ़ाइलें यहाँ खींचें और छोड़ें",
      browseFiles: "फ़ाइलें ब्राउज़ करें",
      or: "या",
      hide: "छिपाएं",
      show: "दिखाएं",
      all: "सभी",
      selected: "{count} चयनित",
      minutes: "मिनट",
      allResults: "सभी परिणाम",
      audio: "ऑडियो",
      video: "वीडियो",
      image: "छवि",
      pdf: "पीडीएफ",
      url: "URL",
      fileName: "फ़ाइल का नाम",
      reportId: "रिपोर्ट आईडी",
      searchType: "खोज प्रकार",
      searchBy: "इसके द्वारा खोजें",
      status: "स्थिति",
      user: "उपयोगकर्ता",
      serviceType: "सेवा प्रकार",
      date: "दिनांक",
      report: "रिपोर्ट",
      reportStatus: "रिपोर्ट की स्थिति",
      extractedDetails: "निकाली गई जानकारी",
      settings: "सेटिंग्स",
      language: "भाषा",
      noTeamMembers: "कोई टीम सदस्य नहीं मिला।",
      currentUser: "वर्तमान उपयोगकर्ता",
      inviteSent: "निमंत्रण भेजा गया",
      members: "{count} सदस्य",
      timeRange: "समय सीमा",
      duration: "अवधि",
      speaker: "वक्ता"
    }
  },
  component: {
    list: {
      title: "परिणामों की सूची",
      noData: "कोई डेटा नहीं मिला",
      noDataForEmail: "इस ईमेल के लिए कोई परिणाम नहीं मिला:",
      searchPlaceholder: "ईमेल से खोजें...",
      resultsFound: "परिणाम मिले: {count}",
      deleteModal: {
        title: "चयनित आइटम हटाएं",
        message: "ये रिपोर्ट हटा दी जाएंगी। क्या आप जारी रखना चाहते हैं?"
      },
      table: {
        header: {
          media: "मीडिया",
          info: "जानकारी",
          result: "परिणाम",
          riskScore: "दस्तावेज़ जोखिम स्कोर"
        }
      }
    },
    status: {
      inProgress: "प्रगति में",
      completed: "पूर्ण",
      failed: "विफल",
      verified: "सत्यापित",
      notVerified: "सत्यापित नहीं",
      valid: "मान्य",
      suspicious: "संदिग्ध",
      serverError: "सर्वर त्रुटि",
      extractionFailed: "निष्कर्षण विफल",
      tooltip: {
        analyzing: "सामग्री का विश्लेषण हो रहा है...",
        urlExtractionFailed: "URL सामग्री निष्कर्षण विफल",
        urlExtractionFailedDesc: "दी गई URL से सामग्री नहीं निकाली जा सकी।",
        fakeDetected: "फर्जी सामग्री या स्पूफिंग प्रयास का पता चला",
        fakeDetectedDesc: "इस सामग्री को फर्जी के रूप में चिह्नित किया गया है या इसमें स्पूफिंग प्रयास शामिल है।",
        checkCompleted: "जांच सफलतापूर्वक पूरी हुई",
        checkCompletedDesc: "कोई असामान्यता या स्पूफिंग प्रयास नहीं मिला।",
        serverErrorDesc: "कुछ गलत हो गया। कृपया पुनः प्रयास करें।"
      }
    },
    table: {
      detection: {
        faceManCheck: "चेहरा हेरफेर जांच",
        aiGenCheck: "AI जनित सामग्री जांच",
        forensicAnalysisCheck: "फ़ॉरेंसिक विश्लेषण जांच",
        voiceAnalysisCheck: "आवाज विश्लेषण जांच",
        confidence: "विश्वास स्तर",
        modelGenerator: "मॉडल जनरेटर",
        manipulationType: "हेरफेर प्रकार",
        noFace: "चेहरा बहुत छोटा/कोई चेहरा नहीं",
        noVoice: "कोई आवाज नहीं",
        details: "विवरण",
        alert: "चेतावनी",
        creationDate: "निर्माण तिथि",
        modifyDate: "संशोधन तिथि",
        notAvailable: "उपलब्ध नहीं",
        description: {
          confidence: "उच्च विश्वास स्तर कम से कम 50% निश्चितता के साथ AI हेरफेर के स्पष्ट संकेत दर्शाता है",
          confidenceAI: "उच्च विश्वास स्तर कम से कम 50% निश्चितता के साथ AI जनरेशन के स्पष्ट संकेत दर्शाता है",
          modelGenerator: "मॉडल जनरेटर: जब दिखाया जाता है, डिटेक्टर यह भी पहचान सकता है कि किस मॉडल का उपयोग जनरेटर के रूप में किया गया था",
          manipulationType: "हेरफेर प्रकार: जब दिखाया जाता है, डिटेक्टर यह भी पहचान सकता है कि यह फेस स्वैप, फेस रीएनैक्टमेंट, लिपसिंक या फेस एनिमेशन है या नहीं"
        }
      }
    },
    pagination: {
      itemPerPage: "प्रति पृष्ठ आइटम:",
      range: "{total} में से {from}-{to}",
    },
    modal: {
      upgradePlan: {
        title: "प्लान अपग्रेड करें",
        message: "नीचे दिए गए बटन पर क्लिक करके आप अपने अकाउंट मैनेजर से संपर्क कर सकते हैं।",
        demoMessage: "\"मैं अपना प्लान अपग्रेड करना चाहता हूं\" पर क्लिक करने के बाद, आपका अकाउंट मैनेजर आपसे संपर्क करेगा और विभिन्न विकल्पों की व्याख्या करेगा।\nक्या आप आगे बढ़ना चाहते हैं?",
        licenceMessage: "आपका लाइसेंस समाप्त हो गया है। लाइसेंस नवीनीकरण के लिए कृपया हमसे संपर्क करें।",
        monthlyLimitReached: "मासिक सीमा पूरी हुई",
        trialLimitReached: "आप अपनी मासिक ट्रायल सबमिशन सीमा { limit } तक पहुंच गए हैं।",
        action: "मैं अपना प्लान अपग्रेड करना चाहता हूं",
      },
    }
  },
  feedback: {
    error: {
      label: "त्रुटि!",
      default: "एक त्रुटि हुई",
      server: "सर्वर त्रुटि हुई",
      generic: "कुछ गलत हो गया। कृपया बाद में पुनः प्रयास करें।",
      unsupportedFileType: "आपकी सदस्यता इस प्रकार की फ़ाइल अपलोड करने की अनुमति नहीं देती",
      terms: "कृपया नियम और शर्तें स्वीकार करें!",
      upload: "फ़ाइल अपलोड करने में विफल",
    },
    success: {
      label: "सफल!",
      default: "कार्य सफल रहा",
      passwordChanged: "पासवर्ड सफलतापूर्वक बदला गया",
      registration: "आपका अनुरोध स्वीकृति के लिए भेज दिया गया है",
      contact: "आपके अनुरोध के लिए धन्यवाद, हम जल्द ही आपसे संपर्क करेंगे",
      copy: "क्लिपबोर्ड पर कॉपी किया गया"
    },
    warning: {
      label: "चेतावनी!",
      trialDisclaimer: "आप प्लेटफ़ॉर्म का परीक्षण संस्करण उपयोग कर रहे हैं। बिना प्रतिबंधों के प्लेटफ़ॉर्म का उपयोग करने के लिए प्रीमियम योजना में अपग्रेड करें।",
      submissionDisclaimer: "कृपया व्यक्तिगत जानकारी न भेजें। Sensity आपके द्वारा भेजी गई सामग्री के लिए जिम्मेदार नहीं है।"
    },
    info: {
      agreeTo: "मैं निम्नलिखित से सहमत हूं:",
      terms: "नियम और शर्तें",
      privacy: "गोपनीयता नीति"
    },
    validation: {
      required: "यह फ़ील्ड आवश्यक है",
      url: "कृपया मान्य URL दर्ज करें",
      email: "कृपया मान्य ईमेल दर्ज करें",
      password: "कृपया मान्य पासवर्ड दर्ज करें",
      passwordMismatch: "पासवर्ड मेल नहीं खाते",
      numeric: "यह फ़ील्ड संख्यात्मक होनी चाहिए",
      workEmail: "कृपया कार्य ईमेल प्रदान करें",
      website: "कृपया मान्य वेबसाइट दर्ज करें",
      phoneNumber: "कृपया मान्य फ़ोन नंबर दर्ज करें",
      allFieldsRequired: "कृपया सभी फ़ील्ड भरें",
      allFieldsCorrect: "कृपया सभी फ़ील्ड सही ढंग से भरें",
      termsRequired: "कृपया नियम और शर्तें स्वीकार करें"
    },
    limitation: {
      maxFileSize: "अधिकतम फ़ाइल आकार",
      maxFile: "एक साथ अपलोड करने के लिए अधिकतम फ़ाइलों की संख्या",
      invalidFile: "अमान्य फ़ाइल प्रकार",
      singleFileWarning: "आप केवल एक फ़ाइल डाल सकते हैं",
      maxFileWarning: "आप एक बार में केवल {count} फ़ाइलें डाल सकते हैं",
      maxFileNameLength: "फ़ाइल का नाम अधिकतम {count} अक्षर का हो सकता है",
      badExtension: "गलत फ़ाइल एक्सटेंशन",
      maxAudioDuration: "अधिकतम ऑडियो अवधि {value} मिनट",
      maxVideoDuration: "अधिकतम वीडियो अवधि {value} मिनट",
      maxVideoResolution: "अधिकतम वीडियो रेजोल्यूशन {height}x{width} या {width}x{height}",
      fileAlreadyUploaded: "फ़ाइल {filename} पहले से अपलोड की गई है",
      unsupportedFileType: "आपकी सदस्यता के लिए असमर्थित फ़ाइल प्रकार"
    }
  },
  page: {
    home: {
      title: "डैशबोर्ड",
      subtitle: "उपयोग और सबमिशन विवरण देखें",
      stats: {
        monthlyLeftCredits: "शेष मासिक क्रेडिट",
        monthlyUsage: "मासिक उपयोग",
        accountExpire: "खाता समाप्त होगा",
        getStarted: "Sensity से अधिकतम लाभ प्राप्त करें",
        videoTitle: "Sensity पर आरंभ करना: विशेषताएं और परिणाम"
      }
    },
    auth: {
      login: {
        title: "लॉग इन करें",
        subtitle: "खाता नहीं है?",
        signUpLink: "निःशुल्क साइन अप करें",
        forgotPassword: "पासवर्ड भूल गए?"
      },
      register: {
        title: "खाता बनाएं",
        subtitle: "पहले से खाता है?",
        emailDisclaimer: "आपका पंजीकरण एक मानव ऑपरेटर द्वारा स्वीकृत किया जाएगा। मुफ्त डोमेन और अस्थायी डोमेन डिफ़ॉल्ट रूप से वैध नहीं माने जाएंगे",
        detailsPlaceholder: "विवरण\nकृपया अपनी संस्था और Sensity का उपयोग करके प्राप्त करने के लिए आपके लक्ष्यों के बारे में अधिक बताएं। पर्याप्त जानकारी के बिना आपका खाता अस्वीकृत कर दिया जाएगा"
      },
      passwordRecovery: {
        title: "पासवर्ड पुनर्प्राप्ति",
        subtitle: "वापस जाएं",
        message: "अपने खाते के लिए उपयोग किया जाने वाला ईमेल दर्ज करें।",
        successMessage: "हमने आपके ईमेल पते पर पासवर्ड रीसेट निर्देश भेज दिए हैं। यदि दस मिनट के भीतर ईमेल प्राप्त नहीं होता है, तो जांचें कि सबमिट किया गया पता सही है।"
      },
      confirmPassword: {
        title: "पासवर्ड रीसेट करें"
      },
      changePassword: {
        title: "पासवर्ड बदलें"
      }
    },
    analytics: {
      title: "विश्लेषण",
      subtitle: "प्लेटफ़ॉर्म और API सबमिशन दोनों के लिए सभी अपलोड की गई सामग्री की विस्तृत जानकारी का अन्वेषण करें।"
    },
    team: {
      title: "टीम प्रबंधन",
      visibility: {
        visible: "सबमिशन पूरी टीम को दिखाई देते हैं",
        notVisible: "सबमिशन पूरी टीम को दिखाई नहीं देते",
        tooltip: {
          visible: "आपकी टीम के सबमिशन सभी टीम सदस्यों को दिखाई देते हैं।",
          notVisible: "आपके सबमिशन प्रत्येक टीम सदस्य के लिए निजी हैं।"
        },
        hint: "यदि आप अपनी टीम के सबमिशन को सभी टीम सदस्यों के लिए दृश्यमान बनाना चाहते हैं, तो कृपया इस बॉक्स को टिक करें। यदि आप चाहते हैं कि प्रत्येक टीम सदस्य का सबमिशन निजी हो तो इसे टिक न करें।"
      },
      addNewMemberModal: {
        title: "नया टीम सदस्य जोड़ें",
        desc: "कृपया नए टीम सदस्य का ईमेल पता प्रदान करें।",
        existingMember: "यह उपयोगकर्ता पहले से ही टीम का सदस्य है।"
      },
      upgradeModal: {
        title: "टीम प्रबंधन एक प्रीमियम सुविधा है",
        desc: "\"मैं अपनी योजना अपग्रेड करना चाहता हूं\" पर क्लिक करने से आपका खाता प्रबंधक आपसे संपर्क करेगा जो विभिन्न विकल्पों की व्याख्या करेगा।",
        confirm: "क्या आप आगे बढ़ना चाहते हैं?",
        button: "मैं अपनी योजना अपग्रेड करना चाहता हूं"
      },
      deleteTeamMemberModal: {
        title: "टीम सदस्य हटाएं",
        desc: "ये उपयोगकर्ता टीम से हटा दिए जाएंगे। क्या आप आगे बढ़ना चाहते हैं?"
      },
      createTeamModal: {
        title: "नई टीम बनाएं",
        desc: "कृपया अपनी टीम के लिए एक नाम प्रदान करें"
      },
      newUserTooltip: {
        analyst: "अधिक उपयोगकर्ता जोड़ने के लिए कृपया अपने टीम व्यवस्थापक से संपर्क करें।",
        admin: "अपनी टीम में नए उपयोगकर्ता जोड़ें।"
      }
    },
    deepfake: {
      title: "डीपफ़ेक पहचान",
      subtitle: "ऑडियो और वीडियो डीपफ़ेक और AI जनित सामग्री का पता लगाने के लिए संदिग्ध मीडिया का विश्लेषण करें।",
      button: "डीपफ़ेक पहचान प्रारंभ करें",
      info: {
        automation: "क्या आप सबमिशन को स्वचालित करना चाहते हैं?",
        checkDocs: "हमारे API दस्तावेज़ देखें",
        checkDocsOnPrem: "हमारे API दस्तावेज़ देखने के लिए हमारी ऑन-प्रिमाइसेस उपयोगकर्ता मैनुअल देखें।",
        contactSupport: "और हमसे यहां संपर्क करें"
      },
      report: {
        overall: {
          user: "उपयोगकर्ता",
          fileName: "फ़ाइल का नाम",
          fileSize: "फ़ाइल आकार (KB)",
          fileHash: "फ़ाइल हैश (SHA256)",
          submissionDate: "सबमिशन दिनांक",
          submissionTime: "सबमिशन समय",
          fileResolution: "फ़ाइल रेजोल्यूशन",
          url: "URL",
          faces: "चेहरे",
          face: "चेहरा",
          processingMessage: "वीडियो रिपोर्ट प्रोसेस की जा रही है। इसमें कुछ मिनट लग सकते हैं।",
          selectFaceMessage: "वीडियो रिपोर्ट जनरेट करने के लिए कृपया कम से कम एक चेहरा चुनें।",
          enableVoiceAnalysisMessage: "आवाज विश्लेषण सेवा सक्षम करने के लिए कृपया support@sensity.ai पर संपर्क करें",
          exportingReport: "रिपोर्ट निर्यात की जा रही है",
          processingVideo: "वीडियो प्रोसेस की जा रही है"
        },
        explanation: {
          fakeFacesDetected: "फर्जी चेहरे मिले",
          faceExplanation: "चेहरों को चुनने पर, संबंधित बाउंडिंग बॉक्स मीडिया के ऊपर दिखाए जाएंगे।",
          faceExplanationVideo: "और निर्यात की गई वीडियो में।",
          selected: "चयनित",
          viewOnVideo: "वीडियो पर देखें",
          exportVideoLabel: "चयनित चेहरों के साथ वीडियो निर्यात करें",
          faceResolution: "चेहरे का रेजोल्यूशन",
          low: "कम",
          lowResolutionInfo: "कम चेहरा रेजोल्यूशन डिटेक्टर की सटीकता को प्रभावित कर सकता है",
          viewAt: "यहाँ देखें",
          pixelBasedAssessment: "पिक्सेल आधारित मूल्यांकन",
          pixelBasedInfo: "यह खंड ऊपर के परिणामों की पुष्टि करने वाले दृश्य प्रतिनिधित्व प्रदान करता है",
          expandImage: "छवि बड़ी करें",
          expandVideoFrame: "वीडियो फ्रेम बड़ा करें",
          heatmapInfo: "हीटमैप उन क्षेत्रों को दर्शाता है जहां मॉडल ने पिक्सेल स्तर पर सिंथेसिस की उच्च संभावना का पता लगाया",
          sectionDesc: "यह खंड ऊपर के परिणामों की पुष्टि करने वाले दृश्य प्रतिनिधित्व प्रदान करता है",
          pixelAndSegmentTitle: "पिक्सेल और सेगमेंट आधारित मूल्यांकन",
          faceAndPixelTitle: "चेहरा संरेखण और पिक्सेल आधारित मूल्यांकन",
          segmentBasedNotAvailable: "इस मीडिया के लिए सेगमेंट आधारित मूल्यांकन उपलब्ध नहीं है",
          segmentBasedAssesment: "सेगमेंट आधारित मूल्यांकन",
          segmentBasedInfo: "डिटेक्टर छवि में उन वस्तुओं की पहचान करता है जो सबसे अधिक संभावना है कि AI द्वारा उत्पन्न की गई हैं",
          faceAlignmentAssesment: "चेहरा संरेखण मूल्यांकन",
          faceAlignmentInfo: "इस GAN परिवार द्वारा उत्पन्न छवियों को आंखों और मुंह की निश्चित स्थिति से पहचाना जा सकता है",
          fakeSpeakersDetected: "नकली वक्ताओं का पता चला"
        }
      },
      form: {
        title: "मीडिया अपलोड करें",
        tab: {
          file: "फ़ाइल",
          url: "URL",
          urlNotAvailable: "URL अपलोड ऑन-प्रिमाइसेस में उपलब्ध नहीं है"
        }
      },
    },
    document: {
      title: "दस्तावेज़ सत्यापन",
      subtitle: "प्रमुख जानकारी निकालें और दस्तावेज़ों की प्रामाणिकता को मान्य करें।",
      button: "दस्तावेज़ सत्यापन शुरू करें",
    },
    identity: {
      title: "पहचान सत्यापन",
      subtitle: "पहचान सत्यापन और जीवंतता का पता लगाता है।",
      button: "पहचान सत्यापन शुरू करें",
    },
    developer: {
      title: "डेवलपर",
      subtitle: "अपनी API कुंजियों और उपयोग का प्रबंधन करें",
      apiToken: "API टोकन"
    }
  },
  name: {
    service: {
      deepfake: "डीपफ़ेक पहचान",
      aiGen: "AI जनित सामग्री",
      faceMan: "चेहरा हेरफेर",
      document: "दस्तावेज़ सत्यापन",
      forensic: "फॉरेंसिक विश्लेषण",
      id: "पहचान सत्यापन",
      liveness: "वीडियो लाइवनेस",
      pixel: "पिक्सेल विश्लेषण",
      voice: "आवाज विश्लेषण",
      faceMatch: "चेहरा मिलान",
      title: "Sensity एंटरप्राइज",
      description: "AI पहचान प्लेटफॉर्म"
    },
    language: {
      turkish: "तुर्की (Türkçe)",
      english: "अंग्रेजी (English)",
      spanish: "स्पेनिश (Español)",
      korean: "कोरियाई (한국어)",
      russian: "रूसी (Русский)",
      hindi: "हिंदी",
      german: "जर्मन (Deutsch)",
      dutch: "डच (Nederlands)",
      uzbek: "उज़्बेक (O'zbekcha)",
      chinese: "चीनी (中文)",
      arabic: "अरबी (العربية)",
      indonesian: "इंडोनेशियाई (Bahasa)",
      vietnamese: "वियतनामी (Tiếng Việt)",
      japanese: "जापानी (日本語)",
      italian: "इतालवी (Italiano)",
      french: "फ्रांसीसी (Français)"
    }
  }
}
