/* eslint-disable */
export default {
  common: {
    action: {
      submit: "제출",
      register: "회원가입",
      login: "로그인",
      continue: "계속",
      confirm: "확인",
      close: "닫기",
      back: "뒤로",
      browse: "파일 찾기",
      save: "저장",
      cancel: "취소",
      delete: "삭제",
      create: "생성",
      invite: "초대",
      analyze: "분석",
      select: "선택",
      selectAll: "전체 선택",
      clearFilters: "필터 초기화",
      exportReport: "보고서 내보내기",
      exportAsPDF: "PDF로 내보내기",
      exportCSV: "CSV 내보내기",
      analyzeVoice: "음성 분석",
      backToAll: "전체 보고서로 돌아가기",
      downloadVideoReport: "동영상 보고서 다운로드",
      generateVideoReport: "동영상 보고서 생성",
      startDeepfake: "딥페이크 감지 시작",
      changePassword: "비밀번호 변경",
      logout: "로그아웃",
      editMember: "팀원 수정",
      editTeam: "팀 수정",
      newUser: "새 사용자",
      createTeam: "새 팀 만들기",
      copy: "복사",
      showThumbnails: "썸네일 표시",
      blurThumbnails: "썸네일 흐림 처리",
      showMore: "더 보기",
      showLess: "접기"
    },
    loading: {
      submitting: "제출 중",
      registering: "등록 중",
      loggingIn: "로그인 중",
      sending: "전송 중",
      inProgress: "진행 중",
      changing: "변경 중",
      processing: "처리 중",
      exporting: "내보내는 중",
      generating: "생성 중",
      loading: "로딩 중"
    },
    placeholder: {
      email: "{'example@mail.com'}",
      password: "*********",
      enterUrl: "URL 입력",
      enterEmail: "이메일 주소 입력",
      enterTeamName: "팀 이름 입력",
      select: "값을 선택해주세요",
      searchByNameAndEmail: "이름 또는 이메일로 검색"
    },
    label: {
      email: "이메일",
      password: "비밀번호",
      confirmPassword: "비밀번호 확인",
      name: "이름",
      firstName: "이름",
      lastName: "성",
      phoneNumber: "전화번호",
      industry: "산업",
      companyWebsite: "회사 웹사이트",
      companyEmail: "회사 이메일",
      oldPassword: "기존 비밀번호",
      newPassword: "새 비밀번호",
      teamName: "팀 이름",
      teamVisibility: "팀 공개 설정",
      role: "역할",
      emailAddress: "이메일 주소",
      dragAndDrop: "여기에 미디어 파일을 끌어다 놓으세요",
      browseFiles: "파일 찾기",
      or: "또는",
      hide: "숨기기",
      show: "표시",
      all: "전체",
      selected: "{count}개 선택됨",
      minutes: "분",
      allResults: "모든 결과",
      audio: "오디오",
      video: "비디오",
      image: "이미지",
      pdf: "PDF",
      url: "URL",
      fileName: "파일 이름",
      reportId: "보고서 ID",
      searchType: "검색 유형",
      searchBy: "검색 기준",
      status: "상태",
      user: "사용자",
      serviceType: "서비스 유형",
      date: "날짜",
      report: "보고서",
      reportStatus: "보고서 상태",
      extractedDetails: "추출된 세부 정보",
      settings: "설정",
      language: "언어",
      noTeamMembers: "팀원을 찾을 수 없습니다.",
      currentUser: "현재 사용자",
      inviteSent: "초대장 발송됨",
      members: "{count} 멤버",
      timeRange: "시간 범위",
      duration: "기간",
      speaker: "화자"
    }
  },
  component: {
    list: {
      title: "결과 목록",
      noData: "데이터를 찾을 수 없습니다",
      noDataForEmail: "이 이메일에 대한 결과를 찾을 수 없습니다:",
      searchPlaceholder: "이메일로 검색...",
      resultsFound: "결과 찾음: {count}개",
      deleteModal: {
        title: "선택한 항목 삭제",
        message: "이 보고서들이 삭제됩니다. 계속하시겠습니까?"
      },
      table: {
        header: {
          media: "미디어",
          info: "정보",
          result: "결과",
          riskScore: "문서 위험 점수"
        }
      }
    },
    status: {
      inProgress: "진행 중",
      completed: "완료됨",
      failed: "실패",
      verified: "확인됨",
      notVerified: "확인되지 않음",
      valid: "유효함",
      suspicious: "의심스러움",
      serverError: "서버 오류",
      extractionFailed: "추출 실패",
      tooltip: {
        analyzing: "콘텐츠 분석 중...",
        urlExtractionFailed: "URL 콘텐츠 추출 실패",
        urlExtractionFailedDesc: "제공된 URL에서 콘텐츠를 추출할 수 없습니다.",
        fakeDetected: "가짜 콘텐츠 또는 스푸핑 시도 감지됨",
        fakeDetectedDesc: "이 콘텐츠가 가짜로 표시되었거나 스푸핑 시도를 포함하고 있습니다.",
        checkCompleted: "검사가 성공적으로 완료됨",
        checkCompletedDesc: "비정상이나 스푸핑 시도가 발견되지 않았습니다.",
        serverErrorDesc: "문제가 발생했습니다. 다시 시도해 주세요."
      }
    },
    table: {
      detection: {
        faceManCheck: "얼굴 조작 확인",
        aiGenCheck: "AI 생성 콘텐츠 확인",
        forensicAnalysisCheck: "포렌식 분석 확인",
        voiceAnalysisCheck: "음성 분석 확인",
        confidence: "신뢰도",
        modelGenerator: "모델 생성기",
        manipulationType: "조작 유형",
        noFace: "얼굴이 너무 작음/얼굴 없음",
        noVoice: "음성 없음",
        details: "상세 정보",
        alert: "경고",
        creationDate: "생성 날짜",
        modifyDate: "수정 날짜",
        notAvailable: "해당 없음",
        description: {
          confidence: "높은 신뢰도는 최소 50% 확실성으로 AI 조작의 명확한 징후를 나타냅니다",
          confidenceAI: "높은 신뢰도는 최소 50% 확실성으로 AI 생성의 명확한 징후를 나타냅니다",
          modelGenerator: "모델 생성기: 표시될 경우, 감지기가 어떤 모델이 생성기로 사용되었는지도 식별할 수 있습니다",
          manipulationType: "조작 유형: 표시될 경우, 감지기가 이것이 얼굴 교체, 얼굴 재연출, 립싱크 또는 얼굴 애니메이션인지도 식별할 수 있습니다"
        }
      }
    },
    pagination: {
      itemPerPage: "페이지당 항목:",
      range: "{total}개 중 {from}-{to}",
    },
    modal: {
      upgradePlan: {
        title: "플랜 업그레이드",
        message: "아래 버튼을 클릭하여 계정 관리자에게 문의하실 수 있습니다.",
        demoMessage: "\"플랜을 업그레이드하고 싶습니다\"를 클릭하시면 계정 관리자가 연락하여 다양한 옵션을 설명해 드립니다.\n계속하시겠습니까?",
        licenceMessage: "라이선스가 만료되었습니다. 라이선스를 갱신하려면 문의해 주세요.",
        monthlyLimitReached: "월간 한도 도달",
        trialLimitReached: "월간 체험판 제출 한도 { limit }에 도달했습니다.",
        action: "플랜을 업그레이드하고 싶습니다",
      },
    }
  },
  feedback: {
    error: {
      label: "오류!",
      default: "오류가 발생했습니다",
      server: "서버 오류가 발생했습니다",
      generic: "문제가 발생했습니다. 나중에 다시 시도해 주세요.",
      unsupportedFileType: "귀하의 구독으로는 이 파일 유형을 업로드할 수 없습니다",
      terms: "이용 약관에 동의해 주세요!",
      upload: "파일 업로드에 실패했습니다"
    },
    success: {
      label: "성공!",
      default: "작업이 성공적으로 완료되었습니다",
      passwordChanged: "비밀번호가 성공적으로 변경되었습니다",
      registration: "요청이 승인을 위해 전송되었습니다",
      contact: "요청해 주셔서 감사합니다. 곧 연락드리겠습니다",
      copy: "클립보드에 복사되었습니다"
    },
    warning: {
      label: "경고!",
      trialDisclaimer: "플랫폼의 평가판을 사용 중입니다. 제한 없이 플랫폼을 사용하려면 프리미엄 플랜으로 업그레이드하세요.",
      submissionDisclaimer: "개인 정보를 제출하지 마세요. Sensity는 제출하신 콘텐츠에 대해 책임지지 않습니다."
    },
    info: {
      agreeTo: "다음에 동의합니다:",
      terms: "이용 약관",
      privacy: "개인정보 처리방침"
    },
    validation: {
      required: "이 필드는 필수입니다",
      url: "유효한 URL을 입력해 주세요",
      email: "유효한 이메일을 입력해 주세요",
      password: "유효한 비밀번호를 입력해 주세요",
      passwordMismatch: "비밀번호가 일치하지 않습니다",
      numeric: "이 필드는 숫자여야 합니다",
      workEmail: "회사 이메일을 입력해 주세요",
      website: "유효한 웹사이트를 입력해 주세요",
      phoneNumber: "유효한 전화번호를 입력해 주세요",
      allFieldsRequired: "모든 필드를 입력해 주세요",
      allFieldsCorrect: "모든 필드를 올바르게 입력해 주세요",
      termsRequired: "이용 약관에 동의해 주세요"
    },
    limitation: {
      maxFileSize: "최대 파일 크기",
      maxFile: "동시에 업로드할 수 있는 최대 파일 수",
      invalidFile: "잘못된 파일 유형",
      singleFileWarning: "하나의 파일만 드롭할 수 있습니다",
      maxFileWarning: "한 번에 최대 {count}개의 파일만 드롭할 수 있습니다",
      maxFileNameLength: "파일 이름은 최대 {count}자까지 가능합니다",
      badExtension: "잘못된 파일 확장자",
      maxAudioDuration: "최대 오디오 길이 {value}분",
      maxVideoDuration: "최대 비디오 길이 {value}분",
      maxVideoResolution: "최대 비디오 해상도 {height}x{width} 또는 {width}x{height}",
      fileAlreadyUploaded: "파일 {filename}이(가) 이미 업로드되었습니다",
      unsupportedFileType: "구독에서 지원하지 않는 파일 유형입니다"
    }
  },
  page: {
    home: {
      title: "대시보드",
      subtitle: "사용량 및 제출 세부 정보 보기",
      stats: {
        monthlyLeftCredits: "남은 월 크레딧",
        monthlyUsage: "월 사용량",
        accountExpire: "계정 만료일",
        getStarted: "Sensity 최대한 활용하기",
        videoTitle: "Sensity 시작하기: 기능 및 결과"
      }
    },
    auth: {
      login: {
        title: "로그인",
        subtitle: "계정이 없으신가요?",
        signUpLink: "무료로 가입하기",
        forgotPassword: "비밀번호를 잊으셨나요?"
      },
      register: {
        title: "계정 만들기",
        subtitle: "이미 계정이 있으신가요?",
        emailDisclaimer: "귀하의 등록은 운영자의 승인을 받아야 합니다. 무료 도메인과 임시 도메인은 기본적으로 정당한 것으로 간주되지 않습니다",
        detailsPlaceholder: "세부 정보\n조직과 Sensity를 사용하여 달성하고자 하는 목표에 대해 자세히 알려주세요. 충분한 정보가 없으면 계정이 거부됩니다"
      },
      passwordRecovery: {
        title: "비밀번호 복구",
        subtitle: "돌아가기",
        message: "계정에 사용하는 이메일을 입력하세요.",
        successMessage: "비밀번호 재설정 안내를 이메일로 보냈습니다. 10분 이내에 이메일을 받지 못한 경우, 제출한 주소가 올바른지 확인해 주세요."
      },
      confirmPassword: {
        title: "비밀번호 재설정"
      },
      changePassword: {
        title: "비밀번호 변경"
      }
    },
    analytics: {
      title: "분석",
      subtitle: "플랫폼 및 API 제출에 대한 모든 업로드된 콘텐츠의 상세 인사이트 탐색"
    },
    team: {
      title: "팀 관리",
      visibility: {
        visible: "제출물이 전체 팀에 표시됨",
        notVisible: "제출물이 전체 팀에 표시되지 않음",
        tooltip: {
          visible: "팀 제출물이 모든 팀원에게 표시됩니다.",
          notVisible: "제출물이 각 팀원에게 비공개입니다."
        },
        hint: "팀 제출물을 모든 팀원에게 표시하려면 이 확인란을 선택하세요. 각 팀원의 제출물을 비공개로 유지하려면 선택하지 마세요."
      },
      addNewMemberModal: {
        title: "새 팀원 추가",
        desc: "새 팀원의 이메일 주소를 입력해 주세요.",
        existingMember: "이 사용자는 이미 팀의 멤버입니다."
      },
      upgradeModal: {
        title: "팀 관리는 프리미엄 기능입니다",
        desc: "\"내 플랜 업그레이드하기\"를 클릭하시면 계정 관리자가 다양한 옵션을 설명해 드릴 것입니다.",
        confirm: "계속하시겠습니까?",
        button: "내 플랜 업그레이드하기"
      },
      deleteTeamMemberModal: {
        title: "팀원 삭제",
        desc: "이 사용자들이 팀에서 삭제됩니다. 계속하시겠습니까?"
      },
      createTeamModal: {
        title: "새 팀 만들기",
        desc: "팀 이름을 입력해 주세요"
      },
      newUserTooltip: {
        analyst: "더 많은 사용자를 추가하려면 팀 관리자에게 문의하세요.",
        admin: "팀에 새 사용자를 추가하세요."
      }
    },
    deepfake: {
      title: "딥페이크 감지",
      subtitle: "오디오 및 비디오 딥페이크와 AI 생성 콘텐츠를 감지하기 위해 의심스러운 미디어 분석",
      button: "딥페이크 감지 시작",
      info: {
        automation: "제출을 자동화하시겠습니까?",
        checkDocs: "API 문서 확인하기",
        checkDocsOnPrem: "API 문서를 보려면 온프레미스 사용자 매뉴얼을 확인하세요.",
        contactSupport: "다음 주소로 문의하세요:"
      },
      report: {
        overall: {
          user: "사용자",
          fileName: "파일 이름",
          fileSize: "파일 크기 (KB)",
          fileHash: "파일 해시 (SHA256)",
          submissionDate: "제출 날짜",
          submissionTime: "제출 시간",
          fileResolution: "파일 해상도",
          url: "URL",
          faces: "얼굴",
          face: "얼굴",
          processingMessage: "비디오 보고서를 처리하는 중입니다. 몇 분 정도 소요될 수 있습니다.",
          selectFaceMessage: "비디오 보고서를 생성하려면 최소 하나의 얼굴을 선택해 주세요.",
          enableVoiceAnalysisMessage: "음성 분석 서비스를 활성화하려면 support@sensity.ai로 문의해 주세요",
          exportingReport: "보고서 내보내는 중",
          processingVideo: "비디오 처리 중"
        },
        explanation: {
          fakeFacesDetected: "가짜 얼굴 감지됨",
          faceExplanation: "얼굴을 선택하면 관련 경계 상자가 미디어 위에 표시됩니다.",
          faceExplanationVideo: "그리고 내보낸 비디오에서도 표시됩니다.",
          selected: "선택됨",
          viewOnVideo: "비디오에서 보기",
          exportVideoLabel: "선택한 얼굴이 포함된 비디오 내보내기",
          faceResolution: "얼굴 해상도",
          low: "낮음",
          lowResolutionInfo: "낮은 얼굴 해상도는 감지기의 정확도를 저하시킬 수 있습니다",
          viewAt: "다음에서 보기",
          pixelBasedAssessment: "픽셀 기반 평가",
          pixelBasedInfo: "이 섹션은 위의 결과를 확인하는 시각적 표현을 제공합니다",
          expandImage: "이미지 확대",
          expandVideoFrame: "비디오 프레임 확대",
          heatmapInfo: "히트맵은 모델이 픽셀 수준에서 합성 가능성이 더 높다고 감지한 영역을 보여줍니다",
          sectionDesc: "이 섹션은 위의 결과를 확인하는 시각적 표현을 제공합니다",
          pixelAndSegmentTitle: "픽셀 및 세그먼트 기반 평가",
          faceAndPixelTitle: "얼굴 정렬 및 픽셀 기반 평가",
          segmentBasedNotAvailable: "이 미디어에 대한 세그먼트 기반 평가를 사용할 수 없습니다",
          segmentBasedAssesment: "세그먼트 기반 평가",
          segmentBasedInfo: "감지기가 AI로 생성되었을 가능성이 가장 높은 이미지의 객체를 식별합니다",
          faceAlignmentAssesment: "얼굴 정렬 평가",
          faceAlignmentInfo: "이 GAN 계열로 생성된 이미지는 눈과 입의 고정된 위치로 인식될 수 있습니다",
          fakeSpeakersDetected: "가짜 화자 감지됨"
        }
      },
      form: {
        title: "미디어 업로드",
        tab: {
          file: "파일",
          url: "URL",
          urlNotAvailable: "URL 업로드는 온프레미스 버전에서 사용할 수 없습니다"
        }
      }
    },
    document: {
      title: "문서 확인",
      subtitle: "주요 정보를 추출하고 문서의 진위를 확인합니다.",
      button: "문서 확인 시작",
    },
    identity: {
      title: "신원 확인",
      subtitle: "신원 확인 및 실시간 감지를 수행합니다.",
      button: "신원 확인 시작",
    },
    developer: {
      title: "개발자",
      subtitle: "API 키 및 사용량 관리",
      apiToken: "API 토큰"
    }
  },
  name: {
    service: {
      deepfake: "딥페이크 감지",
      aiGen: "AI 생성 콘텐츠",
      faceMan: "얼굴 조작",
      document: "문서 검증",
      forensic: "포렌식 분석",
      id: "신원 확인",
      liveness: "영상 라이브니스",
      pixel: "픽셀 분석",
      voice: "음성 분석",
      faceMatch: "얼굴 매칭",
      title: "Sensity Enterprise",
      description: "AI 감지 플랫폼"
    },
    language: {
      english: "영어 (English)",
      turkish: "터키어 (Türkçe)",
      uzbek: "우즈베크어 (O'zbekcha)",
      spanish: "스페인어 (Español)",
      korean: "한국어",
      russian: "러시아어 (Русский)",
      hindi: "힌디어 (हिंदी)",
      german: "독일어 (Deutsch)",
      dutch: "네덜란드어 (Nederlands)",
      chinese: "중국어 (中文)",
      arabic: "아랍어 (العربية)",
      indonesian: "인도네시아어 (Bahasa)",
      vietnamese: "베트남어 (Tiếng Việt)",
      japanese: "일본어 (日本語)",
      italian: "이탈리아어 (Italiano)",
      french: "프랑스어 (Français)"
    }
  }
}
