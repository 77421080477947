/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Yuborish",
      register: "Ro'yxatdan o'tish",
      login: "Kirish",
      continue: "Davom etish",
      confirm: "Tasdiqlash",
      close: "Yopish",
      back: "Orqaga",
      browse: "Fayllarni ko'rish",
      save: "Saqlash",
      cancel: "Bekor qilish",
      delete: "O'chirish",
      create: "Yaratish",
      invite: "Taklif qilish",
      analyze: "Tahlil qilish",
      select: "Tanlash",
      selectAll: "Hammasini tanlash",
      clearFilters: "Filtrlarni tozalash",
      exportReport: "Hisobotni Eksport Qilish",
      exportAsPDF: "PDF Sifatida Eksport Qilish",
      exportCSV: "CSV Eksport Qilish",
      analyzeVoice: "Ovozni Tahlil Qilish",
      backToAll: "Barcha hisobotlarga qaytish",
      downloadVideoReport: "Video hisobotni yuklab olish",
      generateVideoReport: "Video hisobot yaratish",
      startDeepfake: "Deepfake aniqlashni boshlash",
      changePassword: "Parolni o'zgartirish",
      logout: "Chiqish",
      editMember: "Jamoa a'zosini tahrirlash",
      editTeam: "Jamoani tahrirlash",
      newUser: "Yangi foydalanuvchi",
      createTeam: "Yangi jamoa yaratish",
      copy: "Nusxalash",
      showThumbnails: "Eskizlarni Ko'rsatish",
      blurThumbnails: "Eskizlarni Xiralashtirish",
      showMore: "Ko'proq ko'rsatish",
      showLess: "Kamroq ko'rsatish"
    },
    loading: {
      submitting: "Yuborilmoqda",
      registering: "Ro'yxatdan o'tkazilmoqda",
      loggingIn: "Kirilmoqda",
      sending: "Yuborilmoqda",
      inProgress: "Jarayonda",
      changing: "O'zgartirilmoqda",
      processing: "Qayta ishlanmoqda",
      exporting: "Eksport qilinmoqda",
      generating: "Yaratilyapti",
      loading: "Yuklanmoqda"
    },
    placeholder: {
      email: "{'example@mail.com'}",
      password: "*********",
      enterUrl: "URL manzilini kiriting",
      enterEmail: "Elektron pochta manzilini kiriting",
      enterTeamName: "Jamoa nomini kiriting",
      select: "Iltimos, qiymatni tanlang",
      searchByNameAndEmail: "Ism yoki elektron pochta orqali qidirish"
    },
    label: {
      email: "Elektron pochta",
      password: "Parol",
      confirmPassword: "Parolni tasdiqlash",
      name: "Ism",
      firstName: "Ism",
      lastName: "Familiya",
      phoneNumber: "Telefon raqami",
      industry: "Soha",
      companyWebsite: "Kompaniya veb-sayti",
      companyEmail: "Kompaniya elektron pochtasi",
      oldPassword: "Eski parol",
      newPassword: "Yangi parol",
      teamName: "Jamoa nomi",
      teamVisibility: "Jamoa ko'rinishi",
      role: "Vazifa",
      emailAddress: "Elektron pochta manzili",
      dragAndDrop: "Media fayllarni bu yerga tashlang",
      browseFiles: "Fayllarni ko'rish",
      or: "yoki",
      hide: "Yashirish",
      show: "Ko'rsatish",
      all: "Barchasi",
      selected: "{count} Tanlangan",
      minutes: "daqiqa",
      allResults: "Barcha natijalar",
      audio: "audio",
      video: "video",
      image: "rasm",
      pdf: "pdf",
      url: "URL",
      fileName: "Fayl nomi",
      reportId: "Hisobot ID",
      searchType: "Qidiruv turi",
      searchBy: "Qidiruv bo'yicha",
      status: "Holat",
      user: "Foydalanuvchi",
      serviceType: "Xizmat turi",
      date: "Sana",
      report: "Hisobot",
      reportStatus: "Hisobot holati",
      extractedDetails: "Ajratilgan ma'lumotlar",
      settings: "Sozlamalar",
      language: "Til",
      noTeamMembers: "Jamoa a'zolari topilmadi.",
      currentUser: "Joriy foydalanuvchi",
      inviteSent: "Taklif yuborildi",
      members: "{count} A'zolar",
      timeRange: "Vaqt oralig'i",
      duration: "Davomiyligi",
      speaker: "So'zlovchi"
    }
  },
  component: {
    list: {
      title: "Natijalar ro'yxati",
      noData: "Ma'lumot topilmadi",
      noDataForEmail: "Bu elektron pochta uchun natija topilmadi:",
      searchPlaceholder: "Elektron pochta orqali qidirish...",
      resultsFound: "Topilgan natijalar: {count}",
      deleteModal: {
        title: "Tanlangan elementlarni o'chirish",
        message: "Bu hisobotlar o'chiriladi. Davom etishni xohlaysizmi?"
      },
      table: {
        header: {
          media: "Media",
          info: "Ma'lumot",
          result: "Natija",
          riskScore: "Hujjat xavf ball"
        }
      }
    },
    status: {
      inProgress: "Jarayonda",
      completed: "Yakunlandi",
      failed: "Muvaffaqiyatsiz",
      verified: "Tasdiqlangan",
      notVerified: "Tasdiqlanmagan",
      valid: "Yaroqli",
      suspicious: "Shubhali",
      serverError: "Server xatosi",
      extractionFailed: "Ajratish muvaffaqiyatsiz",
      tooltip: {
        analyzing: "Kontent tahlil qilinmoqda...",
        urlExtractionFailed: "URL kontentini ajratib olish muvaffaqiyatsiz",
        urlExtractionFailedDesc: "Berilgan URL dan kontent ajratib olinmadi.",
        fakeDetected: "Soxta kontent yoki aldash urinishi aniqlandi",
        fakeDetectedDesc: "Bu kontent soxta deb belgilangan yoki aldash urinishini o'z ichiga oladi.",
        checkCompleted: "Tekshiruv muvaffaqiyatli yakunlandi",
        checkCompletedDesc: "Hech qanday anomaliya yoki aldash urinishlari topilmadi.",
        serverErrorDesc: "Xatolik yuz berdi. Iltimos, qaytadan urinib ko'ring."
      }
    },
    table: {
      detection: {
        faceManCheck: "Yuz manipulyatsiyasi tekshiruvi",
        aiGenCheck: "AI tomonidan yaratilgan kontent tekshiruvi",
        forensicAnalysisCheck: "Kriminalistik tahlil tekshiruvi",
        voiceAnalysisCheck: "Ovoz tahlili tekshiruvi",
        confidence: "Ishonchlilik",
        modelGenerator: "Model generatori",
        manipulationType: "Manipulyatsiya turi",
        noFace: "Yuz juda kichik/Yuz yo'q",
        noVoice: "Ovoz yo'q",
        details: "Tafsilotlar",
        alert: "Ogohlantirish",
        creationDate: "Yaratilgan sana",
        modifyDate: "O'zgartirilgan sana",
        notAvailable: "Mavjud emas",
        description: {
          confidence: "Yuqori ishonchlilik kamida 50% aniqlik bilan AI manipulyatsiyasining aniq belgilarini ko'rsatadi",
          confidenceAI: "Yuqori ishonchlilik kamida 50% aniqlik bilan AI yaratishning aniq belgilarini ko'rsatadi",
          modelGenerator: "Model generatori: ko'rsatilganda, detektor qaysi model generator sifatida ishlatilganini ham aniqlashi mumkin",
          manipulationType: "Manipulyatsiya turi: ko'rsatilganda, detektor bu yuz almashtirish, yuz harakatlari, lablarni sinxronlash yoki yuz animatsiyasi ekanligini aniqlashi mumkin"
        }
      }
    },
    modal: {
      upgradePlan: {
        title: "Tarifni yangilash",
        message: "Quyidagi tugmani bosish orqali tarifni yangilash uchun hisob menejeri bilan bog'lanishingiz mumkin.",
        demoMessage: "\"Tarifni yangilashni xohlayman\" tugmasini bosganingizdan so'ng hisob menejeri siz bilan bog'lanib, turli variantlarni tushuntiradi.\nDavom etishni xohlaysizmi?",
        licenceMessage: "Litsenziyangiz muddati tugadi. Litsenziyani yangilash uchun biz bilan bog'laning.",
        monthlyLimitReached: "Oylik limit tugadi",
        trialLimitReached: "Siz sinov muddatidagi oylik yuborish limitiga ({limit}) yetib keldingiz.",
        action: "Tarifni yangilashni xohlayman"
      }
    },
    pagination: {
      itemPerPage: "Sahifadagi elementlar:",
      range: "{total} dan {from}-{to}"
    }
  },
  feedback: {
    error: {
      label: "Xato!",
      default: "Xato yuz berdi",
      server: "Server xatosi yuz berdi",
      generic: "Xatolik yuz berdi. Iltimos, keyinroq qayta urinib ko'ring.",
      unsupportedFileType: "Obunangiz bu turdagi faylni yuklashga ruxsat bermaydi",
      terms: "Iltimos, Foydalanish shartlarini qabul qiling!",
      upload: "Faylni yuklashda xatolik yuz berdi"
    },
    success: {
      label: "Muvaffaqiyatli!",
      default: "Operatsiya muvaffaqiyatli bajarildi",
      passwordChanged: "Parol muvaffaqiyatli o'zgartirildi",
      registration: "So'rovingiz tasdiqlash uchun yuborildi",
      contact: "So'rovingiz uchun rahmat, tez orada siz bilan bog'lanamiz",
      copy: "Buferga nusxalandi"
    },
    warning: {
      label: "Ogohlantirish!",
      trialDisclaimer: "Siz platformaning sinov versiyasidan foydalanmoqdasiz. Platformadan cheklovsiz foydalanish uchun premium tarifga o'ting.",
      submissionDisclaimer: "Iltimos, shaxsiy ma'lumotlarni yubormang. Sensity yuborilgan kontent uchun javobgar emas."
    },
    info: {
      agreeTo: "Men quyidagilarga roziman:",
      terms: "Foydalanish shartlari",
      privacy: "Maxfiylik siyosati"
    },
    validation: {
      required: "Bu maydon to'ldirilishi shart",
      url: "Iltimos, to'g'ri URL kiriting",
      email: "Iltimos, to'g'ri elektron pochta manzilini kiriting",
      password: "Iltimos, to'g'ri parol kiriting",
      passwordMismatch: "Parollar mos kelmadi",
      numeric: "Bu maydon raqamli bo'lishi kerak",
      workEmail: "Iltimos, ish elektron pochtasini kiriting",
      website: "Iltimos, to'g'ri veb-sayt manzilini kiriting",
      phoneNumber: "Iltimos, to'g'ri telefon raqamini kiriting",
      allFieldsRequired: "Iltimos, barcha maydonlarni to'ldiring",
      allFieldsCorrect: "Iltimos, barcha maydonlarni to'g'ri to'ldiring",
      termsRequired: "Iltimos, Foydalanish shartlarini qabul qiling"
    },
    limitation: {
      maxFileSize: "Maksimal fayl hajmi",
      maxFile: "Bir vaqtda yuklash mumkin bo'lgan maksimal fayllar soni",
      invalidFile: "Noto'g'ri fayl turi",
      singleFileWarning: "Siz faqat bitta fayl tashlashingiz mumkin",
      maxFileWarning: "Siz bir vaqtda faqat {count} ta fayl tashlashingiz mumkin",
      maxFileNameLength: "Fayl nomi maksimal {count} ta belgidan iborat bo'lishi mumkin",
      badExtension: "Noto'g'ri fayl kengaytmasi",
      maxAudioDuration: "Maksimal audio davomiyligi {value} daqiqa",
      maxVideoDuration: "Maksimal video davomiyligi {value} daqiqa",
      maxVideoResolution: "Maksimal video o'lcham {height}x{width} yoki {width}x{height}",
      fileAlreadyUploaded: "Fayl {filename} allaqachon yuklangan",
      unsupportedFileType: "Obunangiz uchun qo'llab-quvvatlanmaydigan fayl turi"
    }
  },
  page: {
    home: {
      title: "Boshqaruv paneli",
      subtitle: "Foydalanish va yuborish tafsilotlarini ko'ring",
      stats: {
        monthlyLeftCredits: "Qolgan oylik kreditlar",
        monthlyUsage: "Oylik foydalanish",
        accountExpire: "Hisob muddati tugaydi",
        getStarted: "Sensity'dan maksimal foydalaning",
        videoTitle: "Sensity'da boshlash: xususiyatlar va natijalar"
      }
    },
    auth: {
      login: {
        title: "Kirish",
        subtitle: "Hisobingiz yo'qmi?",
        signUpLink: "Bepul ro'yxatdan o'ting",
        forgotPassword: "Parolni unutdingizmi?"
      },
      register: {
        title: "Hisob yaratish",
        subtitle: "Allaqachon hisobingiz bormi?",
        emailDisclaimer: "Ro'yxatdan o'tishingiz inson operator tomonidan tasdiqlanadi. Bepul domenlar va vaqtinchalik domenlar standart holda qonuniy hisoblanmaydi",
        detailsPlaceholder: "Tafsilotlar\nIltimos, tashkilotingiz va Sensity'dan foydalanib erishmoqchi bo'lgan maqsadlaringiz haqida ko'proq ma'lumot bering. To'liq ma'lumot bo'lmasa hisobingiz rad etiladi"
      },
      passwordRecovery: {
        title: "Parolni tiklash",
        subtitle: "Orqaga qaytish",
        message: "Hisobingiz uchun foydalanayotgan elektron pochtani kiriting.",
        successMessage: "Elektron pochtangizga parolni tiklash bo'yicha ko'rsatmalar yubordik. O'n daqiqa ichida xat kelmasa, kiritilgan manzil to'g'riligini tekshiring."
      },
      confirmPassword: {
        title: "Parolni tiklash"
      },
      changePassword: {
        title: "Parolni o'zgartirish"
      }
    },
    analytics: {
      title: "Tahlillar",
      subtitle: "Platforma va API orqali yuklangan barcha kontent bo'yicha batafsil ma'lumotlarni o'rganing."
    },
    team: {
      title: "Jamoa boshqaruvi",
      visibility: {
        visible: "Yuborilgan ma'lumotlar butun jamoaga ko'rinadi",
        notVisible: "Yuborilgan ma'lumotlar butun jamoaga ko'rinmaydi",
        tooltip: {
          visible: "Jamoangiz yuborgan ma'lumotlar barcha jamoa a'zolariga ko'rinadi.",
          notVisible: "Yuborilgan ma'lumotlar har bir jamoa a'zosi uchun maxfiy."
        },
        hint: "Agar jamoa yuborgan ma'lumotlarini barcha a'zolarga ko'rinadigan qilmoqchi bo'lsangiz, iltimos, bu katakchani belgilang. Har bir a'zoning yuborilgan ma'lumotlari maxfiy bo'lishini istasangiz, belgilamang."
      },
      addNewMemberModal: {
        title: "Yangi jamoa a'zosini qo'shish",
        desc: "Iltimos, yangi jamoa a'zosining elektron pochta manzilini kiriting.",
        existingMember: "Bu foydalanuvchi allaqachon jamoa a'zosi."
      },
      upgradeModal: {
        title: "Jamoa boshqaruvi premium funksiya",
        desc: "\"Tarifni yangilashni xohlayman\" tugmasini bosganingizdan so'ng, hisob menejeri siz bilan bog'lanib, turli variantlarni tushuntiradi.",
        confirm: "Davom etishni xohlaysizmi?",
        button: "Tarifni yangilashni xohlayman"
      },
      deleteTeamMemberModal: {
        title: "Jamoa a'zosini o'chirish",
        desc: "Bu foydalanuvchilar jamoadan o'chiriladi. Davom etishni xohlaysizmi?"
      },
      createTeamModal: {
        title: "Yangi jamoa yaratish",
        desc: "Iltimos, jamoangiz uchun nom kiriting"
      },
      newUserTooltip: {
        analyst: "Iltimos, ko'proq foydalanuvchi qo'shish uchun jamoa administratori bilan bog'laning.",
        admin: "Jamoangizga yangi foydalanuvchilar qo'shing."
      }
    },
    deepfake: {
      title: "Deepfake aniqlash",
      subtitle: "Shubhali medialarni audio va video deepfake'lar hamda AI tomonidan yaratilgan kontentni aniqlash uchun tahlil qiling.",
      button: "Deepfake aniqlashni boshlash",
      info: {
        automation: "Yuborishlarni avtomatlashtirmoqchimisiz?",
        checkDocs: "API hujjatlarimizni tekshiring",
        checkDocsOnPrem: "API hujjatlarimizni ko'rish uchun mahalliy foydalanuvchi qo'llanmasini tekshiring.",
        contactSupport: "va biz bilan bog'laning"
      },
      report: {
        overall: {
          user: "Foydalanuvchi",
          fileName: "Fayl nomi",
          fileSize: "Fayl hajmi (KB)",
          fileHash: "Fayl xeshi (SHA256)",
          submissionDate: "Yuborilgan sana",
          submissionTime: "Yuborilgan vaqt",
          fileResolution: "Fayl o'lchami",
          url: "URL",
          faces: "yuzlar",
          face: "yuz",
          processingMessage: "Video hisobot qayta ishlanmoqda. Bu bir necha daqiqa olishi mumkin.",
          selectFaceMessage: "Iltimos, video hisobot yaratish uchun kamida bitta yuzni tanlang.",
          enableVoiceAnalysisMessage: "Ovozni tahlil qilish xizmatini yoqish uchun iltimos support@sensity.ai bilan bog'laning",
          exportingReport: "Hisobot eksport qilinmoqda",
          processingVideo: "Video qayta ishlanmoqda"
        },
        explanation: {
          fakeFacesDetected: "Soxta yuzlar aniqlandi",
          faceExplanation: "Yuzlarni tanlash orqali, tegishli chegaralovchi ramkalar media ustida ko'rsatiladi.",
          faceExplanationVideo: "va eksport qilingan videoda.",
          selected: "tanlangan",
          viewOnVideo: "Videoda ko'rish",
          exportVideoLabel: "Tanlangan yuzlar bilan videoni eksport qilish",
          faceResolution: "Yuz o'lchami",
          low: "past",
          lowResolutionInfo: "Past yuz o'lchami detektorning aniqligiga ta'sir qilishi mumkin",
          viewAt: "Ko'rish",
          pixelBasedAssessment: "Piksel asosidagi baholash",
          pixelBasedInfo: "Bu bo'lim yuqoridagi natijalarni tasdiqlovchi vizual ko'rinishlarni taqdim etadi",
          expandImage: "Rasmni kengaytirish",
          expandVideoFrame: "Video kadrni kengaytirish",
          heatmapInfo: "Issiqlik xaritasi model piksel darajasida sintez ehtimoli yuqoriroq deb aniqlagan joylarni ko'rsatadi",
          sectionDesc: "Bu bo'lim yuqoridagi natijalarni tasdiqlovchi vizual ko'rinishlarni taqdim etadi",
          pixelAndSegmentTitle: "Piksel va segment asosidagi baholash",
          faceAndPixelTitle: "Yuz moslashuvi va piksel asosidagi baholash",
          segmentBasedNotAvailable: "Bu media uchun segment asosidagi baholash mavjud emas",
          segmentBasedAssesment: "Segment asosidagi baholash",
          segmentBasedInfo: "Detektor rasmdagi AI tomonidan yaratilgan bo'lishi ehtimoli eng yuqori bo'lgan ob'ektlarni aniqlaydi",
          faceAlignmentAssesment: "Yuz moslashuvi baholash",
          faceAlignmentInfo: "Bu GAN oilasi tomonidan yaratilgan rasmlarni ko'z va og'izning belgilangan joylashuvidan aniqlash mumkin",
          fakeSpeakersDetected: "Soxta So'zlovchilar Aniqlandi"
        }
      },
      form: {
        title: "Media yuklash",
        tab: {
          file: "Fayl",
          url: "URL",
          urlNotAvailable: "URL orqali yuklash mahalliy versiyada mavjud emas"
        }
      }
    },
    document: {
      title: "Hujjatni tekshirish",
      subtitle: "Asosiy ma'lumotlarni chiqarish va hujjatlarning haqiqiyligini tekshirish.",
      button: "Hujjatni tekshirishni boshlash",
    },
    identity: {
      title: "Shaxsni tekshirish",
      subtitle: "Shaxsni tekshirish va jonli aniqlanishini bajaradi.",
      button: "Shaxsni tekshirishni boshlash",
    },
    developer: {
      title: "Dasturchi",
      subtitle: "API kalitlaringiz va foydalanishni boshqaring",
      apiToken: "API Kaliti"
    }
  },
  name: {
    service: {
      deepfake: "Deepfake aniqlash",
      aiGen: "AI tomonidan yaratilgan kontent",
      faceMan: "Yuz manipulyatsiyasi",
      document: "Hujjat tekshirish",
      forensic: "Kriminalistik tahlil",
      id: "Shaxsni tekshirish",
      liveness: "Video jonlilik",
      pixel: "Piksel tahlili",
      voice: "Ovoz tahlili",
      faceMatch: "Yuz moslashtirish",
      title: "Sensity Enterprise",
      idDoc: "ID Hujjat o'quvchi"
    },
    language: {
      english: "Inglizcha (English)",
      turkish: "Turkcha (Türkçe)",
      uzbek: "O'zbekcha",
      spanish: "Ispancha (Español)",
      korean: "Koreyscha (한국어)",
      russian: "Ruscha (Русский)",
      hindi: "Hindcha (हिंदी)",
      german: "Nemischa (Deutsch)",
      dutch: "Gollandcha (Nederlands)",
      chinese: "Xitoycha (中文)",
      arabic: "Arabcha (العربية)",
      indonesian: "Indonezcha (Bahasa)",
      vietnamese: "Vyetnamcha (Tiếng Việt)",
      japanese: "Yaponcha (日本語)",
      italian: "Italyancha (Italiano)",
      french: "Fransuzcha (Français)"
    }
  }
}
