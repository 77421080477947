/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Отправить",
      register: "Зарегистрироваться",
      login: "Войти",
      continue: "Продолжить",
      confirm: "Подтвердить",
      close: "Закрыть",
      back: "Назад",
      browse: "Выбрать файлы",
      save: "Сохранить",
      cancel: "Отменить",
      delete: "Удалить",
      create: "Создать",
      invite: "Пригласить",
      analyze: "Анализировать",
      selectAll: "Выбрать все",
      clearFilters: "Очистить фильтры",
      exportReport: "Экспорт Отчета",
      exportAsPDF: "Экспорт в PDF",
      exportCSV: "Экспорт CSV",
      analyzeVoice: "Анализ Голоса",
      backToAll: "Вернуться ко всем отчетам",
      downloadVideoReport: "Скачать видеоотчет",
      generateVideoReport: "Сгенерировать видеоотчет",
      startDeepfake: "Начать обнаружение дипфейков",
      changePassword: "Изменить пароль",
      logout: "Выйти",
      editMember: "Редактировать участника",
      editTeam: "Редактировать команду",
      newUser: "Новый пользователь",
      createTeam: "Создать новую команду",
      select: "Выбрать",
      copy: "Копировать",
      showThumbnails: "Показать Миниатюры",
      blurThumbnails: "Размыть Миниатюры",
      showMore: "Показать больше",
      showLess: "Показать меньше"
    },
    loading: {
      submitting: "Отправка",
      registering: "Регистрация",
      loggingIn: "Вход в систему",
      sending: "Отправка",
      inProgress: "В процессе",
      changing: "Изменение",
      processing: "Обработка",
      exporting: "Экспорт",
      generating: "Генерация",
      loading: "Загрузка"
    },
    placeholder: {
      email: "{'example@mail.com'}",
      password: "*********",
      enterUrl: "Введите URL",
      enterEmail: "Введите email адрес",
      enterTeamName: "Введите название команды",
      select: "Пожалуйста, выберите значение",
      searchByNameAndEmail: "Поиск по имени или email"
    },
    label: {
      email: "Email",
      password: "Пароль",
      confirmPassword: "Подтверждение пароля",
      name: "Имя",
      firstName: "Имя",
      lastName: "Фамилия",
      phoneNumber: "Номер телефона",
      industry: "Отрасль",
      companyWebsite: "Сайт компании",
      companyEmail: "Email компании",
      oldPassword: "Старый пароль",
      newPassword: "Новый пароль",
      teamName: "Название команды",
      teamVisibility: "Видимость команды",
      role: "Роль",
      emailAddress: "Email адрес",
      dragAndDrop: "Перетащите медиафайлы сюда",
      browseFiles: "Выбрать файлы",
      or: "или",
      hide: "Скрыть",
      show: "Показать",
      all: "Все",
      selected: "Выбрано: {count}",
      minutes: "мин",
      allResults: "Все результаты",
      audio: "аудио",
      video: "видео",
      image: "изображение",
      pdf: "pdf",
      url: "URL",
      fileName: "Имя файла",
      reportId: "ID отчета",
      searchType: "Тип поиска",
      searchBy: "Искать по",
      status: "Статус",
      user: "Пользователь",
      serviceType: "Тип сервиса",
      date: "Дата",
      report: "Отчет",
      reportStatus: "Статус отчета",
      extractedDetails: "Извлеченные данные",
      settings: "Настройки",
      language: "Язык",
      noTeamMembers: "Участники команды не найдены.",
      currentUser: "Текущий пользователь",
      inviteSent: "Приглашение отправлено",
      members: "{count} Участников",
      timeRange: "Временной диапазон",
      duration: "Длительность",
      speaker: "Говорящий"
    }
  },
  component: {
    list: {
      title: "Список результатов",
      noData: "Данные не найдены",
      noDataForEmail: "Результаты для этого email не найдены:",
      searchPlaceholder: "Поиск по email...",
      resultsFound: "Найдено результатов: {count}",
      deleteModal: {
        title: "Удалить выбранные элементы",
        message: "Эти отчеты будут удалены. Вы уверены, что хотите продолжить?"
      },
      table: {
        header: {
          media: "Медиа",
          info: "Информация",
          result: "Результат",
          riskScore: "Оценка риска документа"
        }
      }
    },
    status: {
      inProgress: "В процессе",
      completed: "Завершено",
      failed: "Не удалось",
      verified: "Проверено",
      notVerified: "Не проверено",
      valid: "Действительно",
      suspicious: "Подозрительно",
      serverError: "Ошибка сервера",
      extractionFailed: "Ошибка извлечения",
      tooltip: {
        analyzing: "Анализ контента...",
        urlExtractionFailed: "Не удалось извлечь контент URL",
        urlExtractionFailedDesc: "Не удалось извлечь контент из предоставленного URL.",
        fakeDetected: "Обнаружен поддельный контент или попытка подмены",
        fakeDetectedDesc: "Этот контент отмечен как поддельный или содержит попытку подмены.",
        checkCompleted: "Проверка успешно завершена",
        checkCompletedDesc: "Аномалии или попытки подмены не обнаружены.",
        serverErrorDesc: "Что-то пошло не так. Пожалуйста, попробуйте снова."
      }
    },
    table: {
      detection: {
        faceManCheck: "Проверка манипуляции с лицом",
        aiGenCheck: "Проверка ИИ-сгенерированного контента",
        forensicAnalysisCheck: "Проверка криминалистического анализа",
        voiceAnalysisCheck: "Проверка анализа голоса",
        confidence: "Уверенность",
        modelGenerator: "Модель-генератор",
        manipulationType: "Тип манипуляции",
        noFace: "Лицо слишком маленькое/Нет лица",
        noVoice: "Нет голоса",
        details: "Подробности",
        alert: "Предупреждение",
        creationDate: "Дата создания",
        modifyDate: "Дата изменения",
        notAvailable: "Н/Д",
        description: {
          confidence: "Высокая уверенность указывает на явные признаки манипуляции ИИ с достоверностью не менее 50%",
          confidenceAI: "Высокая уверенность указывает на явные признаки генерации ИИ с достоверностью не менее 50%",
          modelGenerator: "Модель-генератор: когда показано, детектор также может определить, какая модель использовалась в качестве генератора",
          manipulationType: "Тип манипуляции: когда показано, детектор также может определить, является ли это подменой лица, реанимацией лица, синхронизацией губ или анимацией лица"
        }
      }
    },
    pagination: {
      itemPerPage: "Элементов на странице:",
      range: "{from}-{to} из {total}",
    },
    modal: {
      upgradePlan: {
        title: "Обновить План",
        message: "Вы можете связаться с вашим менеджером для обновления плана, нажав на кнопку ниже.",
        demoMessage: "Нажав \"Я хочу обновить план\", с вами свяжется ваш менеджер, который объяснит различные варианты.\nХотите продолжить?",
        licenceMessage: "Срок действия вашей лицензии истек. Пожалуйста, свяжитесь с нами для продления лицензии.",
        monthlyLimitReached: "Достигнут Месячный Лимит",
        trialLimitReached: "Вы достигли месячного лимита пробных отправок { limit }.",
        action: "Я хочу обновить план",
      },
    }
  },
  feedback: {
    error: {
      label: "Ошибка!",
      default: "Произошла ошибка",
      server: "Произошла ошибка сервера",
      generic: "Что-то пошло не так. Пожалуйста, повторите попытку позже.",
      unsupportedFileType: "Ваша подписка не позволяет загружать этот тип файла",
      terms: "Пожалуйста, примите Условия использования!",
      upload: "Не удалось загрузить файл"
    },
    success: {
      label: "Успешно!",
      default: "Операция выполнена успешно",
      passwordChanged: "Пароль успешно изменен",
      registration: "Ваш запрос отправлен на рассмотрение",
      contact: "Спасибо за ваш запрос, мы свяжемся с вами в ближайшее время",
      copy: "Скопировано в буфер обмена"
    },
    warning: {
      label: "Внимание!",
      trialDisclaimer: "Вы используете пробную версию платформы. Обновите до премиум-плана, чтобы использовать платформу без ограничений.",
      submissionDisclaimer: "Пожалуйста, не отправляйте личную информацию. Sensity не несет ответственности за содержание вашего материала."
    },
    info: {
      agreeTo: "Я соглашаюсь с:",
      terms: "Условия использования",
      privacy: "Политика конфиденциальности"
    },
    validation: {
      required: "Это поле обязательно",
      url: "Пожалуйста, введите действительный URL",
      email: "Пожалуйста, введите действительный email",
      password: "Пожалуйста, введите действительный пароль",
      passwordMismatch: "Пароли не совпадают",
      numeric: "Это поле должно быть числовым",
      workEmail: "Пожалуйста, укажите рабочий email",
      website: "Пожалуйста, введите действительный веб-сайт",
      phoneNumber: "Пожалуйста, введите действительный номер телефона",
      allFieldsRequired: "Пожалуйста, заполните все поля",
      allFieldsCorrect: "Пожалуйста, заполните все поля правильно",
      termsRequired: "Пожалуйста, примите Условия использования"
    },
    limitation: {
      maxFileSize: "Максимальный размер файла",
      maxFile: "Максимальное количество файлов для одновременной загрузки",
      invalidFile: "Недопустимый тип файла",
      singleFileWarning: "Можно перетащить только один файл",
      maxFileWarning: "Можно перетащить только {count} файлов одновременно",
      maxFileNameLength: "Максимальная длина имени файла {count} символов",
      badExtension: "Неверное расширение файла",
      maxAudioDuration: "Максимальная длительность аудио {value} минут",
      maxVideoDuration: "Максимальная длительность видео {value} минут",
      maxVideoResolution: "Максимальное разрешение видео {height}x{width} или {width}x{height}",
      fileAlreadyUploaded: "Файл {filename} уже загружен",
      unsupportedFileType: "Неподдерживаемый тип файла для вашей подписки"
    }
  },
  page: {
    home: {
      title: "Панель управления",
      subtitle: "Просмотр деталей использования и отправок",
      stats: {
        monthlyLeftCredits: "Осталось месячных кредитов",
        monthlyUsage: "Месячное использование",
        accountExpire: "Срок действия аккаунта истекает",
        getStarted: "Максимально используйте Sensity",
        videoTitle: "Начало работы с Sensity: функции и результаты"
      }
    },
    auth: {
      login: {
        title: "Вход",
        subtitle: "Нет аккаунта?",
        signUpLink: "Зарегистрироваться бесплатно",
        forgotPassword: "Забыли пароль?"
      },
      register: {
        title: "Создать аккаунт",
        subtitle: "Уже есть аккаунт?",
        emailDisclaimer: "Ваша регистрация будет одобрена оператором. Бесплатные и временные домены по умолчанию не считаются легитимными",
        detailsPlaceholder: "Подробности\nПожалуйста, расскажите подробнее о вашей организации и целях, которых вы хотите достичь с помощью Sensity. Без исчерпывающей информации ваша учетная запись будет отклонена"
      },
      passwordRecovery: {
        title: "Восстановление пароля",
        subtitle: "Вернуться к",
        message: "Введите email, который вы используете для своего аккаунта.",
        successMessage: "Мы отправили инструкции по сбросу пароля на ваш email. Если вы не получили письмо в течение десяти минут, проверьте правильность указанного адреса."
      },
      confirmPassword: {
        title: "Сброс пароля"
      },
      changePassword: {
        title: "Изменить пароль"
      }
    },
    analytics: {
      title: "Аналитика",
      subtitle: "Исследуйте подробную аналитику всего загруженного контента как для платформы, так и для API-отправок."
    },
    team: {
      title: "Управление командой",
      visibility: {
        visible: "Отправки видны всей команде",
        notVisible: "Отправки не видны всей команде",
        tooltip: {
          visible: "Отправки вашей команды видны всем участникам команды.",
          notVisible: "Ваши отправки являются приватными для каждого участника команды."
        },
        hint: "Если вы хотите сделать отправки команды видимыми для всех участников команды, отметьте этот флажок. Не отмечайте его, если хотите, чтобы отправки каждого участника команды были приватными."
      },
      addNewMemberModal: {
        title: "Добавить нового участника команды",
        desc: "Пожалуйста, укажите email адрес нового участника команды.",
        existingMember: "Этот пользователь уже является участником команды."
      },
      upgradeModal: {
        title: "Управление командой - это премиум-функция",
        desc: "При нажатии на \"Я хочу обновить свой план\" с вами свяжется ваш менеджер по работе с клиентами, который объяснит различные варианты.",
        confirm: "Хотите продолжить?",
        button: "Я хочу обновить свой план"
      },
      deleteTeamMemberModal: {
        title: "Удалить участника команды",
        desc: "Эти пользователи будут удалены из команды. Вы уверены, что хотите продолжить?"
      },
      createTeamModal: {
        title: "Создать новую команду",
        desc: "Пожалуйста, укажите название для вашей команды"
      },
      newUserTooltip: {
        analyst: "Пожалуйста, свяжитесь с администратором команды, чтобы добавить больше пользователей.",
        admin: "Добавьте новых пользователей в вашу команду."
      }
    },
    deepfake: {
      title: "Обнаружение дипфейков",
      subtitle: "Анализируйте подозрительные медиафайлы для обнаружения аудио и видео дипфейков и контента, сгенерированного ИИ.",
      button: "Начать обнаружение дипфейков",
      info: {
        automation: "Хотите автоматизировать отправки?",
        checkDocs: "Ознакомьтесь с нашей API-документацией",
        checkDocsOnPrem: "Ознакомьтесь с нашим руководством по локальному развертыванию, чтобы просмотреть нашу API-документацию.",
        contactSupport: "и свяжитесь с нами по адресу"
      },
      report: {
        overall: {
          user: "Пользователь",
          fileName: "Имя файла",
          fileSize: "Размер файла (КБ)",
          fileHash: "Хеш файла (SHA256)",
          submissionDate: "Дата отправки",
          submissionTime: "Время отправки",
          fileResolution: "Разрешение файла",
          url: "URL",
          faces: "лиц",
          face: "лицо",
          processingMessage: "Обработка видеоотчета. Это может занять несколько минут.",
          selectFaceMessage: "Пожалуйста, выберите хотя бы одно лицо для создания видеоотчета.",
          enableVoiceAnalysisMessage: "Чтобы включить сервис анализа голоса, пожалуйста, свяжитесь с support@sensity.ai",
          exportingReport: "Экспорт отчета",
          processingVideo: "Обработка видео"
        },
        explanation: {
          fakeFacesDetected: "Обнаружены поддельные лица",
          faceExplanation: "При выборе лиц соответствующие ограничивающие рамки будут отображены поверх медиафайла.",
          faceExplanationVideo: "и в экспортированном видео.",
          selected: "выбрано",
          viewOnVideo: "Просмотр на видео",
          exportVideoLabel: "Экспортировать видео с выбранными лицами",
          faceResolution: "Разрешение лица",
          low: "низкое",
          lowResolutionInfo: "Низкое разрешение лица может снизить точность детектора",
          viewAt: "Просмотр в",
          pixelBasedAssessment: "Попиксельная оценка",
          pixelBasedInfo: "Этот раздел предоставляет визуальные представления, подтверждающие результаты выше",
          expandImage: "Увеличить изображение",
          expandVideoFrame: "Увеличить кадр видео",
          heatmapInfo: "Тепловая карта показывает области, где модель обнаружила более высокую вероятность синтеза на уровне пикселей",
          sectionDesc: "Этот раздел предоставляет визуальные представления, подтверждающие результаты выше",
          pixelAndSegmentTitle: "Попиксельная и посегментная оценка",
          faceAndPixelTitle: "Оценка выравнивания лица и попиксельная оценка",
          segmentBasedNotAvailable: "Посегментная оценка недоступна для этого медиафайла",
          segmentBasedAssesment: "Посегментная оценка",
          segmentBasedInfo: "Детектор определяет объекты на изображении, которые с наибольшей вероятностью сгенерированы ИИ",
          faceAlignmentAssesment: "Оценка выравнивания лица",
          faceAlignmentInfo: "Изображения, сгенерированные этим семейством GAN, можно распознать по фиксированному положению глаз и рта",
          fakeSpeakersDetected: "Обнаружены Поддельные Голоса"
        }
      },
      form: {
        title: "Загрузить медиа",
        tab: {
          file: "Файл",
          url: "URL",
          urlNotAvailable: "Загрузка по URL недоступна в локальной версии"
        }
      }
    },
    document: {
      title: "Проверка документов",
      subtitle: "Извлечение ключевой информации и подтверждение подлинности документов.",
      button: "Начать проверку документов",
    },
    identity: {
      title: "Проверка личности",
      subtitle: "Выполняет проверку личности и определение живости.",
      button: "Начать проверку личности",
    },
    developer: {
      title: "Разработчик",
      subtitle: "Управляйте своими API-ключами и использованием",
      apiToken: "API-ключ"
    }
  },
  name: {
    service: {
      deepfake: "Обнаружение дипфейков",
      aiGen: "Контент, сгенерированный ИИ",
      faceMan: "Манипуляция с лицом",
      document: "Проверка документов",
      forensic: "Криминалистический анализ",
      id: "Проверка личности",
      liveness: "Проверка подлинности видео",
      pixel: "Анализ пикселей",
      voice: "Анализ голоса",
      faceMatch: "Сопоставление лиц",
      title: "Sensity Enterprise",
      description: "Платформа обнаружения ИИ"
    },
    language: {
      english: "Английский (English)",
      turkish: "Турецкий (Türkçe)",
      uzbek: "Узбекский (O'zbekcha)",
      spanish: "Испанский (Español)",
      korean: "Корейский (한국어)",
      russian: "Русский",
      hindi: "Хинди (हिंदी)",
      german: "Немецкий (Deutsch)",
      dutch: "Нидерландский (Nederlands)",
      chinese: "Китайский (中文)",
      arabic: "Арабский (العربية)",
      indonesian: "Индонезийский (Bahasa)",
      vietnamese: "Вьетнамский (Tiếng Việt)",
      japanese: "Японский (日本語)",
      italian: "Итальянский (Italiano)",
      french: "Французский (Français)"
    }
  }
}
