<script>
import { validate } from "vee-validate";
  import {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  } from "@headlessui/vue";

export default {
  name: "SnsSelect",
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  props: {
    modelValue: {
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Please select a value",
    },
    icon: {
      type: String,
      default: "",
    },
    validationRule: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "isValid"],
  data() {
    return {
      validatorError: null,
    };
  },
  // todo refactor validation
  computed: {
    selectStyle() {
      let selectClass = `select-${this.size}`;

      if (this.modelValue && this.modelValue[this.itemText]) {
        selectClass += " text-base-content";
      } else {
        selectClass += " text-neutral";
      }

      return selectClass;
    }
  },
  methods: {
    validateField() {
      if (this.validationRule !== "" || this.required) {
        validate(this.modelValue, `${this.required ? "required|" : ""}${this.validationRule}`).then(
          (result) => {
            const isValid = result.valid && this.modelValue !== "default";

            if (isValid) {
              this.validatorError = null;
            } else if (this.modelValue === "default") {
              this.validatorError = this.$t("common.placeholder.select");
            } else {
              [this.validatorError] = result.errors;
            }

            this.$emit("isValid", isValid);
          }
        );
      }
    },
    updateModelValue(value) {
      this.$emit("update:modelValue", value);
      this.validateField();
    },
  },
};
</script>

<template>
  <div id="sns-select">
    <Listbox
      :model-value="modelValue"
      @update:model-value="updateModelValue"
      @blur="validateField"
    >
      <div class="relative">
        <ListboxButton
          class="
            select select-bordered w-full
            focus:outline-none focus:border-primary
            items-center text-base truncate
          "
          :class="selectStyle"
        >
          <i v-if="icon !== ''" class="mr-2" :class="icon" />

          <div class="text-start">
            <div class="flex select-label">
              {{ label }}
            </div>

            <div class="flex justify-between">
              <template v-if="loading">
                <div class="basis-full">
                  <div class="flex">
                    <div class="loading loading-spinner loading-xs mr-2" />
                    {{ $t("common.loading.loading") }}
                  </div>
                </div>
              </template>

              <template v-else>
                {{ modelValue[itemText] || (placeholder || $t("common.placeholder.select")) }}
              </template>
            </div>
          </div>
        </ListboxButton>

        <ListboxOptions
          class="absolute z-10 mt-1 min-w-36 w-full max-h-96 overflow-auto rounded
          bg-base-100 py-1 text-base shadow-lg ring-1 ring-accent focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="item in items"
            :key="item[itemValue]"
            v-slot="{ active, selected }"
            :value="item"
            :disabled="item.disabled"
            as="template"
          >
            <li
              :class="[
                active ? 'bg-primary !text-white' : 'text-base-content',
                'relative cursor-default select-none py-2 px-4',
              ]"
            >
              <div class="flex">
                <div class="basis-1/12 text-primary text-center mr-2" :class="active ? '!text-white' : ''">
                  <i class="bi-check" :class="selected ? 'block' : 'invisible'"/>
                </div>

                <div class="basis-11/12" :class="[selected ? 'font-medium' : 'font-normal','block truncate',]">
                  {{ item[itemText] }}
                </div>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </div>
    </Listbox>

    <div
      v-if="validatorError"
      class="form-text error-message"
    >
      {{ validatorError }}
    </div>
  </div>
</template>

<style lang="scss">
#sns-select {
  font-size: 1rem;

  .error-message {
    @apply text-error;
    animation: shake-vertical 100ms ease-in-out 3;
  }

  .select-label {
    @apply text-neutral text-sm;
  }

  @keyframes shake-vertical {
    0% {
      transform: translate(1px, 0);
    }
    20% {
      transform: translate(-3px, 0);
    }
    30% {
      transform: translate(3px, 0);
    }
    40% {
      transform: translate(1px, 0);
    }
    60% {
      transform: translate(-3px, 0);
    }
    70% {
      transform: translate(3px, 0);
    }
    100% {
      transform: translate(1px, 0);
    }
  }
}
</style>
