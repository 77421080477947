/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Gửi",
      register: "Đăng ký",
      login: "Đăng nhập",
      continue: "Tiếp tục",
      confirm: "Xác nhận",
      close: "Đóng",
      back: "Quay lại",
      browse: "Duyệt tệp",
      save: "Lưu",
      cancel: "Hủy",
      delete: "Xóa",
      create: "Tạo mới",
      invite: "Mời",
      analyze: "Phân tích",
      select: "Chọn",
      selectAll: "Chọn tất cả",
      clearFilters: "Xóa bộ lọc",
      exportReport: "Xuất Báo Cáo",
      exportAsPDF: "Xuất ra PDF",
      exportCSV: "Xuất CSV",
      analyzeVoice: "Phân Tích Giọng Nói",
      backToAll: "Quay lại tất cả báo cáo",
      downloadVideoReport: "Tải xuống báo cáo video",
      generateVideoReport: "Tạo báo cáo video",
      startDeepfake: "Bắt đầu phát hiện Deepfake",
      changePassword: "Đổi mật khẩu",
      logout: "Đăng xuất",
      editMember: "Chỉnh sửa thành viên nhóm",
      editTeam: "Chỉnh sửa nhóm",
      newUser: "Người dùng mới",
      createTeam: "Tạo nhóm mới",
      copy: "Sao chép",
      showThumbnails: "Hiển thị Hình thu nhỏ",
      blurThumbnails: "Làm mờ Hình thu nhỏ",
      showMore: "Xem thêm",
      showLess: "Thu gọn",
    },
    loading: {
      submitting: "Đang gửi",
      registering: "Đang đăng ký",
      loggingIn: "Đang đăng nhập",
      sending: "Đang gửi",
      inProgress: "Đang xử lý",
      changing: "Đang thay đổi",
      processing: "Đang xử lý",
      exporting: "Đang xuất",
      generating: "Đang tạo",
      loading: "Đang tải"
    },
    placeholder: {
      email: "{'example@mail.com'}",
      password: "*********",
      enterUrl: "Nhập URL",
      enterEmail: "Nhập địa chỉ email",
      enterTeamName: "Nhập tên nhóm",
      select: "Vui lòng chọn một giá trị",
      searchByNameAndEmail: "Tìm kiếm theo tên hoặc địa chỉ email",
    },
    label: {
      email: "Email",
      password: "Mật khẩu",
      confirmPassword: "Xác nhận mật khẩu",
      name: "Tên",
      firstName: "Tên",
      lastName: "Họ",
      phoneNumber: "Số điện thoại",
      industry: "Ngành nghề",
      companyWebsite: "Website công ty",
      companyEmail: "Email công ty",
      oldPassword: "Mật khẩu cũ",
      newPassword: "Mật khẩu mới",
      teamName: "Tên nhóm",
      teamVisibility: "Hiển thị nhóm",
      role: "Vai trò",
      emailAddress: "Địa chỉ email",
      dragAndDrop: "Kéo và thả tệp phương tiện vào đây",
      browseFiles: "Duyệt tệp",
      or: "hoặc",
      hide: "Ẩn",
      show: "Hiện",
      all: "Tất cả",
      selected: "{count} Đã chọn",
      minutes: "phút",
      allResults: "Tất cả kết quả",
      audio: "âm thanh",
      video: "video",
      image: "hình ảnh",
      pdf: "pdf",
      url: "URL",
      fileName: "Tên tệp",
      reportId: "ID báo cáo",
      searchType: "Loại tìm kiếm",
      searchBy: "Tìm kiếm theo",
      status: "Trạng thái",
      user: "Người dùng",
      serviceType: "Loại dịch vụ",
      date: "Ngày",
      report: "Báo cáo",
      reportStatus: "Trạng thái báo cáo",
      extractedDetails: "Chi tiết trích xuất",
      settings: "Cài đặt",
      language: "Ngôn ngữ",
      noTeamMembers: "Không tìm thấy thành viên nhóm.",
      currentUser: "Người dùng hiện tại",
      inviteSent: "Đã gửi lời mời",
      members: "{count} Thành viên",
      timeRange: "Khoảng Thời Gian",
      duration: "Thời Lượng",
      speaker: "Người nói"
    }
  },
  component: {
    list: {
      title: "Danh sách kết quả",
      noData: "Không tìm thấy dữ liệu",
      noDataForEmail: "Không tìm thấy kết quả cho email này:",
      searchPlaceholder: "Tìm kiếm email...",
      resultsFound: "Kết quả tìm thấy: {count}",
      deleteModal: {
        title: "Xóa các mục đã chọn",
        message: "Những báo cáo này sẽ bị xóa. Bạn có chắc chắn muốn tiếp tục?",
      },
      table: {
        header: {
          media: "Phương tiện",
          info: "Thông tin",
          result: "Kết quả",
          riskScore: "Điểm rủi ro tài liệu"
        },
      },
    },
    status: {
      inProgress: "Đang xử lý",
      completed: "Hoàn thành",
      failed: "Thất bại",
      verified: "Đã xác minh",
      notVerified: "Chưa xác minh",
      valid: "Hợp lệ",
      suspicious: "Đáng ngờ",
      serverError: "Lỗi máy chủ",
      extractionFailed: "Trích xuất thất bại",
      tooltip: {
        analyzing: "Đang phân tích nội dung...",
        urlExtractionFailed: "Trích xuất nội dung URL thất bại",
        urlExtractionFailedDesc: "Không thể trích xuất nội dung từ URL đã cung cấp.",
        fakeDetected: "Phát hiện nội dung giả mạo hoặc nỗ lực giả mạo",
        fakeDetectedDesc: "Nội dung này đã được đánh dấu là giả mạo hoặc chứa nỗ lực giả mạo.",
        checkCompleted: "Kiểm tra hoàn thành thành công",
        checkCompletedDesc: "Không tìm thấy bất thường hoặc nỗ lực giả mạo.",
        serverErrorDesc: "Đã xảy ra lỗi. Vui lòng thử lại sau."
      }
    },
    table: {
      detection: {
        faceManCheck: "Kiểm tra thao tác khuôn mặt",
        aiGenCheck: "Kiểm tra nội dung tạo bởi AI",
        forensicAnalysisCheck: "Kiểm tra phân tích pháp y",
        voiceAnalysisCheck: "Kiểm tra phân tích giọng nói",
        confidence: "Độ tin cậy",
        modelGenerator: "Mô hình tạo",
        manipulationType: "Loại thao tác",
        noFace: "Khuôn mặt quá nhỏ/Không có khuôn mặt",
        noVoice: "Không có giọng nói",
        details: "Chi tiết",
        alert: "Cảnh báo",
        creationDate: "Ngày tạo",
        modifyDate: "Ngày sửa đổi",
        notAvailable: "Không có",
        description: {
          confidence: "Độ tin cậy cao cho thấy dấu hiệu rõ ràng của thao tác AI, với độ chắc chắn ít nhất 50%",
          confidenceAI: "Độ tin cậy cao cho thấy dấu hiệu rõ ràng của việc tạo bởi AI, với độ chắc chắn ít nhất 50%",
          modelGenerator: "Mô hình tạo: khi hiển thị, bộ phát hiện cũng có thể xác định mô hình nào đã được sử dụng làm công cụ tạo",
          manipulationType: "Loại thao tác: khi hiển thị, bộ phát hiện cũng có thể xác định đây là hoán đổi khuôn mặt, tái tạo khuôn mặt, đồng bộ môi hay hoạt ảnh khuôn mặt"
        }
      }
    },
    pagination: {
      itemPerPage: "Số mục mỗi trang:",
      range: "{from}-{to} trong tổng số {total}",
    },
    modal: {
      upgradePlan: {
        title: "Nâng cấp gói",
        message: "Bạn có thể liên hệ với người quản lý tài khoản để nâng cấp gói bằng cách nhấp vào nút bên dưới.",
        demoMessage: "Bằng cách nhấp vào \"Tôi muốn nâng cấp gói\", bạn sẽ được người quản lý tài khoản liên hệ để giải thích về các tùy chọn khác nhau.\nBạn có muốn tiếp tục không?",
        licenceMessage: "Giấy phép của bạn đã hết hạn. Vui lòng liên hệ với chúng tôi để gia hạn giấy phép.",
        monthlyLimitReached: "Đã đạt giới hạn hàng tháng",
        trialLimitReached: "Bạn đã đạt đến giới hạn gửi dùng thử hàng tháng là {limit}.",
        action: "Tôi muốn nâng cấp gói",
      },
    }
  },
  feedback: {
    error: {
      label: "Lỗi!",
      default: "Đã xảy ra lỗi",
      server: "Đã xảy ra lỗi máy chủ",
      generic: "Đã xảy ra lỗi. Vui lòng thử lại sau.",
      unsupportedFileType: "Gói đăng ký của bạn không cho phép tải lên loại tệp này",
      terms: "Vui lòng chấp nhận Điều khoản và Điều kiện!",
      upload: "Không thể tải lên tệp",
    },
    success: {
      label: "Thành công!",
      default: "Thao tác thành công",
      passwordChanged: "Đổi mật khẩu thành công",
      registration: "Yêu cầu của bạn đã được gửi để phê duyệt",
      contact: "Cảm ơn yêu cầu của bạn, chúng tôi sẽ liên hệ sớm",
      copy: "Đã sao chép vào clipboard",
    },
    warning: {
      label: "Cảnh báo!",
      trialDisclaimer: "Bạn đang sử dụng phiên bản dùng thử của nền tảng. Nâng cấp lên gói cao cấp để sử dụng nền tảng không giới hạn.",
      submissionDisclaimer: "Vui lòng không gửi thông tin cá nhân. Sensity không chịu trách nhiệm về nội dung bạn gửi."
    },
    info: {
      agreeTo: "Tôi đồng ý với những điều sau:",
      terms: "Điều khoản và Điều kiện",
      privacy: "Chính sách Bảo mật",
    },
    validation: {
      required: "Trường này là bắt buộc",
      url: "Vui lòng nhập URL hợp lệ",
      email: "Vui lòng nhập email hợp lệ",
      password: "Vui lòng nhập mật khẩu hợp lệ",
      passwordMismatch: "Mật khẩu không khớp",
      numeric: "Trường này phải là số",
      workEmail: "Vui lòng cung cấp email công ty",
      website: "Vui lòng nhập website hợp lệ",
      phoneNumber: "Vui lòng nhập số điện thoại hợp lệ",
      allFieldsRequired: "Vui lòng điền tất cả các trường!",
      allFieldsCorrect: "Vui lòng điền tất cả các trường chính xác!",
      termsRequired: "Vui lòng chấp nhận Điều khoản và Điều kiện!"
    },
    limitation: {
      maxFileSize: "Kích thước tệp tối đa",
      maxFile: "Số lượng tệp tối đa có thể tải lên cùng lúc",
      invalidFile: "Loại tệp không hợp lệ",
      singleFileWarning: "Bạn chỉ có thể thả một tệp",
      maxFileWarning: "Bạn chỉ có thể thả {count} tệp cùng lúc",
      maxFileNameLength: "Tên tệp tối đa có thể là {count} ký tự",
      badExtension: "Phần mở rộng tệp không hợp lệ",
      maxAudioDuration: "Thời lượng âm thanh tối đa {value} phút",
      maxVideoDuration: "Thời lượng video tối đa {value} phút",
      maxVideoResolution: "Độ phân giải video tối đa {height}x{width} hoặc {width}x{height}",
      fileAlreadyUploaded: "Tệp {filename} đã được tải lên",
      unsupportedFileType: "Loại tệp không được hỗ trợ cho gói đăng ký của bạn"
    }
  },
  page: {
    home: {
      title: "Bảng điều khiển",
      subtitle: "Xem chi tiết sử dụng và gửi",
      stats: {
        monthlyLeftCredits: "Tín dụng còn lại trong tháng",
        monthlyUsage: "Sử dụng hàng tháng",
        accountExpire: "Tài khoản sẽ hết hạn vào",
        getStarted: "Tận dụng tối đa Sensity",
        videoTitle: "Bắt đầu với Sensity: tính năng và kết quả"
      }
    },
    auth: {
      login: {
        title: "Đăng nhập",
        subtitle: "Chưa có tài khoản?",
        signUpLink: "Đăng ký miễn phí",
        forgotPassword: "Quên mật khẩu?"
      },
      register: {
        title: "Tạo tài khoản",
        subtitle: "Đã có tài khoản?",
        emailDisclaimer: "Đăng ký của bạn sẽ được phê duyệt bởi người vận hành. Các tên miền miễn phí và tạm thời sẽ không được coi là hợp lệ theo mặc định",
        detailsPlaceholder: "Chi tiết \nVui lòng cho chúng tôi biết thêm về tổ chức của bạn và những mục tiêu bạn muốn đạt được khi sử dụng Sensity. Không có thông tin đầy đủ, tài khoản của bạn sẽ bị từ chối"
      },
      passwordRecovery: {
        title: "Khôi phục mật khẩu",
        subtitle: "Quay lại",
        message: "Nhập email bạn đang sử dụng cho tài khoản.",
        successMessage: "Chúng tôi đã gửi hướng dẫn đặt lại mật khẩu đến địa chỉ email của bạn. Nếu không nhận được email trong vòng mười phút, hãy kiểm tra lại địa chỉ đã nhập."
      },
      confirmPassword: {
        title: "Đặt lại mật khẩu"
      },
      changePassword: {
        title: "Đổi mật khẩu"
      }
    },
    analytics: {
      title: "Phân tích",
      subtitle: "Khám phá thông tin chi tiết về tất cả nội dung đã tải lên cho cả nền tảng và gửi qua API."
    },
    team: {
      title: "Quản lý nhóm",
      visibility: {
        visible: "Các nội dung gửi hiển thị với toàn bộ nhóm",
        notVisible: "Các nội dung gửi không hiển thị với toàn bộ nhóm",
        tooltip: {
          visible: "Nội dung gửi của nhóm bạn hiển thị với tất cả thành viên nhóm.",
          notVisible: "Nội dung gửi của bạn riêng tư đối với từng thành viên nhóm."
        },
        hint: "Nếu bạn muốn cho phép nội dung gửi của nhóm hiển thị với tất cả thành viên nhóm, vui lòng đánh dấu vào ô này. Không đánh dấu nếu bạn muốn nội dung gửi của mỗi thành viên nhóm được giữ riêng tư."
      },
      addNewMemberModal: {
        title: "Thêm thành viên nhóm mới",
        desc: "Vui lòng cung cấp địa chỉ email của thành viên nhóm mới.",
        existingMember: "Người dùng này đã là thành viên của nhóm."
      },
      upgradeModal: {
        title: "Quản lý nhóm là tính năng cao cấp",
        desc: "Bằng cách nhấp vào \"Tôi muốn nâng cấp gói\", bạn sẽ được người quản lý tài khoản liên hệ để giải thích về các tùy chọn khác nhau.",
        confirm: "Bạn có muốn tiếp tục?",
        button: "Tôi muốn nâng cấp gói"
      },
      deleteTeamMemberModal: {
        title: "Xóa thành viên nhóm",
        desc: "Những người dùng này sẽ bị xóa khỏi nhóm. Bạn có chắc chắn muốn tiếp tục?"
      },
      createTeamModal: {
        title: "Tạo nhóm mới",
        desc: "Vui lòng đặt tên cho nhóm của bạn"
      },
      newUserTooltip: {
        analyst: "Vui lòng liên hệ quản trị viên nhóm để thêm người dùng.",
        admin: "Thêm người dùng mới vào nhóm của bạn."
      },
    },
    deepfake: {
      title: "Phát hiện Deepfake",
      subtitle: "Phân tích phương tiện đáng ngờ để phát hiện deepfake âm thanh và video và nội dung được tạo bởi AI.",
      button: "Bắt đầu phát hiện Deepfake",
      info: {
        automation: "Bạn muốn tự động hóa việc gửi?",
        checkDocs: "Xem tài liệu API của chúng tôi",
        checkDocsOnPrem: "Xem hướng dẫn sử dụng tại chỗ để xem tài liệu API của chúng tôi.",
        contactSupport: "và liên hệ với chúng tôi tại"
      },
      report: {
        overall: {
          user: "Người dùng",
          fileName: "Tên tệp",
          fileSize: "Kích thước tệp (KB)",
          fileHash: "Mã băm tệp (SHA256)",
          submissionDate: "Ngày gửi",
          submissionTime: "Thời gian gửi",
          fileResolution: "Độ phân giải tệp",
          url: "URL",
          faces: "khuôn mặt",
          face: "khuôn mặt",
          processingMessage: "Đang xử lý báo cáo video. Có thể mất vài phút.",
          selectFaceMessage: "Vui lòng chọn ít nhất một khuôn mặt để tạo báo cáo video.",
          enableVoiceAnalysisMessage: "Để kích hoạt dịch vụ phân tích giọng nói, vui lòng liên hệ support@sensity.ai",
          exportingReport: "Đang xuất báo cáo",
          processingVideo: "Đang xử lý video"
        },
        explanation: {
          fakeFacesDetected: "Đã phát hiện khuôn mặt giả",
          faceExplanation: "Khi chọn khuôn mặt, các khung giới hạn tương ứng sẽ hiển thị trên phương tiện.",
          faceExplanationVideo: "và trong video đã xuất.",
          selected: "đã chọn",
          viewOnVideo: "Xem trên video",
          exportVideoLabel: "Xuất video với các khuôn mặt đã chọn",
          faceResolution: "Độ phân giải khuôn mặt",
          low: "thấp",
          lowResolutionInfo: "Độ phân giải khuôn mặt thấp có thể ảnh hưởng đến độ chính xác của bộ phát hiện",
          viewAt: "Xem tại",
          pixelBasedAssessment: "Đánh giá dựa trên pixel",
          pixelBasedInfo: "Phần này cung cấp các biểu diễn trực quan xác nhận kết quả ở trên",
          expandImage: "Mở rộng hình ảnh",
          expandVideoFrame: "Mở rộng khung video",
          heatmapInfo: "Bản đồ nhiệt hiển thị các khu vực mà mô hình phát hiện khả năng tổng hợp cao hơn ở cấp độ pixel",
          sectionDesc: "Phần này cung cấp các biểu diễn trực quan xác nhận kết quả ở trên",
          pixelAndSegmentTitle: "Đánh giá dựa trên pixel và phân đoạn",
          faceAndPixelTitle: "Căn chỉnh khuôn mặt và đánh giá dựa trên pixel",
          segmentBasedNotAvailable: "Đánh giá dựa trên phân đoạn không khả dụng cho phương tiện này",
          segmentBasedAssesment: "Đánh giá dựa trên phân đoạn",
          segmentBasedInfo: "Bộ phát hiện xác định các đối tượng trong hình ảnh có khả năng được tạo bởi AI cao nhất",
          faceAlignmentAssesment: "Đánh giá căn chỉnh khuôn mặt",
          faceAlignmentInfo: "Hình ảnh được tạo bởi dòng GAN này có thể được nhận biết bởi vị trí cố định của mắt và miệng",
          fakeSpeakersDetected: "Đã Phát Hiện Người Nói Giả Mạo"
        },
      },
      form: {
        title: "Tải lên phương tiện",
        tab: {
          file: "Tệp",
          url: "URL",
          urlNotAvailable: "Tải lên qua URL không khả dụng khi triển khai tại chỗ"
        },
      }
    },
    document: {
      title: "Xác minh tài liệu",
      subtitle: "Trích xuất thông tin chính và xác thực tính xác thực của tài liệu.",
      button: "Bắt đầu xác minh tài liệu",
    },
    identity: {
      title: "Xác minh danh tính",
      subtitle: "Thực hiện xác minh danh tính và phát hiện sự sống.",
      button: "Bắt đầu xác minh danh tính",
    },
    developer: {
      title: "Nhà phát triển",
      subtitle: "Quản lý khóa API và mức sử dụng của bạn",
      apiToken: "Mã thông báo API"
    }
  },
  name: {
    service: {
      deepfake: "Phát hiện Deepfake",
      aiGen: "Nội dung tạo bởi AI",
      faceMan: "Thao tác khuôn mặt",
      document: "Xác minh tài liệu",
      forensic: "Phân tích pháp y",
      id: "Xác minh danh tính",
      liveness: "Video trực tiếp",
      pixel: "Phân tích pixel",
      voice: "Phân tích giọng nói",
      faceMatch: "Đối chiếu khuôn mặt",
      title: "Sensity Enterprise",
      idDoc: "Đọc tài liệu định danh"
    },
    language: {
      english: "Tiếng Anh (English)",
      turkish: "Tiếng Thổ Nhĩ Kỳ (Türkçe)",
      uzbek: "Tiếng Uzbek (O'zbekcha)",
      spanish: "Tiếng Tây Ban Nha (Español)",
      korean: "Tiếng Hàn (한국어)",
      russian: "Tiếng Nga (Русский)",
      hindi: "Tiếng Hindi (हिंदी)",
      german: "Tiếng Đức (Deutsch)",
      dutch: "Tiếng Hà Lan (Nederlands)",
      chinese: "Tiếng Trung (中文)",
      arabic: "Tiếng Ả Rập (العربية)",
      indonesian: "Tiếng Indonesia (Bahasa)",
      vietnamese: "Tiếng Việt",
      japanese: "Tiếng Nhật (日本語)",
      italian: "Tiếng Ý (Italiano)",
      french: "Tiếng Pháp (Français)"
    }
  }
}
