/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Kirim",
      register: "Daftar",
      login: "Masuk",
      continue: "Lanjutkan",
      confirm: "Konfirmasi",
      close: "Tutup",
      back: "Kembali",
      browse: "Telusuri berkas",
      save: "Simpan",
      cancel: "Batal",
      delete: "Hapus",
      create: "Buat",
      invite: "Undang",
      analyze: "Analisis",
      select: "Pilih",
      selectAll: "Pilih Semua",
      clearFilters: "Hapus Filter",
      exportReport: "Ekspor Laporan",
      exportAsPDF: "Ekspor sebagai PDF",
      exportCSV: "Ekspor CSV",
      analyzeVoice: "Analisis Suara",
      backToAll: "Kembali ke Semua Laporan",
      downloadVideoReport: "Unduh Laporan Video",
      generateVideoReport: "Buat Laporan Video",
      startDeepfake: "Mulai Deteksi Deepfake",
      changePassword: "Ubah Kata Sandi",
      logout: "Keluar",
      editMember: "Edit Anggota Tim",
      editTeam: "Edit Tim",
      newUser: "Pengguna Baru",
      createTeam: "Buat Tim Baru",
      copy: "Salin",
      showThumbnails: "Tampilkan Thumbnail",
      blurThumbnails: "Buramkan Thumbnail",
      showMore: "Tampilkan Lebih Banyak",
      showLess: "Tampilkan Lebih Sedikit",
    },
    loading: {
      submitting: "Mengirim",
      registering: "Mendaftar",
      loggingIn: "Masuk",
      sending: "Mengirim",
      inProgress: "Sedang Berlangsung",
      changing: "Mengubah",
      processing: "Memproses",
      exporting: "Mengekspor",
      generating: "Membuat",
      loading: "Memuat"
    },
    placeholder: {
      email: "{'contoh@mail.com'}",
      password: "*********",
      enterUrl: "Masukkan URL",
      enterEmail: "Masukkan alamat email",
      enterTeamName: "Masukkan nama tim",
      select: "Silakan pilih nilai",
      searchByNameAndEmail: "Cari berdasarkan nama atau alamat email",
    },
    label: {
      email: "Email",
      password: "Kata Sandi",
      confirmPassword: "Konfirmasi Kata Sandi",
      name: "Nama",
      firstName: "Nama Depan",
      lastName: "Nama Belakang",
      phoneNumber: "Nomor Telepon",
      industry: "Industri",
      companyWebsite: "Situs Web Perusahaan",
      companyEmail: "Email Perusahaan",
      oldPassword: "Kata sandi lama",
      newPassword: "Kata sandi baru",
      teamName: "Nama Tim",
      teamVisibility: "Visibilitas Tim",
      role: "Peran",
      emailAddress: "Alamat email",
      dragAndDrop: "Seret & lepas berkas media di sini",
      browseFiles: "Telusuri berkas",
      or: "atau",
      hide: "Sembunyikan",
      show: "Tampilkan",
      all: "Semua",
      selected: "{count} Dipilih",
      minutes: "menit",
      allResults: "Semua Hasil",
      audio: "audio",
      video: "video",
      image: "gambar",
      pdf: "pdf",
      url: "URL",
      fileName: "Nama Berkas",
      reportId: "ID Laporan",
      searchType: "Jenis Pencarian",
      searchBy: "Cari Berdasarkan",
      status: "Status",
      user: "Pengguna",
      serviceType: "Jenis Layanan",
      date: "Tanggal",
      report: "Laporan",
      reportStatus: "Status Laporan",
      extractedDetails: "Detail yang Diekstrak",
      settings: "Pengaturan",
      language: "Bahasa",
      noTeamMembers: "Tidak ditemukan anggota tim.",
      currentUser: "Pengguna saat ini",
      inviteSent: "Undangan Terkirim",
      members: "{count} Anggota",
      timeRange: "Rentang Waktu",
      duration: "Durasi",
      speaker: "Pembicara"
    }
  },
  component: {
    list: {
      title: "Daftar Hasil",
      noData: "Data tidak ditemukan",
      noDataForEmail: "Tidak ada hasil ditemukan untuk email ini:",
      searchPlaceholder: "Cari email...",
      resultsFound: "Hasil Ditemukan: {count}",
      deleteModal: {
        title: "Hapus Item Terpilih",
        message: "Laporan ini akan dihapus. Anda yakin ingin melanjutkan?",
      },
      table: {
        header: {
          media: "Media",
          info: "Info",
          result: "Hasil",
          riskScore: "Skor Risiko Dokumen"
        },
      },
    },
    status: {
      inProgress: "Sedang Berlangsung",
      completed: "Selesai",
      failed: "Gagal",
      verified: "Terverifikasi",
      notVerified: "Belum Terverifikasi",
      valid: "Valid",
      suspicious: "Mencurigakan",
      serverError: "Error Server",
      extractionFailed: "Ekstraksi Gagal",
      tooltip: {
        analyzing: "Menganalisis Konten...",
        urlExtractionFailed: "Ekstraksi Konten URL Gagal",
        urlExtractionFailedDesc: "Konten tidak dapat diekstrak dari URL yang diberikan.",
        fakeDetected: "Konten Palsu atau Upaya Spoofing Terdeteksi",
        fakeDetectedDesc: "Konten ini telah ditandai sebagai palsu atau mengandung upaya spoofing.",
        checkCompleted: "Pemeriksaan Berhasil Diselesaikan",
        checkCompletedDesc: "Tidak ditemukan anomali atau upaya spoofing.",
        serverErrorDesc: "Terjadi kesalahan. Silakan coba lagi nanti."
      }
    },
    table: {
      detection: {
        faceManCheck: "Pemeriksaan Manipulasi Wajah",
        aiGenCheck: "Pemeriksaan Konten Buatan AI",
        forensicAnalysisCheck: "Pemeriksaan Analisis Forensik",
        voiceAnalysisCheck: "Pemeriksaan Analisis Suara",
        confidence: "Tingkat Kepercayaan",
        modelGenerator: "Generator Model",
        manipulationType: "Jenis Manipulasi",
        noFace: "Wajah terlalu kecil/Tidak ada wajah",
        noVoice: "Tidak ada Suara",
        details: "Detail",
        alert: "Peringatan",
        creationDate: "Tanggal Pembuatan",
        modifyDate: "Tanggal Modifikasi",
        notAvailable: "T/A",
        description: {
          confidence: "Tingkat kepercayaan tinggi menunjukkan tanda-tanda jelas manipulasi AI, dengan kepastian minimal 50%",
          confidenceAI: "Tingkat kepercayaan tinggi menunjukkan tanda-tanda jelas generasi AI, dengan kepastian minimal 50%",
          modelGenerator: "Generator Model: ketika ditampilkan, detektor juga dapat mengidentifikasi model mana yang digunakan sebagai generator",
          manipulationType: "Jenis manipulasi: ketika ditampilkan, detektor juga dapat mengidentifikasi apakah ini pertukaran wajah, penggerakan wajah ulang, sinkronisasi bibir, atau animasi wajah"
        }
      }
    },
    pagination: {
      itemPerPage: "Item per halaman:",
      range: "{from}-{to} dari {total}",
    },
    modal: {
      upgradePlan: {
        title: "Tingkatkan Paket",
        message: "Anda dapat menghubungi manajer akun Anda untuk meningkatkan paket dengan mengklik tombol di bawah.",
        demoMessage: "Dengan mengklik \"Saya ingin meningkatkan paket saya\" Anda akan dihubungi oleh manajer akun yang akan menjelaskan berbagai opsi.\nApakah Anda ingin melanjutkan?",
        licenceMessage: "Lisensi Anda telah kedaluwarsa. Silakan hubungi kami untuk memperbarui lisensi Anda.",
        monthlyLimitReached: "Batas Bulanan Tercapai",
        trialLimitReached: "Anda telah mencapai batas pengiriman uji coba bulanan sebanyak {limit}.",
        action: "Saya ingin meningkatkan paket saya",
      },
    }
  },
  feedback: {
    error: {
      label: "Kesalahan!",
      default: "Terjadi Kesalahan",
      server: "Terjadi kesalahan server",
      generic: "Terjadi kesalahan. Silakan coba lagi nanti.",
      unsupportedFileType: "Langganan Anda tidak mengizinkan mengunggah jenis file ini",
      terms: "Silakan setujui Syarat dan Ketentuan!",
      upload: "Gagal mengunggah file",
    },
    success: {
      label: "Berhasil!",
      default: "Operasi berhasil",
      passwordChanged: "Kata sandi berhasil diubah",
      registration: "Permintaan Anda telah dikirim untuk persetujuan",
      contact: "Terima kasih atas permintaan Anda, kami akan segera menghubungi Anda",
      copy: "Berhasil disalin ke clipboard",
    },
    warning: {
      label: "Peringatan!",
      trialDisclaimer: "Anda menggunakan versi uji coba platform. Tingkatkan ke paket premium untuk menggunakan platform tanpa batasan.",
      submissionDisclaimer: "Mohon jangan mengirimkan informasi pribadi. Sensity tidak bertanggung jawab atas konten yang Anda kirimkan."
    },
    info: {
      agreeTo: "Saya setuju dengan yang berikut:",
      terms: "Syarat dan Ketentuan",
      privacy: "Kebijakan Privasi",
    },
    validation: {
      required: "Bidang ini wajib diisi",
      url: "Silakan masukkan URL yang valid",
      email: "Silakan masukkan email yang valid",
      password: "Silakan masukkan kata sandi yang valid",
      passwordMismatch: "Kata sandi tidak cocok",
      numeric: "Bidang ini harus berupa angka",
      workEmail: "Silakan berikan email kerja",
      website: "Silakan masukkan situs web yang valid",
      phoneNumber: "Silakan masukkan nomor telepon yang valid",
      allFieldsRequired: "Silakan isi semua bidang!",
      allFieldsCorrect: "Silakan isi semua bidang dengan benar!",
      termsRequired: "Silakan setujui Syarat dan Ketentuan!"
    },
    limitation: {
      maxFileSize: "Ukuran berkas maksimum",
      maxFile: "Jumlah maksimum berkas yang dapat diunggah secara bersamaan",
      invalidFile: "Jenis berkas tidak valid",
      singleFileWarning: "Anda hanya dapat meletakkan satu berkas",
      maxFileWarning: "Anda hanya dapat meletakkan {count} berkas sekaligus",
      maxFileNameLength: "Nama berkas maksimum dapat berisi {count} karakter",
      badExtension: "Ekstensi berkas tidak valid",
      maxAudioDuration: "Durasi audio maksimum {value} menit",
      maxVideoDuration: "Durasi video maksimum {value} menit",
      maxVideoResolution: "Resolusi video maksimum {height}x{width} atau {width}x{height}",
      fileAlreadyUploaded: "Berkas {filename} sudah diunggah",
      unsupportedFileType: "Jenis berkas tidak didukung untuk langganan Anda"
    }
  },
  page: {
    home: {
      title: "Dasbor",
      subtitle: "Lihat detail penggunaan dan pengiriman",
      stats: {
        monthlyLeftCredits: "Sisa Kredit Bulanan",
        monthlyUsage: "Penggunaan Bulanan",
        accountExpire: "Akun akan kedaluwarsa pada",
        getStarted: "Dapatkan yang terbaik dari Sensity",
        videoTitle: "Memulai dengan Sensity: fitur dan hasil"
      }
    },
    auth: {
      login: {
        title: "Masuk",
        subtitle: "Belum punya akun?",
        signUpLink: "Daftar gratis",
        forgotPassword: "Lupa kata sandi?"
      },
      register: {
        title: "Buat akun",
        subtitle: "Sudah punya akun?",
        emailDisclaimer: "Pendaftaran Anda akan disetujui oleh operator manusia. Domain gratis dan domain sementara tidak akan dianggap sah secara default",
        detailsPlaceholder: "Detail \nMohon ceritakan lebih lanjut tentang organisasi Anda dan tujuan apa yang ingin Anda capai menggunakan Sensity. Tanpa informasi yang lengkap, akun Anda akan ditolak"
      },
      passwordRecovery: {
        title: "Pemulihan Kata Sandi",
        subtitle: "Kembali ke",
        message: "Masukkan email yang Anda gunakan untuk akun Anda.",
        successMessage: "Kami telah mengirimkan instruksi pengaturan ulang kata sandi ke alamat email Anda. Jika tidak ada email yang diterima dalam waktu sepuluh menit, periksa apakah alamat yang dimasukkan sudah benar."
      },
      confirmPassword: {
        title: "Atur Ulang Kata Sandi"
      },
      changePassword: {
        title: "Ubah kata sandi"
      }
    },
    analytics: {
      title: "Analitik",
      subtitle: "Jelajahi wawasan terperinci dari semua konten yang diunggah untuk pengiriman platform dan API."
    },
    team: {
      title: "Manajemen Tim",
      visibility: {
        visible: "Kiriman dapat dilihat oleh seluruh Tim",
        notVisible: "Kiriman tidak dapat dilihat oleh seluruh Tim",
        tooltip: {
          visible: "Kiriman tim Anda dapat dilihat oleh semua anggota tim.",
          notVisible: "Kiriman Anda bersifat pribadi untuk setiap anggota tim."
        },
        hint: "Jika Anda ingin membuat kiriman tim Anda dapat dilihat oleh semua anggota tim, silakan centang kotak ini. Jangan centang jika Anda ingin kiriman setiap anggota tim tetap pribadi."
      },
      addNewMemberModal: {
        title: "Tambah Anggota Tim Baru",
        desc: "Silakan berikan alamat email anggota tim baru.",
        existingMember: "Pengguna ini sudah menjadi anggota tim."
      },
      upgradeModal: {
        title: "Manajemen tim adalah fitur premium",
        desc: "Dengan mengklik \"Saya ingin meningkatkan paket saya\" Anda akan dihubungi oleh manajer akun yang akan menjelaskan berbagai opsi.",
        confirm: "Apakah Anda ingin melanjutkan?",
        button: "Saya ingin meningkatkan paket saya"
      },
      deleteTeamMemberModal: {
        title: "Hapus Anggota Tim",
        desc: "Pengguna-pengguna ini akan dihapus dari tim. Anda yakin ingin melanjutkan?"
      },
      createTeamModal: {
        title: "Buat Tim Baru",
        desc: "Silakan berikan nama untuk tim Anda"
      },
      newUserTooltip: {
        analyst: "Silakan hubungi administrator tim Anda untuk menambahkan lebih banyak pengguna.",
        admin: "Tambahkan pengguna baru ke tim Anda."
      },
    },
    deepfake: {
      title: "Deteksi Deepfake",
      subtitle: "Analisis media yang mencurigakan untuk mendeteksi deepfake audio dan video serta konten yang dihasilkan AI.",
      button: "Mulai Deteksi Deepfake",
      info: {
        automation: "Ingin mengotomatiskan pengiriman?",
        checkDocs: "Periksa dokumentasi API kami",
        checkDocsOnPrem: "Periksa manual pengguna on-premise kami untuk melihat dokumentasi API kami.",
        contactSupport: "dan hubungi kami di"
      },
      report: {
        overall: {
          user: "Pengguna",
          fileName: "Nama Berkas",
          fileSize: "Ukuran Berkas (KB)",
          fileHash: "Hash Berkas (SHA256)",
          submissionDate: "Tanggal Pengiriman",
          submissionTime: "Waktu Pengiriman",
          fileResolution: "Resolusi Berkas",
          url: "URL",
          faces: "wajah",
          face: "wajah",
          processingMessage: "Memproses laporan video. Ini mungkin membutuhkan beberapa menit.",
          selectFaceMessage: "Silakan pilih setidaknya satu wajah untuk menghasilkan laporan video.",
          enableVoiceAnalysisMessage: "Untuk mengaktifkan layanan analisis suara, silakan hubungi support@sensity.ai",
          exportingReport: "Mengekspor Laporan",
          processingVideo: "Memproses Video"
        },
        explanation: {
          fakeFacesDetected: "Wajah Palsu Terdeteksi",
          faceExplanation: "Dengan memilih wajah, kotak pembatas yang terkait akan ditampilkan di atas media.",
          faceExplanationVideo: "dan dalam video yang diekspor.",
          selected: "dipilih",
          viewOnVideo: "Lihat di video",
          exportVideoLabel: "Ekspor video dengan wajah yang dipilih",
          faceResolution: "Resolusi Wajah",
          low: "rendah",
          lowResolutionInfo: "Resolusi wajah yang rendah dapat mengurangi akurasi detektor",
          viewAt: "Lihat di",
          pixelBasedAssessment: "Penilaian berbasis piksel",
          pixelBasedInfo: "Bagian ini memberikan representasi visual yang mengkonfirmasi hasil di atas",
          expandImage: "Perbesar Gambar",
          expandVideoFrame: "Perbesar Frame Video",
          heatmapInfo: "Peta panas menggambarkan area di mana model mendeteksi kemungkinan sintesis yang lebih tinggi di tingkat piksel",
          sectionDesc: "Bagian ini memberikan representasi visual yang mengkonfirmasi hasil di atas",
          pixelAndSegmentTitle: "Penilaian berbasis piksel dan segmen",
          faceAndPixelTitle: "Penyelarasan wajah dan penilaian berbasis piksel",
          segmentBasedNotAvailable: "Penilaian berbasis segmen tidak tersedia untuk media ini",
          segmentBasedAssesment: "Penilaian Berbasis Segmen",
          segmentBasedInfo: "Detektor mengidentifikasi objek dalam gambar yang paling mungkin dihasilkan oleh AI",
          faceAlignmentAssesment: "Penilaian Penyelarasan Wajah",
          faceAlignmentInfo: "Gambar yang dihasilkan oleh keluarga GAN ini dapat dikenali dari posisi mata dan mulut yang tetap",
          fakeSpeakersDetected: "Pembicara Palsu Terdeteksi",
        },
      },
      form: {
        title: "Unggah Media",
        tab: {
          file: "Berkas",
          url: "URL",
          urlNotAvailable: "Pengunggahan URL tidak tersedia di on-premise"
        },
      }
    },
    document: {
      title: "Verifikasi Dokumen",
      subtitle: "Ekstrak informasi kunci dan validasi keaslian dokumen.",
      button: "Mulai Verifikasi Dokumen",
    },
    identity: {
      title: "Verifikasi Identitas",
      subtitle: "Melakukan verifikasi identitas dan deteksi keaktifan.",
      button: "Mulai Verifikasi Identitas",
    },
    developer: {
      title: "Pengembang",
      subtitle: "Kelola kunci API dan penggunaan Anda",
      apiToken: "Token API"
    }
  },
  name: {
    service: {
      deepfake: "Deteksi Deepfake",
      aiGen: "Konten Buatan AI",
      faceMan: "Manipulasi Wajah",
      document: "Verifikasi Dokumen",
      forensic: "Analisis Forensik",
      id: "Verifikasi Identitas",
      liveness: "Video Liveness",
      pixel: "Analisis Piksel",
      voice: "Analisis Suara",
      faceMatch: "Pencocokan Wajah",
      title: "Sensity Enterprise",
      idDoc: "Pembaca Dokumen Identitas"
    },
    language: {
      english: "Bahasa Inggris (English)",
      turkish: "Bahasa Turki (Türkçe)",
      uzbek: "Bahasa Uzbek (O'zbekcha)",
      spanish: "Bahasa Spanyol (Español)",
      korean: "Bahasa Korea (한국어)",
      russian: "Bahasa Rusia (Русский)",
      hindi: "Bahasa Hindi (हिंदी)",
      german: "Bahasa Jerman (Deutsch)",
      dutch: "Bahasa Belanda (Nederlands)",
      chinese: "Bahasa Mandarin (中文)",
      arabic: "Bahasa Arab (العربية)",
      indonesian: "Bahasa Indonesia",
      vietnamese: "Bahasa Vietnam (Tiếng Việt)",
      japanese: "Bahasa Jepang (日本語)",
      italian: "Bahasa Italia (Italiano)",
      french: "Bahasa Prancis (Français)"
    }
  }
}
