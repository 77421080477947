/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Absenden",
      register: "Registrieren",
      login: "Anmelden",
      continue: "Weiter",
      confirm: "Bestätigen",
      close: "Schließen",
      back: "Zurück",
      browse: "Dateien durchsuchen",
      save: "Speichern",
      cancel: "Abbrechen",
      delete: "Löschen",
      create: "Erstellen",
      invite: "Einladen",
      analyze: "Analysieren",
      select: "Auswählen",
      selectAll: "Alle auswählen",
      clearFilters: "Filter zurücksetzen",
      exportReport: "Bericht Exportieren",
      exportAsPDF: "Als PDF Exportieren",
      exportCSV: "CSV Exportieren",
      analyzeVoice: "Stimme Analysieren",
      backToAll: "Zurück zu allen Berichten",
      downloadVideoReport: "Videobericht herunterladen",
      generateVideoReport: "Videobericht erstellen",
      startDeepfake: "Deepfake-Erkennung starten",
      changePassword: "Passwort ändern",
      logout: "Abmelden",
      editMember: "Teammitglied bearbeiten",
      editTeam: "Team bearbeiten",
      newUser: "Neuer Benutzer",
      createTeam: "Neues Team erstellen",
      copy: "Kopieren",
      showThumbnails: "Vorschaubilder Anzeigen",
      blurThumbnails: "Vorschaubilder Verwischen",
      showMore: "Mehr anzeigen",
      showLess: "Weniger anzeigen",
    },
    loading: {
      submitting: "Wird gesendet",
      registering: "Registrierung läuft",
      loggingIn: "Anmeldung läuft",
      sending: "Wird gesendet",
      inProgress: "In Bearbeitung",
      changing: "Wird geändert",
      processing: "Wird verarbeitet",
      exporting: "Wird exportiert",
      generating: "Wird erstellt",
      loading: "Wird geladen"
    },
    placeholder: {
      email: "{'beispiel@mail.com'}",
      password: "*********",
      enterUrl: "URL eingeben",
      enterEmail: "E-Mail-Adresse eingeben",
      enterTeamName: "Teamname eingeben",
      select: "Bitte einen Wert auswählen",
      searchByNameAndEmail: "Nach Name oder E-Mail-Adresse suchen",
    },
    label: {
      email: "E-Mail",
      password: "Passwort",
      confirmPassword: "Passwort bestätigen",
      name: "Name",
      firstName: "Vorname",
      lastName: "Nachname",
      phoneNumber: "Telefonnummer",
      industry: "Branche",
      companyWebsite: "Unternehmenswebsite",
      companyEmail: "Unternehmens-E-Mail",
      oldPassword: "Altes Passwort",
      newPassword: "Neues Passwort",
      teamName: "Teamname",
      teamVisibility: "Team-Sichtbarkeit",
      role: "Rolle",
      emailAddress: "E-Mail-Adresse",
      dragAndDrop: "Mediendateien hierher ziehen",
      browseFiles: "Dateien durchsuchen",
      or: "oder",
      hide: "Ausblenden",
      show: "Einblenden",
      all: "Alle",
      selected: "{count} Ausgewählt",
      minutes: "min",
      allResults: "Alle Ergebnisse",
      audio: "Audio",
      video: "Video",
      image: "Bild",
      pdf: "PDF",
      url: "URL",
      fileName: "Dateiname",
      reportId: "Bericht-ID",
      searchType: "Suchtyp",
      searchBy: "Suchen nach",
      status: "Status",
      user: "Benutzer",
      serviceType: "Diensttyp",
      date: "Datum",
      report: "Bericht",
      reportStatus: "Berichtsstatus",
      extractedDetails: "Extrahierte Details",
      settings: "Einstellungen",
      language: "Sprache",
      noTeamMembers: "Keine Teammitglieder gefunden.",
      currentUser: "Aktueller Benutzer",
      inviteSent: "Einladung gesendet",
      members: "{count} Mitglieder",
      timeRange: "Zeitbereich",
      duration: "Dauer",
      speaker: "Sprecher"
    }
  },
  component: {
    list: {
      title: "Ergebnisliste",
      noData: "Keine Daten gefunden",
      noDataForEmail: "Keine Ergebnisse für diese E-Mail gefunden:",
      searchPlaceholder: "E-Mail suchen...",
      resultsFound: "Gefundene Ergebnisse: {count}",
      deleteModal: {
        title: "Ausgewählte Elemente löschen",
        message: "Diese Berichte werden gelöscht. Möchten Sie fortfahren?",
      },
      table: {
        header: {
          media: "Medien",
          info: "Info",
          result: "Ergebnis",
          riskScore: "Dokument-Risikobewertung"
        },
      },
    },
    status: {
      inProgress: "In Bearbeitung",
      completed: "Abgeschlossen",
      failed: "Fehlgeschlagen",
      verified: "Verifiziert",
      notVerified: "Nicht verifiziert",
      valid: "Gültig",
      suspicious: "Verdächtig",
      serverError: "Serverfehler",
      extractionFailed: "Extraktion fehlgeschlagen",
      tooltip: {
        analyzing: "Inhalt wird analysiert...",
        urlExtractionFailed: "URL-Inhaltsextraktion fehlgeschlagen",
        urlExtractionFailedDesc: "Der Inhalt konnte nicht von der angegebenen URL extrahiert werden.",
        fakeDetected: "Gefälschter Inhalt oder Täuschungsversuch erkannt",
        fakeDetectedDesc: "Dieser Inhalt wurde als gefälscht markiert oder enthält einen Täuschungsversuch.",
        checkCompleted: "Überprüfung erfolgreich abgeschlossen",
        checkCompletedDesc: "Keine Anomalien oder Täuschungsversuche gefunden.",
        serverErrorDesc: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
      }
    },
    table: {
      detection: {
        faceManCheck: "Gesichtsmanipulationsprüfung",
        aiGenCheck: "KI-generierte Inhaltsprüfung",
        forensicAnalysisCheck: "Forensische Analyseprüfung",
        voiceAnalysisCheck: "Stimmanalyseprüfung",
        confidence: "Konfidenz",
        modelGenerator: "Modellgenerator",
        manipulationType: "Manipulationstyp",
        noFace: "Gesicht zu klein/Kein Gesicht",
        noVoice: "Keine Stimme",
        details: "Details",
        alert: "Warnung",
        creationDate: "Erstellungsdatum",
        modifyDate: "Änderungsdatum",
        notAvailable: "N/V",
        description: {
          confidence: "Hohe Konfidenz zeigt deutliche Anzeichen von KI-Manipulation, mit mindestens 50% Sicherheit",
          confidenceAI: "Hohe Konfidenz zeigt deutliche Anzeichen von KI-Generierung, mit mindestens 50% Sicherheit",
          modelGenerator: "Modellgenerator: wenn angezeigt, kann der Detektor auch identifizieren, welches Modell als Generator verwendet wurde",
          manipulationType: "Manipulationstyp: wenn angezeigt, kann der Detektor auch identifizieren, ob es sich um einen Gesichtstausch, eine Gesichtsnachstellung, Lippensynchronisation oder Gesichtsanimation handelt"
        }
      }
    },
    pagination: {
      itemPerPage: "Einträge pro Seite:",
      range: "{from}-{to} von {total}",
    },
    modal: {
      upgradePlan: {
        title: "Plan upgraden",
        message: "Sie können Ihren Account-Manager kontaktieren, um Ihren Plan durch Klicken auf den Button unten zu upgraden.",
        demoMessage: "Wenn Sie auf \"Ich möchte meinen Plan upgraden\" klicken, wird Ihr Account-Manager Sie kontaktieren und die verschiedenen Optionen erläutern.\nMöchten Sie fortfahren?",
        licenceMessage: "Ihre Lizenz ist abgelaufen. Bitte kontaktieren Sie uns, um Ihre Lizenz zu erneuern.",
        monthlyLimitReached: "Monatliches Limit erreicht",
        trialLimitReached: "Sie haben Ihr monatliches Testlimit von {limit} Einreichungen erreicht.",
        action: "Ich möchte meinen Plan upgraden",
      },
    }
  },
  feedback: {
    error: {
      label: "Fehler!",
      default: "Ein Fehler ist aufgetreten",
      server: "Ein Serverfehler ist aufgetreten",
      generic: "Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut.",
      unsupportedFileType: "Ihr Abonnement erlaubt das Hochladen dieses Dateityps nicht",
      terms: "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen!",
      upload: "Fehler beim Hochladen der Datei",
    },
    success: {
      label: "Erfolg!",
      default: "Operation erfolgreich",
      passwordChanged: "Passwort erfolgreich geändert",
      registration: "Ihre Anfrage wurde zur Genehmigung gesendet",
      contact: "Danke für Ihre Anfrage, wir werden uns bald bei Ihnen melden",
      copy: "In die Zwischenablage kopiert",
    },
    warning: {
      label: "Warnung!",
      trialDisclaimer: "Sie verwenden die Testversion der Plattform. Upgraden Sie auf den Premium-Plan, um die Plattform ohne Einschränkungen zu nutzen.",
      submissionDisclaimer: "Bitte übermitteln Sie keine persönlichen Informationen. Sensity ist nicht für den Inhalt Ihrer Übermittlung verantwortlich."
    },
    info: {
      agreeTo: "Ich stimme Folgendem zu:",
      terms: "Geschäftsbedingungen",
      privacy: "Datenschutzerklärung",
    },
    validation: {
      required: "Dieses Feld ist erforderlich",
      url: "Bitte geben Sie eine gültige URL ein",
      email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      password: "Bitte geben Sie ein gültiges Passwort ein",
      passwordMismatch: "Passwörter stimmen nicht überein",
      numeric: "Dieses Feld muss numerisch sein",
      workEmail: "Bitte geben Sie eine geschäftliche E-Mail-Adresse an",
      website: "Bitte geben Sie eine gültige Website ein",
      phoneNumber: "Bitte geben Sie eine gültige Telefonnummer ein",
      allFieldsRequired: "Bitte füllen Sie alle Felder aus!",
      allFieldsCorrect: "Bitte füllen Sie alle Felder korrekt aus!",
      termsRequired: "Bitte akzeptieren Sie die Geschäftsbedingungen!"
    },
    limitation: {
      maxFileSize: "Maximale Dateigröße",
      maxFile: "Maximale Anzahl gleichzeitig hochladbarer Dateien",
      invalidFile: "Ungültiger Dateityp",
      singleFileWarning: "Sie können nur eine Datei ablegen",
      maxFileWarning: "Sie können nur {count} Dateien gleichzeitig ablegen",
      maxFileNameLength: "Der Dateiname darf maximal {count} Zeichen lang sein",
      badExtension: "Ungültige Dateierweiterung",
      maxAudioDuration: "Maximale Audiodauer {value} Minuten",
      maxVideoDuration: "Maximale Videodauer {value} Minuten",
      maxVideoResolution: "Maximale Videoauflösung {height}x{width} oder {width}x{height}",
      fileAlreadyUploaded: "Die Datei {filename} wurde bereits hochgeladen",
      unsupportedFileType: "Nicht unterstützter Dateityp für Ihr Abonnement"
    }
  },
  page: {
    home: {
      title: "Dashboard",
      subtitle: "Nutzungs- und Übermittlungsdetails anzeigen",
      stats: {
        monthlyLeftCredits: "Verbleibende monatliche Credits",
        monthlyUsage: "Monatliche Nutzung",
        accountExpire: "Account läuft ab am",
        getStarted: "Holen Sie das Beste aus Sensity heraus",
        videoTitle: "Erste Schritte mit Sensity: Funktionen und Ergebnisse"
      }
    },
    auth: {
      login: {
        title: "Anmelden",
        subtitle: "Noch kein Konto?",
        signUpLink: "Kostenlos registrieren",
        forgotPassword: "Passwort vergessen?"
      },
      register: {
        title: "Konto erstellen",
        subtitle: "Bereits ein Konto?",
        emailDisclaimer: "Ihre Registrierung wird von einem menschlichen Operator genehmigt. Kostenlose Domains und temporäre Domains werden standardmäßig nicht als legitim betrachtet",
        detailsPlaceholder: "Details \nBitte erzählen Sie uns mehr über Ihre Organisation und welche Ziele Sie mit Sensity erreichen möchten. Ohne ausführliche Informationen wird Ihr Konto abgelehnt"
      },
      passwordRecovery: {
        title: "Passwort-Wiederherstellung",
        subtitle: "Zurück zu",
        message: "Geben Sie die E-Mail-Adresse ein, die Sie für Ihr Konto verwenden.",
        successMessage: "Wir haben Anweisungen zum Zurücksetzen des Passworts an Ihre E-Mail-Adresse gesendet. Wenn Sie innerhalb von zehn Minuten keine E-Mail erhalten, überprüfen Sie bitte, ob die eingegebene Adresse korrekt ist."
      },
      confirmPassword: {
        title: "Passwort zurücksetzen"
      },
      changePassword: {
        title: "Passwort ändern"
      }
    },
    analytics: {
      title: "Analytik",
      subtitle: "Erkunden Sie detaillierte Einblicke in alle hochgeladenen Inhalte für Plattform- und API-Übermittlungen."
    },
    team: {
      title: "Team-Verwaltung",
      visibility: {
        visible: "Übermittlungen sind für das gesamte Team sichtbar",
        notVisible: "Übermittlungen sind nicht für das gesamte Team sichtbar",
        tooltip: {
          visible: "Ihre Team-Übermittlungen sind für alle Teammitglieder sichtbar.",
          notVisible: "Ihre Übermittlungen sind für jedes Teammitglied privat."
        },
        hint: "Wenn Sie möchten, dass Ihre Team-Übermittlungen für alle Teammitglieder sichtbar sind, aktivieren Sie bitte dieses Kontrollkästchen. Aktivieren Sie es nicht, wenn die Übermittlungen jedes Teammitglieds privat bleiben sollen."
      },
      addNewMemberModal: {
        title: "Neues Teammitglied hinzufügen",
        desc: "Bitte geben Sie die E-Mail-Adresse des neuen Teammitglieds an.",
        existingMember: "Dieser Benutzer ist bereits Teammitglied."
      },
      upgradeModal: {
        title: "Team-Verwaltung ist eine Premium-Funktion",
        desc: "Wenn Sie auf \"Ich möchte meinen Plan upgraden\" klicken, wird Ihr Account-Manager Sie kontaktieren und die verschiedenen Optionen erläutern.",
        confirm: "Möchten Sie fortfahren?",
        button: "Ich möchte meinen Plan upgraden"
      },
      deleteTeamMemberModal: {
        title: "Teammitglied löschen",
        desc: "Diese Benutzer werden aus dem Team entfernt. Möchten Sie fortfahren?"
      },
      createTeamModal: {
        title: "Neues Team erstellen",
        desc: "Bitte geben Sie einen Namen für Ihr Team ein"
      },
      newUserTooltip: {
        analyst: "Bitte kontaktieren Sie Ihren Team-Administrator, um weitere Benutzer hinzuzufügen.",
        admin: "Fügen Sie neue Benutzer zu Ihrem Team hinzu."
      },
    },
    deepfake: {
      title: "Deepfake-Erkennung",
      subtitle: "Analysieren Sie verdächtige Medien, um Audio- und Video-Deepfakes sowie KI-generierte Inhalte zu erkennen.",
      button: "Deepfake-Erkennung starten",
      info: {
        automation: "Möchten Sie Übermittlungen automatisieren?",
        checkDocs: "Sehen Sie sich unsere API-Dokumentation an",
        checkDocsOnPrem: "Lesen Sie unser On-Premise-Benutzerhandbuch, um unsere API-Dokumentation einzusehen.",
        contactSupport: "und kontaktieren Sie uns unter"
      },
      report: {
        overall: {
          user: "Benutzer",
          fileName: "Dateiname",
          fileSize: "Dateigröße (KB)",
          fileHash: "Datei-Hash (SHA256)",
          submissionDate: "Übermittlungsdatum",
          submissionTime: "Übermittlungszeit",
          fileResolution: "Dateiauflösung",
          url: "URL",
          faces: "Gesichter",
          face: "Gesicht",
          processingMessage: "Videobericht wird verarbeitet. Dies kann einige Minuten dauern.",
          selectFaceMessage: "Bitte wählen Sie mindestens ein Gesicht aus, um den Videobericht zu generieren.",
          enableVoiceAnalysisMessage: "Um den Stimmanalyse-Service zu aktivieren, kontaktieren Sie bitte support@sensity.ai",
          exportingReport: "Bericht wird exportiert",
          processingVideo: "Video wird verarbeitet"
        },
        explanation: {
          fakeFacesDetected: "Gefälschte Gesichter erkannt",
          faceExplanation: "Durch Auswahl der Gesichter werden die entsprechenden Begrenzungsrahmen über den Medien angezeigt.",
          faceExplanationVideo: "und im exportierten Video.",
          selected: "ausgewählt",
          viewOnVideo: "Im Video anzeigen",
          exportVideoLabel: "Video mit ausgewählten Gesichtern exportieren",
          faceResolution: "Gesichtsauflösung",
          low: "niedrig",
          lowResolutionInfo: "Niedrige Gesichtsauflösung kann die Genauigkeit des Detektors beeinträchtigen",
          viewAt: "Ansicht bei",
          pixelBasedAssessment: "Pixelbasierte Bewertung",
          pixelBasedInfo: "Dieser Abschnitt bietet visuelle Darstellungen zur Bestätigung der obigen Ergebnisse",
          expandImage: "Bild vergrößern",
          expandVideoFrame: "Videoframe vergrößern",
          heatmapInfo: "Die Heatmap zeigt die Bereiche, in denen das Modell eine höhere Wahrscheinlichkeit für Synthese auf Pixelebene erkannt hat",
          sectionDesc: "Dieser Abschnitt bietet visuelle Darstellungen zur Bestätigung der obigen Ergebnisse",
          pixelAndSegmentTitle: "Pixel- und segmentbasierte Bewertung",
          faceAndPixelTitle: "Gesichtsausrichtung und pixelbasierte Bewertung",
          segmentBasedNotAvailable: "Segmentbasierte Bewertung ist für diese Medien nicht verfügbar",
          segmentBasedAssesment: "Segmentbasierte Bewertung",
          segmentBasedInfo: "Der Detektor identifiziert die Objekte im Bild, die am wahrscheinlichsten KI-generiert sind",
          faceAlignmentAssesment: "Gesichtsausrichtungsbewertung",
          faceAlignmentInfo: "Von dieser GAN-Familie generierte Bilder können an der festen Position von Augen und Mund erkannt werden",
          fakeSpeakersDetected: "Gefälschte Sprecher Erkannt"
        },
      },
      form: {
        title: "Medien hochladen",
        tab: {
          file: "Datei",
          url: "URL",
          urlNotAvailable: "URL-Upload ist bei On-Premise nicht verfügbar"
        },
      }
    },
    document: {
      title: "Dokumentenverifizierung",
      subtitle: "Extrahieren Sie Schlüsselinformationen und validieren Sie die Authentizität von Dokumenten.",
      button: "Dokumentenverifizierung starten",
    },
    identity: {
      title: "Identitätsüberprüfung",
      subtitle: "Führt Identitätsüberprüfung und Lebendkeitserkennung durch.",
      button: "Identitätsüberprüfung starten",
    },
    developer: {
      title: "Entwickler",
      subtitle: "Verwalten Sie Ihre API-Schlüssel und Nutzung",
      apiToken: "API-Token"
    },
  },
  name: {
    service: {
      deepfake: "Deepfake-Erkennung",
      aiGen: "KI-generierter Inhalt",
      faceMan: "Gesichtsmanipulation",
      document: "Dokumentenverifizierung",
      forensic: "Forensische Analyse",
      id: "Identitätsüberprüfung",
      liveness: "Video-Liveness",
      pixel: "Pixelanalyse",
      voice: "Stimmanalyse",
      faceMatch: "Gesichtsabgleich",
      title: "Sensity Enterprise",
      idDoc: "Ausweisdokumentenleser"
    },
    language: {
      english: "Englisch (English)",
      turkish: "Türkisch (Türkçe)",
      uzbek: "Usbekisch (O'zbekcha)",
      spanish: "Spanisch (Español)",
      korean: "Koreanisch (한국어)",
      russian: "Russisch (Русский)",
      hindi: "Hindi (हिंदी)",
      german: "Deutsch",
      dutch: "Niederländisch (Nederlands)",
      chinese: "Chinesisch (中文)",
      arabic: "Arabisch (العربية)",
      indonesian: "Indonesisch (Bahasa)",
      vietnamese: "Vietnamesisch (Tiếng Việt)",
      japanese: "Japanisch (日本語)",
      italian: "Italienisch (Italiano)",
      french: "Französisch (Français)"
    }
  }
}
