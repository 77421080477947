import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import i18n from "@/plugins/i18n";
import CleanLayout from "@/views/layouts/CleanLayout";
import NotFound from "@/components/error-boundary/NotFound";

// middlewares
import authorizedMiddleware from "@/middlewares/authorized.middleware";
import unauthorizedMiddleware from "@/middlewares/unauthorized.middleware";
import middlewarePipeline from "@/router/middlewarePipeline";
import DashboardLayout from "@/views/layouts/DashboardLayout";
import VerificationLayout from "@/views/layouts/VerificationLayout";

const routes = [
  {
    path: "/",
    name: "common",
    component: CleanLayout,
    children: [
      {
        path: "",
        name: "common.home",
        redirect: {
          name: "dashboard.home",
        },
      },
      {
        path: "/home",
        name: "home",
        redirect: {
          name: "dashboard.home",
        },
      },
      {
        path: "/login",
        name: "common.login",
        component: () => import("../views/pages/common/LoginPage"),
        meta: { middleware: [unauthorizedMiddleware] },
      },
      {
        path: "/register",
        name: "common.register",
        component: () => {
          if (!store.getters.isOnPrem) {
            return import("../views/pages/common/RegisterPage");
          }

          return router.push("/login");
        },
        meta: { middleware: [unauthorizedMiddleware] },
      },
      {
        path: "/password-recovery",
        name: "common.password.recovery",
        component: () => import("../views/pages/common/PasswordRecoveryPage"),
        meta: { middleware: [unauthorizedMiddleware] },
      },
      {
        path: "/confirm-new-password",
        name: "common.create.new.password",
        component: () => import("../views/pages/common/ConfirmNewPassword"),
        meta: { middleware: [unauthorizedMiddleware] },
      },
    ],
  },
  {
    path: "/",
    name: "dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/analytics",
        name: "dashboard.analytics.list",
        component: () => import("../views/pages/dashboard/AnalyticsPage"),
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/team",
        name: "dashboard.team.management",
        component: () => {
          if (!store.getters.isOnPrem) {
            return import("../views/pages/team_management/TeamManagement.vue");
          }

          return router.push("/");
        },
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/developer",
        name: "dashboard.developer",
        component: () => {
          if (store.getters.hasDetectionApiAccess) {
            return import("../views/pages/dashboard/DeveloperPage");
          }

          return router.push("/");
        },
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/",
        name: "dashboard.home",
        component: () => import("../views/pages/common/HomePage.vue")
      },
      {
        path: "/deepfake-detection",
        name: "dashboard.deepfake.detection",
        component: () => {
          if (
            store.getters.hasAccessToProduct("deepfake_detection")
          ) {
            return import("../views/pages/dashboard/DeepfakeDetectionPage");
          }

          return router.push("/");
        },
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/deepfake-detection/report/:id", // public url
        name: "dashboard.deepfake.detection.report",
        component: () => import("../views/pages/deepfake/Report"),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/document-verification",
        name: "dashboard.document.check",
        component: () => {
          if (
            store.getters.hasAccessToProduct("document_verification")
          ) {
            return import("../views/pages/dashboard/DocumentCheckPage");
          }

          return router.push("/");
        },
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/id-verification",
        name: "dashboard.id.verification",
        component: () => {
          if (
            store.getters.hasAccessToProduct("identity_verification")
          ) {
            return import("../views/pages/dashboard/IdVerification");
          }

          return router.push("/");
        },
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/id-verification/report/:id", // public url
        name: "dashboard.id.verification.report",
        component: () => import("../views/pages/verification/Report"),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/document-verification/report/:id", // public url
        name: "dashboard.document.check.report",
        component: () => import("../views/pages/document/Report"),
        props: (route) => ({
          id: route.params.id,
        }),
        meta: { middleware: [authorizedMiddleware] },
      },
    ],
  },
  {
    path: "/",
    name: "verification",
    component: VerificationLayout,
    children: [
      {
        path: "/id-verification/form",
        name: "verification.form",
        component: () => import("../views/pages/verification/Form"),
        beforeEnter: (to, from, next) => {
          if (
            !store.getters.isTrialEnded &&
            store.getters.hasAccessToProduct("identity_verification")
          ) {
            next();
          } else {
            next("/");
          }
        },
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/deepfake-detection/form",
        name: "deepfake.detection.form",
        component: () => import("../views/pages/deepfake/Form"),
        beforeEnter: (to, from, next) => {
          if (
            !store.getters.isTrialEnded &&
            store.getters.hasAccessToProduct("deepfake_detection")
          ) {
            next();
          } else {
            next("/");
          }
        },
        meta: { middleware: [authorizedMiddleware] },
      },
      {
        path: "/document-verification/form",
        name: "document.check.form",
        component: () => import("../views/pages/document/Form"),
        beforeEnter: (to, from, next) => {
          if (
            !store.getters.isTrialEnded &&
            store.getters.hasAccessToProduct("document_verification")
          ) {
            next();
          } else {
            next("/");
          }
        },
        meta: { middleware: [authorizedMiddleware] },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  strict: true,
  trailing: false,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      window.scrollTo(savedPosition.left, savedPosition.top);
    }
  },
});

router.beforeEach(async (to, from, next) => {
  // Handle language from query parameter
  const langParam = to.query.lang;
  if (langParam) {
    i18n.global.locale.value = langParam;
    localStorage.setItem("locale", langParam);
  }

  const nonRedirectUrls = [
    "common.login",
    "common.register",
    "common.password.recovery",
    "common.confirm.registration",
    "common.create.new.password",
  ];

  if (store.getters.isAuth && nonRedirectUrls.includes(to.name)) {
    return next({ name: "dashboard.home" });
  }

  if (!store.getters.isAuth && !nonRedirectUrls.includes(to.name)) {
    store.dispatch("setRedirectUrl", to.fullPath);

    return next({ name: "common.login" });
  }

  if (!to.meta.middleware) {
    return next();
  }

  if (store.getters.isAuth) {
    return next();
  }

  const { middleware } = to.meta;
  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
