/* eslint-disable */
export default {
  common: {
    action: {
      submit: "送信",
      register: "登録",
      login: "ログイン",
      continue: "続ける",
      confirm: "確認",
      close: "閉じる",
      back: "戻る",
      browse: "ファイルを参照",
      save: "保存",
      cancel: "キャンセル",
      delete: "削除",
      create: "作成",
      invite: "招待",
      analyze: "分析",
      select: "選択",
      selectAll: "すべて選択",
      clearFilters: "フィルターをクリア",
      exportReport: "レポートをエクスポート",
      exportAsPDF: "PDFとしてエクスポート",
      exportCSV: "CSVエクスポート",
      analyzeVoice: "音声を分析",
      backToAll: "すべてのレポートに戻る",
      downloadVideoReport: "動画レポートをダウンロード",
      generateVideoReport: "動画レポートを生成",
      startDeepfake: "ディープフェイク検出を開始",
      changePassword: "パスワードを変更",
      logout: "ログアウト",
      editMember: "チームメンバーを編集",
      editTeam: "チームを編集",
      newUser: "新規ユーザー",
      createTeam: "新規チームを作成",
      copy: "コピー",
      showThumbnails: "サムネイルを表示",
      blurThumbnails: "サムネイルをぼかす",
      showMore: "もっと見る",
      showLess: "閉じる",
    },
    loading: {
      submitting: "送信中",
      registering: "登録中",
      loggingIn: "ログイン中",
      sending: "送信中",
      inProgress: "処理中",
      changing: "変更中",
      processing: "処理中",
      exporting: "エクスポート中",
      generating: "生成中",
      loading: "読み込み中"
    },
    placeholder: {
      email: "{'example@mail.com'}",
      password: "*********",
      enterUrl: "URLを入力",
      enterEmail: "メールアドレスを入力",
      enterTeamName: "チーム名を入力",
      select: "値を選択してください",
      searchByNameAndEmail: "名前またはメールアドレスで検索",
    },
    label: {
      email: "メール",
      password: "パスワード",
      confirmPassword: "パスワードの確認",
      name: "名前",
      firstName: "名",
      lastName: "姓",
      phoneNumber: "電話番号",
      industry: "業界",
      companyWebsite: "会社のウェブサイト",
      companyEmail: "会社のメール",
      oldPassword: "現在のパスワード",
      newPassword: "新しいパスワード",
      teamName: "チーム名",
      teamVisibility: "チームの可視性",
      role: "役割",
      emailAddress: "メールアドレス",
      dragAndDrop: "メディアファイルをここにドラッグ＆ドロップ",
      browseFiles: "ファイルを参照",
      or: "または",
      hide: "非表示",
      show: "表示",
      all: "すべて",
      selected: "{count}件選択",
      minutes: "分",
      allResults: "すべての結果",
      audio: "オーディオ",
      video: "動画",
      image: "画像",
      pdf: "PDF",
      url: "URL",
      fileName: "ファイル名",
      reportId: "レポートID",
      searchType: "検索タイプ",
      searchBy: "検索条件",
      status: "ステータス",
      user: "ユーザー",
      serviceType: "サービスタイプ",
      date: "日付",
      report: "レポート",
      reportStatus: "レポートのステータス",
      extractedDetails: "抽出された詳細",
      settings: "設定",
      language: "言語",
      noTeamMembers: "チームメンバーが見つかりません。",
      currentUser: "現在のユーザー",
      inviteSent: "招待を送信済み",
      members: "{count} メンバー",
      timeRange: "時間範囲",
      duration: "期間",
      speaker: "話者"
    }
  },
  component: {
    list: {
      title: "結果リスト",
      noData: "データが見つかりません",
      noDataForEmail: "このメールアドレスの結果が見つかりません：",
      searchPlaceholder: "メールアドレスを検索...",
      resultsFound: "検索結果：{count}件",
      deleteModal: {
        title: "選択したアイテムを削除",
        message: "これらのレポートは削除されます。続行してもよろしいですか？",
      },
      table: {
        header: {
          media: "メディア",
          info: "情報",
          result: "結果",
          riskScore: "文書リスクスコア"
        },
      },
    },
    status: {
      inProgress: "処理中",
      completed: "完了",
      failed: "失敗",
      verified: "認証済み",
      notVerified: "未認証",
      valid: "有効",
      suspicious: "不審",
      serverError: "サーバーエラー",
      extractionFailed: "抽出失敗",
      tooltip: {
        analyzing: "コンテンツを分析中...",
        urlExtractionFailed: "URLコンテンツの抽出に失敗",
        urlExtractionFailedDesc: "提供されたURLからコンテンツを抽出できませんでした。",
        fakeDetected: "偽造コンテンツまたはなりすまし試行を検出",
        fakeDetectedDesc: "このコンテンツは偽造として判定されたか、なりすまし試行が含まれています。",
        checkCompleted: "確認が正常に完了",
        checkCompletedDesc: "異常やなりすまし試行は検出されませんでした。",
        serverErrorDesc: "エラーが発生しました。後でもう一度お試しください。"
      }
    },
    table: {
      detection: {
        faceManCheck: "顔操作チェック",
        aiGenCheck: "AI生成コンテンツチェック",
        forensicAnalysisCheck: "フォレンジック分析チェック",
        voiceAnalysisCheck: "音声分析チェック",
        confidence: "信頼度",
        modelGenerator: "モデルジェネレーター",
        manipulationType: "操作タイプ",
        noFace: "顔が小さすぎる/顔なし",
        noVoice: "音声なし",
        details: "詳細",
        alert: "警告",
        creationDate: "作成日",
        modifyDate: "修正日",
        notAvailable: "利用不可",
        description: {
          confidence: "高信頼度は、AI操作の明確な兆候を示し、少なくとも50%の確実性があります",
          confidenceAI: "高信頼度は、AI生成の明確な兆候を示し、少なくとも50%の確実性があります",
          modelGenerator: "モデルジェネレーター：表示されている場合、検出器はどのモデルが生成に使用されたかも識別できます",
          manipulationType: "操作タイプ：表示されている場合、検出器は顔の入れ替え、顔の再生成、リップシンク、顔のアニメーションのいずれかを識別できます"
        }
      }
    },
    pagination: {
      itemPerPage: "1ページあたりの項目数：",
      range: "{total}件中{from}-{to}件",
    },
    modal: {
      upgradePlan: {
        title: "プランをアップグレード",
        message: "下のボタンをクリックして、アカウントマネージャーに連絡し、プランをアップグレードできます。",
        demoMessage: "「プランをアップグレードしたい」をクリックすると、アカウントマネージャーから様々なオプションについて説明を受けることができます。\n続行しますか？",
        licenceMessage: "ライセンスが期限切れです。ライセンスを更新するには当社までご連絡ください。",
        monthlyLimitReached: "月間制限に到達",
        trialLimitReached: "トライアルの月間提出制限（{limit}回）に達しました。",
        action: "プランをアップグレードしたい",
      },
    }
  },
  feedback: {
    error: {
      label: "エラー！",
      default: "エラーが発生しました",
      server: "サーバーエラーが発生しました",
      generic: "問題が発生しました。後でもう一度お試しください。",
      unsupportedFileType: "お使いのサブスクリプションではこのファイルタイプのアップロードは許可されていません",
      terms: "利用規約に同意してください！",
      upload: "ファイルのアップロードに失敗しました",
    },
    success: {
      label: "成功！",
      default: "操作が成功しました",
      passwordChanged: "パスワードが正常に変更されました",
      registration: "リクエストが承認のために送信されました",
      contact: "リクエストありがとうございます。まもなくご連絡いたします",
      copy: "クリップボードにコピーしました",
    },
    warning: {
      label: "警告！",
      trialDisclaimer: "プラットフォームのトライアル版を使用中です。制限なくプラットフォームを使用するには、プレミアムプランにアップグレードしてください。",
      submissionDisclaimer: "個人情報は送信しないでください。Sensityは送信内容について責任を負いません。"
    },
    info: {
      agreeTo: "以下に同意します：",
      terms: "利用規約",
      privacy: "プライバシーポリシー",
    },
    validation: {
      required: "このフィールドは必須です",
      url: "有効なURLを入力してください",
      email: "有効なメールアドレスを入力してください",
      password: "有効なパスワードを入力してください",
      passwordMismatch: "パスワードが一致しません",
      numeric: "このフィールドは数値である必要があります",
      workEmail: "会社のメールアドレスを入力してください",
      website: "有効なウェブサイトを入力してください",
      phoneNumber: "有効な電話番号を入力してください",
      allFieldsRequired: "すべてのフィールドを入力してください！",
      allFieldsCorrect: "すべてのフィールドを正しく入力してください！",
      termsRequired: "利用規約に同意してください！"
    },
    limitation: {
      maxFileSize: "最大ファイルサイズ",
      maxFile: "一度にアップロードできる最大ファイル数",
      invalidFile: "無効なファイルタイプ",
      singleFileWarning: "1つのファイルのみドロップできます",
      maxFileWarning: "一度に{count}個のファイルまでしかドロップできません",
      maxFileNameLength: "ファイル名は最大{count}文字までです",
      badExtension: "不正なファイル拡張子",
      maxAudioDuration: "最大音声時間：{value}分",
      maxVideoDuration: "最大動画時間：{value}分",
      maxVideoResolution: "最大動画解像度：{height}x{width}または{width}x{height}",
      fileAlreadyUploaded: "ファイル{filename}は既にアップロードされています",
      unsupportedFileType: "お使いのサブスクリプションではサポートされていないファイルタイプです"
    }
  },
  page: {
    home: {
      title: "ダッシュボード",
      subtitle: "使用状況と提出の詳細を確認",
      stats: {
        monthlyLeftCredits: "残りの月間クレジット",
        monthlyUsage: "月間使用量",
        accountExpire: "アカウントの有効期限：",
        getStarted: "Sensityを最大限活用する",
        videoTitle: "Sensityを始める：機能と成果"
      }
    },
    auth: {
      login: {
        title: "ログイン",
        subtitle: "アカウントをお持ちでない場合",
        signUpLink: "無料で登録",
        forgotPassword: "パスワードをお忘れですか？"
      },
      register: {
        title: "アカウントを作成",
        subtitle: "既にアカウントをお持ちの場合",
        emailDisclaimer: "登録は人間のオペレーターによって承認されます。フリーメールや一時的なドメインは、デフォルトでは正当なものとして認められません",
        detailsPlaceholder: "詳細\nあなたの組織とSensityを使用して達成したい目標について詳しく教えてください。十分な情報がない場合、アカウントは拒否されます"
      },
      passwordRecovery: {
        title: "パスワードの復旧",
        subtitle: "戻る",
        message: "アカウントで使用しているメールアドレスを入力してください。",
        successMessage: "パスワードリセットの手順をメールアドレスに送信しました。10分以内にメールが届かない場合は、入力したアドレスが正しいかご確認ください。"
      },
      confirmPassword: {
        title: "パスワードをリセット"
      },
      changePassword: {
        title: "パスワードを変更"
      }
    },
    analytics: {
      title: "分析",
      subtitle: "プラットフォームとAPIの両方の提出について、アップロードされたすべてのコンテンツの詳細な分析を探索します。"
    },
    team: {
      title: "チーム管理",
      visibility: {
        visible: "提出内容はチーム全体に表示されます",
        notVisible: "提出内容はチーム全体に表示されません",
        tooltip: {
          visible: "チームの提出内容は、すべてのチームメンバーが閲覧できます。",
          notVisible: "提出内容は各チームメンバーにのみ表示されます。"
        },
        hint: "チームの提出内容をすべてのチームメンバーに表示したい場合は、このボックスにチェックを入れてください。各チームメンバーの提出を非公開にしたい場合は、チェックを入れないでください。"
      },
      addNewMemberModal: {
        title: "新しいチームメンバーを追加",
        desc: "新しいチームメンバーのメールアドレスを入力してください。",
        existingMember: "このユーザーは既にチームのメンバーです。"
      },
      upgradeModal: {
        title: "チーム管理はプレミアム機能です",
        desc: "「プランをアップグレードしたい」をクリックすると、アカウントマネージャーから様々なオプションについて説明を受けることができます。",
        confirm: "続行しますか？",
        button: "プランをアップグレードしたい"
      },
      deleteTeamMemberModal: {
        title: "チームメンバーを削除",
        desc: "これらのユーザーはチームから削除されます。続行してもよろしいですか？"
      },
      createTeamModal: {
        title: "新規チームを作成",
        desc: "チームの名前を入力してください"
      },
      newUserTooltip: {
        analyst: "ユーザーを追加するには、チーム管理者にお問い合わせください。",
        admin: "チームに新しいユーザーを追加します。"
      },
    },
    deepfake: {
      title: "ディープフェイク検出",
      subtitle: "不審なメディアを分析して、音声・動画のディープフェイクやAI生成コンテンツを検出します。",
      button: "ディープフェイク検出を開始",
      info: {
        automation: "提出を自動化したいですか？",
        checkDocs: "APIドキュメントを確認",
        checkDocsOnPrem: "APIドキュメントを表示するには、オンプレミスユーザーマニュアルをご確認ください。",
        contactSupport: "お問い合わせ先："
      },
      report: {
        overall: {
          user: "ユーザー",
          fileName: "ファイル名",
          fileSize: "ファイルサイズ（KB）",
          fileHash: "ファイルハッシュ（SHA256）",
          submissionDate: "提出日",
          submissionTime: "提出時刻",
          fileResolution: "ファイル解像度",
          url: "URL",
          faces: "顔",
          face: "顔",
          processingMessage: "動画レポートを処理中です。数分かかる場合があります。",
          selectFaceMessage: "動画レポートを生成するには、少なくとも1つの顔を選択してください。",
          enableVoiceAnalysisMessage: "音声分析サービスを有効にするには、support@sensity.aiまでご連絡ください",
          exportingReport: "レポートをエクスポート中",
          processingVideo: "動画を処理中"
        },
        explanation: {
          fakeFacesDetected: "偽造顔を検出",
          faceExplanation: "顔を選択すると、対応するバウンディングボックスがメディアの上に表示されます。",
          faceExplanationVideo: "エクスポートされた動画にも表示されます。",
          selected: "選択済み",
          viewOnVideo: "動画で表示",
          exportVideoLabel: "選択した顔を含む動画をエクスポート",
          faceResolution: "顔の解像度",
          low: "低",
          lowResolutionInfo: "顔の解像度が低いと、検出器の精度が低下する可能性があります",
          viewAt: "表示位置",
          pixelBasedAssessment: "ピクセルベースの評価",
          pixelBasedInfo: "このセクションでは、上記の結果を確認する視覚的な表現を提供します",
          expandImage: "画像を拡大",
          expandVideoFrame: "動画フレームを拡大",
          heatmapInfo: "ヒートマップは、モデルがピクセルレベルで合成の可能性が高いと検出した領域を示しています",
          sectionDesc: "このセクションでは、上記の結果を確認する視覚的な表現を提供します",
          pixelAndSegmentTitle: "ピクセルとセグメントベースの評価",
          faceAndPixelTitle: "顔の配置とピクセルベースの評価",
          segmentBasedNotAvailable: "このメディアではセグメントベースの評価を利用できません",
          segmentBasedAssesment: "セグメントベースの評価",
          segmentBasedInfo: "検出器は、画像内でAIによって生成された可能性が最も高いオブジェクトを識別します",
          faceAlignmentAssesment: "顔の配置評価",
          faceAlignmentInfo: "このGANファミリーによって生成された画像は、目と口の固定位置によって認識できます",
          fakeSpeakersDetected: "偽の話者を検出"
        },
      },
      form: {
        title: "メディアをアップロード",
        tab: {
          file: "ファイル",
          url: "URL",
          urlNotAvailable: "URLアップロードはオンプレミスでは利用できません"
        },
      }
    },
    document: {
      title: "文書検証",
      subtitle: "重要な情報を抽出し、文書の信頼性を検証します。",
      button: "文書検証を開始",
    },
    identity: {
      title: "本人確認",
      subtitle: "本人確認とライブネス検出を実行します。",
      button: "本人確認を開始",
    },
    developer: {
      title: "開発者",
      subtitle: "APIキーと使用状況を管理",
      apiToken: "APIトークン"
    }
  },
  name: {
    service: {
      deepfake: "ディープフェイク検出",
      aiGen: "AI生成コンテンツ",
      faceMan: "顔操作",
      document: "文書認証",
      forensic: "フォレンジック分析",
      id: "本人確認",
      liveness: "ビデオライブネス",
      pixel: "ピクセル分析",
      voice: "音声分析",
      faceMatch: "顔照合",
      title: "Sensity Enterprise",
      idDoc: "身分証明書リーダー"
    },
    language: {
      english: "英語 (English)",
      turkish: "トルコ語 (Türkçe)",
      uzbek: "ウズベク語 (O'zbekcha)",
      spanish: "スペイン語 (Español)",
      korean: "韓国語 (한국어)",
      russian: "ロシア語 (Русский)",
      hindi: "ヒンディー語 (हिंदी)",
      german: "ドイツ語 (Deutsch)",
      dutch: "オランダ語 (Nederlands)",
      chinese: "中国語 (中文)",
      arabic: "アラビア語 (العربية)",
      indonesian: "インドネシア語 (Bahasa)",
      vietnamese: "ベトナム語 (Tiếng Việt)",
      japanese: "日本語",
      italian: "イタリア語 (Italiano)",
      french: "フランス語 (Français)"
    }
  }
}
