/* eslint-disable */
export default {
  common: {
    action: {
      submit: "Gönder",
      register: "Kayıt Ol",
      login: "Giriş Yap",
      continue: "Devam Et",
      confirm: "Onayla",
      close: "Kapat",
      back: "Geri",
      browse: "Dosya Seç",
      save: "Kaydet",
      cancel: "İptal",
      delete: "Sil",
      create: "Oluştur",
      invite: "Davet Et",
      analyze: "Analiz Et",
      selectAll: "Tümünü Seç",
      clearFilters: "Filtreleri Temizle",
      exportReport: "Raporu Dışa Aktar",
      exportAsPDF: "PDF Olarak Dışa Aktar",
      exportCSV: "CSV İndir",
      analyzeVoice: "Sesi Analiz Et",
      backToAll: "Tüm Raporlara Dön",
      downloadVideoReport: "Video Raporunu İndir",
      generateVideoReport: "Video Raporu Oluştur",
      startDeepfake: "Deepfake Tespitini Başlat",
      changePassword: "Şifreyi Değiştir",
      logout: "Çıkış Yap",
      editMember: "Ekip Üyesini Düzenle",
      editTeam: "Ekibi Düzenle",
      newUser: "Yeni Kullanıcı",
      createTeam: "Yeni Ekip Oluştur",
      select: "Seç",
      copy: "Kopyala",
      showThumbnails: "Küçük Resimleri Göster",
      blurThumbnails: "Küçük Resimleri Bulanıklaştır",
      showMore: "Daha Fazla",
      showLess: "Daha Az"
    },
    loading: {
      submitting: "Gönderiliyor",
      registering: "Kaydediliyor",
      loggingIn: "Giriş Yapılıyor",
      sending: "Gönderiliyor",
      inProgress: "İşleniyor",
      changing: "Değiştiriliyor",
      processing: "İşleniyor",
      exporting: "Dışa Aktarılıyor",
      generating: "Oluşturuluyor",
      loading: "Yükleniyor"
    },
    placeholder: {
      email: "{'ornek@mail.com'}",
      password: "*********",
      enterUrl: "URL girin",
      enterEmail: "E-posta adresini girin",
      enterTeamName: "Ekip adını girin",
      select: "Bir değer seçin",
      searchByNameAndEmail: "İsim veya e-posta ile arayın"
    },
    label: {
      // Auth related
      email: "E-posta",
      password: "Şifre",
      confirmPassword: "Şifreyi Onayla",

      // User information
      name: "İsim",
      firstName: "Ad",
      lastName: "Soyad",
      phoneNumber: "Telefon Numarası",
      industry: "Sektör",
      companyWebsite: "Şirket Web Sitesi",
      companyEmail: "Şirket E-postası",
      oldPassword: "Eski şifre",
      newPassword: "Yeni şifre",

      // Team related
      teamName: "Ekip Adı",
      teamVisibility: "Ekip Görünürlüğü",
      role: "Rol",
      emailAddress: "E-posta adresi",

      // File handling
      dragAndDrop: "Medya dosyalarını buraya sürükleyip bırakın",
      browseFiles: "Dosya seç",

      // Common UI
      or: "veya",
      hide: "Gizle",
      show: "Göster",
      all: "Tümü",
      selected: "{count} Seçildi",
      minutes: "dk",
      allResults: "Tüm Sonuçlar",

      // Media types
      audio: "ses",
      video: "video",
      image: "görüntü",
      pdf: "pdf",
      url: "URL",

      // Search and filters
      fileName: "Dosya Adı",
      reportId: "Rapor ID",
      searchType: "Arama Tipi",
      searchBy: "Şuna Göre Ara",
      status: "Durum",
      user: "Kullanıcı",
      serviceType: "Servis Tipi",
      date: "Tarih",

      // Misc
      report: "Rapor",
      reportStatus: "Rapor Durumu",
      extractedDetails: "Çıkarılan Detaylar",
      settings: "Ayarlar",
      language: "Dil",

      // Team
      noTeamMembers: "Ekip üyesi bulunamadı.",
      currentUser: "Mevcut kullanıcı",
      inviteSent: "Davet Gönderildi",
      members: "{count} Üye",
      timeRange: "Zaman Aralığı",
      duration: "Süre",
      speaker: "Konuşmacı"
    }
  },
  component: {
    list: {
      title: "Sonuç Listesi",
      noData: "Veri bulunamadı",
      noDataForEmail: "Bu e-posta için sonuç bulunamadı:",
      searchPlaceholder: "E-posta ile ara...",
      resultsFound: "Bulunan Sonuçlar: {count}",
      deleteModal: {
        title: "Seçili Öğeleri Sil",
        message: "Bu raporlar silinecek. Devam etmek istediğinizden emin misiniz?"
      },
      table: {
        header: {
          media: "Medya",
          info: "Bilgi",
          result: "Sonuç",
          riskScore: "Doküman Risk Puanı"
        }
      }
    },
    status: {
      inProgress: "İşleniyor",
      completed: "Tamamlandı",
      failed: "Başarısız",
      verified: "Doğrulandı",
      notVerified: "Doğrulanmadı",
      valid: "Geçerli",
      suspicious: "Şüpheli",
      serverError: "Sunucu Hatası",
      extractionFailed: "Çıkarma Başarısız",
      tooltip: {
        analyzing: "İçerik Analiz Ediliyor...",
        urlExtractionFailed: "URL İçeriği Çıkarılamadı",
        urlExtractionFailedDesc: "URL'den içerik çıkarılamadı.",
        fakeDetected: "Sahte İçerik veya Sahtecilik Girişimi Tespit Edildi",
        fakeDetectedDesc: "Bu içerik sahte olarak işaretlendi veya sahtecilik girişimi içeriyor.",
        checkCompleted: "Kontrol Başarıyla Tamamlandı",
        checkCompletedDesc: "Anormallik veya sahtecilik girişimi bulunamadı.",
        serverErrorDesc: "Bir şeyler yanlış gitti. Lütfen tekrar deneyin."
      }
    },
    table: {
      detection: {
        faceManCheck: "Yüz Manipülasyonu Kontrolü",
        aiGenCheck: "Yapay Zeka Üretimi İçerik Kontrolü",
        forensicAnalysisCheck: "Adli Analiz Kontrolü",
        voiceAnalysisCheck: "Ses Analizi Kontrolü",
        confidence: "Güven",
        modelGenerator: "Model Üretici",
        manipulationType: "Manipülasyon Tipi",
        noFace: "Yüz çok küçük/Yüz yok",
        noVoice: "Ses yok",
        details: "Detaylar",
        alert: "Uyarı",
        creationDate: "Oluşturma Tarihi",
        modifyDate: "Değiştirme Tarihi",
        notAvailable: "Mevcut Değil",
        description: {
          confidence: "Yüksek güven, en az %50 kesinlikle yapay zeka manipülasyonunun net işaretlerini gösterir",
          confidenceAI: "Yüksek güven, en az %50 kesinlikle yapay zeka üretiminin net işaretlerini gösterir",
          modelGenerator: "Model Üretici: gösterildiğinde, dedektör hangi modelin üretici olarak kullanıldığını da tanımlayabilir",
          manipulationType: "Manipülasyon tipi: gösterildiğinde, dedektör bunun yüz değiştirme, yüz canlandırma, dudak senkronizasyonu veya yüz animasyonu olup olmadığını da tanımlayabilir"
        }
      }
    },
    pagination: {
      itemPerPage: "Sayfa başına öğe:",
      range: "{from}-{to} / {total}",
    },
    modal: {
      upgradePlan: {
        title: "Planı Yükselt",
        message: "Aşağıdaki düğmeyi tıklayarak hesap yöneticinizle iletişime geçebilirsiniz.",
        demoMessage: "\"Planımı yükseltmek istiyorum\" düğmesine tıkladığınızda, hesap yöneticiniz sizinle iletişime geçecek ve çeşitli seçenekleri açıklayacaktır.\nDevam etmek istiyor musunuz?",
        licenceMessage: "Lisansınızın süresi doldu. Lisansınızı yenilemek için lütfen bizimle iletişime geçin.",
        monthlyLimitReached: "Aylık Limite Ulaşıldı",
        trialLimitReached: "{ limit } aylık deneme gönderim limitinize ulaştınız.",
        action: "Planımı yükseltmek istiyorum",
      },
    }
  },
  feedback: {
    error: {
      label: "Hata!",
      default: "Bir Hata Oluştu",
      server: "Sunucu hatası oluştu",
      generic: "Bir şeyler yanlış gitti. Lütfen daha sonra tekrar deneyin.",
      unsupportedFileType: "Aboneliğiniz bu dosya türünü yüklemeye izin vermiyor",
      terms: "Lütfen Şartlar ve Koşulları kabul edin !",
      upload: "Dosya yüklenemedi"
    },
    success: {
      label: "Başarılı!",
      default: "İşlem başarılı",
      passwordChanged: "Şifre başarıyla değiştirildi",
      registration: "İsteğiniz onay için gönderildi",
      contact: "İsteğiniz için teşekkürler, yakında sizinle iletişime geçeceğiz",
      copy: "Panoya kopyalandı"
    },
    warning: {
      label: "Uyarı!",
      trialDisclaimer: "Platformun deneme sürümünü kullanıyorsunuz. Platformu kısıtlamalar olmadan kullanmak için premium plana yükseltin.",
      submissionDisclaimer: "Lütfen kişisel bilgi göndermeyin. Sensity, gönderdiğiniz içerikten sorumlu değildir."
    },
    info: {
      agreeTo: "Aşağıdakileri kabul ediyorum:",
      terms: "Şartlar ve Koşullar",
      privacy: "Gizlilik Politikası"
    },
    validation: {
      required: "Bu alan zorunludur",
      url: "Lütfen geçerli bir URL girin",
      email: "Lütfen geçerli bir e-posta girin",
      password: "Lütfen geçerli bir şifre girin",
      passwordMismatch: "Şifreler eşleşmiyor",
      numeric: "Bu alan sadece sayısal karakterler içerebilir",
      workEmail: "Lütfen iş e-postası girin",
      website: "Lütfen geçerli bir website girin",
      phoneNumber: "Lütfen geçerli bir telefon numarası girin",
      allFieldsRequired: "Lütfen tüm alanları doldurun!",
      allFieldsCorrect: "Lütfen tüm alanları doğru doldurun!",
      termsRequired: "Lütfen Şartlar ve Koşulları kabul edin!"
    },
    limitation: {
      maxFileSize: "Maksimum dosya boyutu",
      maxFile: "Aynı anda yüklenebilecek maksimum dosya sayısı",
      invalidFile: "Geçersiz dosya türü",
      singleFileWarning: "Sadece bir dosya bırakabilirsiniz",
      maxFileWarning: "En fazla {count} dosya bırakabilirsiniz",
      maxFileNameLength: "Maksimum dosya adı {count} karakter olabilir",
      badExtension: "Hatalı dosya uzantısı",
      maxAudioDuration: "Maksimum ses süresi {value} dakika",
      maxVideoDuration: "Maksimum video süresi {value} dakika",
      maxVideoResolution: "Maksimum video çözünürlüğü {height}x{width} veya {width}x{height}",
      fileAlreadyUploaded: "Dosya {filename} zaten yüklendi",
      unsupportedFileType: "Aboneliğiniz için desteklenmeyen dosya türü"
    }
  },
  page: {
    home: {
      title: "Panel",
      subtitle: "Kullanım ve gönderim detaylarını görün",
      stats: {
        monthlyLeftCredits: "Kalan Aylık Krediler",
        monthlyUsage: "Aylık Kullanım",
        accountExpire: "Hesap şu tarihte sona erecek",
        getStarted: "Sensity'den en iyi şekilde yararlanın",
        videoTitle: "Sensity'de başlangıç: özellikler ve sonuçlar"
      }
    },
    auth: {
      login: {
        title: "Giriş Yap",
        subtitle: "Hesabınız yok mu?",
        signUpLink: "Ücretsiz kayıt olun",
        forgotPassword: "Şifrenizi mi unuttunuz?"
      },
      register: {
        title: "Hesap oluştur",
        subtitle: "Zaten hesabınız var mı?",
        emailDisclaimer: "Kaydınız bir insan operatör tarafından onaylanacak. Ücretsiz alan adları ve geçici alan adları varsayılan olarak meşru kabul edilmeyecektir",
        detailsPlaceholder: "Detaylar\nLütfen organizasyonunuz ve Sensity'yi kullanarak elde etmek istediğiniz hedefler hakkında daha fazla bilgi verin. Kapsamlı bilgi olmadan hesabınız reddedilecektir"
      },
      passwordRecovery: {
        title: "Şifre Kurtarma",
        subtitle: "Geri dön",
        message: "Hesabınız için kullandığınız e-postayı girin.",
        successMessage: "Şifre sıfırlama talimatlarını e-posta adresinize gönderdik. On dakika içinde e-posta alınmazsa, gönderilen adresin doğru olduğunu kontrol edin."
      },
      confirmPassword: {
        title: "Şifreyi Sıfırla"
      },
      changePassword: {
        title: "Şifre değiştir"
      }
    },
    analytics: {
      title: "Analitik",
      subtitle: "Platform ve API gönderimlerinin tüm yüklenen içeriğine ilişkin detaylı bilgileri keşfedin."
    },
    team: {
      title: "Ekip Yönetimi",
      visibility: {
        visible: "Gönderimler tüm Ekibe görünür",
        notVisible: "Gönderimler tüm Ekibe görünmez",
        tooltip: {
          visible: "Ekip gönderileriniz tüm ekip üyelerine görünür.",
          notVisible: "Gönderileriniz her ekip üyesine özeldir."
        },
        hint: "Ekip gönderiminizi tüm ekip üyelerine görünür yapmak istiyorsanız, lütfen bu kutuyu işaretleyin. Her ekip üyesinin gönderiminin özel olmasını istiyorsanız işaretlemeyin."
      },
      addNewMemberModal: {
        title: "Yeni Ekip Üyesi Ekle",
        desc: "Lütfen yeni ekip üyesinin e-posta adresini girin.",
        existingMember: "Bu kullanıcı zaten ekibin üyesi."
      },
      upgradeModal: {
        title: "Ekip yönetimi premium bir özelliktir",
        desc: "\"Planımı yükseltmek istiyorum\" seçeneğine tıkladığınızda, hesap yöneticiniz çeşitli seçenekleri açıklayacak.",
        confirm: "Devam etmek istiyor musunuz?",
        button: "Planımı yükseltmek istiyorum"
      },
      deleteTeamMemberModal: {
        title: "Ekip Üyesini Sil",
        desc: "Bu kullanıcılar ekipten silinecek. Devam etmek istediğinizden emin misiniz?"
      },
      createTeamModal: {
        title: "Yeni Ekip Oluştur",
        desc: "Lütfen ekibiniz için bir ad girin"
      },
      newUserTooltip: {
        analyst: "Lütfen daha fazla kullanıcı eklemek için ekip yöneticinizle iletişime geçin.",
        admin: "Ekibinize yeni kullanıcılar ekleyin."
      }
    },
    deepfake: {
      title: "Deepfake Tespiti",
      subtitle: "Ses ve video deepfake'lerini ve yapay zeka üretimi içeriği tespit etmek için şüpheli medyayı analiz edin.",
      button: "Deepfake Tespitini Başlat",
      info: {
        automation: "Gönderimleri otomatikleştirmek ister misiniz?",
        checkDocs: "API belgelerimizi kontrol edin",
        checkDocsOnPrem: "API belgelerimizi görüntülemek için şirket içi kullanım kılavuzumuzu kontrol edin.",
        contactSupport: "ve bize ulaşın"
      },
      report: {
        overall: {
          user: "Kullanıcı",
          fileName: "Dosya Adı",
          fileSize: "Dosya Boyutu (KB)",
          fileHash: "Dosya Hash'i (SHA256)",
          submissionDate: "Gönderim Tarihi",
          submissionTime: "Gönderim Saati",
          fileResolution: "Dosya Çözünürlüğü",
          url: "URL",
          faces: "yüz",
          face: "yüz",
          processingMessage: "Video raporu işleniyor. Bu birkaç dakika sürebilir.",
          selectFaceMessage: "Video raporu oluşturmak için lütfen en az bir yüz seçin.",
          enableVoiceAnalysisMessage: "Ses analizi hizmetini etkinleştirmek için lütfen support@sensity.ai ile iletişime geçin",
          exportingReport: "Rapor Dışa Aktarılıyor",
          processingVideo: "Video İşleniyor"
        },
        explanation: {
          fakeFacesDetected: "Sahte Yüzler Tespit Edildi",
          faceExplanation: "Yüzleri seçerek, ilgili sınırlayıcı kutular medyanın üzerinde görüntülenecek.",
          faceExplanationVideo: "ve dışa aktarılan videoda.",
          selected: "seçildi",
          viewOnVideo: "Videoda görüntüle",
          exportVideoLabel: "Videoyu seçili yüzlerle dışa aktar",
          faceResolution: "Yüz Çözünürlüğü",
          low: "düşük",
          lowResolutionInfo: "Düşük yüz çözünürlüğü dedektörün doğruluğunu etkileyebilir",
          viewAt: "Şurada görüntüle",
          pixelBasedAssessment: "Piksel tabanlı değerlendirme",
          pixelBasedInfo: "Bu bölüm yukarıdaki sonuçları doğrulayan görsel gösterimler sağlar",
          expandImage: "Görüntüyü Genişlet",
          expandVideoFrame: "Video Karesini Genişlet",
          heatmapInfo: "Isı haritası, modelin piksel düzeyinde sentez olasılığının daha yüksek olduğunu tespit ettiği alanları gösterir",
          sectionDesc: "Bu bölüm yukarıdaki sonuçları doğrulayan görsel gösterimler sağlar",
          pixelAndSegmentTitle: "Piksel ve Segment tabanlı değerlendirme",
          faceAndPixelTitle: "Yüz Hizalama ve Piksel tabanlı değerlendirme",
          segmentBasedNotAvailable: "Bu medya için segment tabanlı değerlendirme mevcut değil",
          segmentBasedAssesment: "Segment Tabanlı Değerlendirme",
          segmentBasedInfo: "Dedektör, görüntüde yapay zeka tarafından üretilmiş olma olasılığı en yüksek olan nesneleri tanımlar",
          faceAlignmentAssesment: "Yüz Hizalama Değerlendirmesi",
          faceAlignmentInfo: "Bu GAN ailesinin ürettiği görüntüler, gözlerin ve ağzın sabit konumu ile tanınabilir",
          fakeSpeakersDetected: "Sahte Konuşmacılar Tespit Edildi",
        },
      },
      form: {
        title: "Medya Yükle",
        tab: {
          file: "Dosya",
          url: "URL",
          urlNotAvailable: "URL yükleme şirket içi sürümde mevcut değil"
        },
      },
    },
    document: {
      title: "Belge Doğrulama",
      subtitle: "Temel bilgileri çıkar ve belgelerin gerçekliğini doğrula.",
      button: "Belge Doğrulamayı Başlat",
    },
    identity: {
      title: "Kimlik Doğrulama",
      subtitle: "Kimlik doğrulama ve canlılık tespiti yapar.",
      button: "Kimlik Doğrulamayı Başlat",
    },
    developer: {
      title: "Geliştirici",
      subtitle: "API anahtarlarınızı ve kullanımınızı yönetin",
      apiToken: "API Anahtarı"
    }
  },
  name: {
    service: {
      deepfake: "Deepfake Tespiti",
      aiGen: "Yapay Zeka Üretimi İçerik",
      faceMan: "Yüz Manipülasyonu",
      document: "Doküman Doğrulama",
      forensic: "Adli Analiz",
      id: "Kimlik Doğrulama",
      liveness: "Video Canlılık",
      pixel: "Piksel Analizi",
      voice: "Ses Analizi",
      faceMatch: "Yüz Eşleştirme",
      title: "Sensity Kurumsal",
      description: "Yapay Zeka Tespit Platformu"
    },
    language: {
      english: "İngilizce (English)",
      turkish: "Türkçe",
      uzbek: "Özbekçe (O'zbekcha)",
      spanish: "İspanyolca (Español)",
      korean: "Korece (한국어)",
      russian: "Rusça (Русский)",
      hindi: "Hintçe (हिंदी)",
      german: "Almanca (Deutsch)",
      dutch: "Flemenkçe (Nederlands)",
      chinese: "Çince (中文)",
      arabic: "Arapça (العربية)",
      indonesian: "Endonezce (Bahasa)",
      vietnamese: "Vietnamca (Tiếng Việt)",
      japanese: "Japonca (日本語)",
      italian: "İtalyanca (Italiano)",
      french: "Fransızca (Français)"
    }
  }
}
